/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from "react";
import ModalExample from "../Modal/Modal";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAddressEdit } from "../../redux/actions/authentication";

const EditAddress = (props) => {
  const { id, data } = props;
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setForm(data);
  }, []);

  const activate = (e) => {
    e.preventDefault();
    let data = { name: form.name, line1: form.line1, phone: form.phone };
    props.fetchAddressEdit(id, data);
    setCheck(true);
  };

  useEffect(() => {
    if (check) {
      NotificationManager.success("Edited successfully");
      setCheck(false);
    }
  }, [props.address.address]);

  return (
    <div>
      <NotificationContainer />
      <ModalExample
        modalTitle="Edit Profile"
        buttonLabel={<span className="text-info action-address">EDIT</span>}
        handleAction={activate}
        handleTitle="Save"
      >
        <NotificationContainer />
        <div>
          {/* <div className="">{props.user.message}</div> */}
          <form action="post" onSubmit={activate}>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Full name</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                name="name"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                required
                autoFocus
              />
            </div>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Phone</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                name="phone"
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: e.target.value })}
                required
                autoFocus
              />
            </div>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Address</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                name="birthday"
                value={form.line1}
                onChange={(e) => setForm({ ...form, line1: e.target.value })}
                required
                autoFocus
              />
            </div>
          </form>
        </div>
      </ModalExample>
    </div>
  );
};

EditAddress.propTypes = {
  user: PropTypes.object.isRequired,
  fetchAddressEdit: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  address: state.address,
});

const pageActions = { fetchAddressEdit };

export default connect(mapStateToProps, pageActions)(EditAddress);
