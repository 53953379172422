import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import photo from "../../images/photo.png";
import bike from "../../images/bike.png";
import dress from "../../images/dress.png";
import phone from "../../images/phone.png";
import fruit from "../../images/fruit.png";

import { Link, useHistory } from "react-router-dom";
import ViewMore from "../../component/ViewMore/ViewMore";
import SellAds from "../../component/SellAds/SellAds";
import CardAuction from "../../component/CardAuction1/CardAuction";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAuctions } from "../../redux/actions/auctions";
import { fetchStores } from "../../redux/actions/stores";
import CardStore from "../../component/CardStore/CardStore";

const Auction = (props) => {
  const history = useHistory();
  const [auctions, setAuctions] = useState([]);
  const [pageCount, setPage] = useState(12);
  const [stores, setStores] = useState([]);

  function viewMore() {
    props.fetchAuctions(pageCount);
    setPage(pageCount + 12);
  }

  useEffect(() => {
    props.fetchAuctions();
    props.fetchStores();
  }, []);

  useEffect(() => {
    if (props.auctions.auctions.data) {
      setAuctions(props.auctions.auctions.data.auction);
    }
  }, [props.auctions]);

  useEffect(() => {
    if (props.stores.stores.data) {
      setStores(props.stores.stores.data.stores.docs);
    }
  }, [props.stores.stores]);

  return (
    <Layout>
      <section className="auction-section">
        {/* <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/auction">Auction</Link>
        </div> */}

        <section>
          <div className="auction-image-head">
            <div className="auction-image-text">
              <h1>The Best Place</h1>
              <h1>
                <strong>to Buy and Sell</strong>
              </h1>
              <button className="btn auction-reg">
                Register
              </button>
            </div>
          </div>
        </section>

        <section className="auction-content-how">
          <div className="how-it-work">
            <div className="how-text">
              <p className="top-text">
                <strong>How</strong> It Works
              </p>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="how-icon">
                  <i className="fa fa-user fa-2x" aria-hidden="true"></i>
                </div>
                <p>
                  <strong>Register</strong>
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Harum rerum, necessitatibus quae maiores molestiae suscipit.
                  Repudiandae nemo molestias placeat laborum sapiente, porro
                  velit voluptas quaerat obcaecati officia sunt ab modi?
                </p>
              </div>
              <div className="col-md-3">
                <div className="how-icon-2">
                  <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                </div>
                <p>
                  <strong>Select</strong>
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Harum rerum, necessitatibus quae maiores molestiae suscipit.
                  Repudiandae nemo molestias placeat laborum sapiente, porro
                  velit voluptas quaerat obcaecati officia sunt ab modi?
                </p>
              </div>
              <div className="col-md-3">
                <div className="how-icon">
                  <i className="fa fa-gavel fa-2x" aria-hidden="true"></i>
                </div>
                <p>
                  <strong>Bid</strong>
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Harum rerum, necessitatibus quae maiores molestiae suscipit.
                  Repudiandae nemo molestias placeat laborum sapiente, porro
                  velit voluptas quaerat obcaecati officia sunt ab modi?
                </p>
              </div>
              <div className="col-md-3">
                <div className="how-icon-2">
                  <i className="fa fa-trophy fa-2x" aria-hidden="true"></i>
                </div>
                <p>
                  <strong>Win</strong>
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Harum rerum, necessitatibus quae maiores molestiae suscipit.
                  Repudiandae nemo molestias placeat laborum sapiente, porro
                  velit voluptas quaerat obcaecati officia sunt ab modi?
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="auction-mobile">
        <div className="featured-auction-item p-3">
              {
                <div className="row auction-items">
                  {(auctions || []).slice(0, 5).map((item, i) => {
                    return (
                      <Link to={`/place-bid/${item.id}`}>
                        <CardAuction
                          photo={item.product.images[0]}
                          item={item.product.product_name}
                          price={item.starting_price}
                          time={item.start_time}
                          bids={item.current_price}
                        />
                      </Link>
                    );
                  })}
                </div>
              }
            </div>
            <ViewMore viewHandler={viewMore} />
        </section>


        <section className="auction-desktop">


        <section>
        <div className="featured-auction">
            <div className="featured-text">
              <p className="top-text">
                <strong>Featured</strong> Auctions
              </p>
            </div>
            <div className="featured-auction-item p-3">
              {
                <div className="row auction-items">
                  {(auctions || []).slice(0, 5).map((item, i) => {
                    return (
                      <Link to={`/place-bid/${item.id}`}>
                        <CardAuction
                          photo={item.product.images[0]}
                          item={item.product.product_name}
                          price={item.starting_price}
                          time={item.start_time}
                          bids={item.current_price}
                        />
                      </Link>
                    );
                  })}
                </div>
              }
            </div>
          </div>
        </section>

        <section className="auction-item-container">

          
          <div className="current-auction p-3">
            <div className="current-text">
              <p className="top-text">
                <strong>Current</strong> Auctions
              </p>
            </div>
            <div className="current-auction-item">
              {
                <div className="row auction-items">
                  {(auctions || []).slice(0, 5).map((item, i) => {
                    return (
                      <Link to={`/place-bid/${item.id}`}>
                        <CardAuction
                          photo={item.product.images[0]}
                          item={item.product.product_name}
                          price={item.starting_price}
                          time={item.start_time}
                          bids={item.current_price}
                        />
                      </Link>
                    );
                  })}
                </div>
              }
            </div>
          </div>
          <div className="finished-auction p-3">
            <div className="finished-text">
              <p className="top-text">
                <strong>Finished</strong> Auctions
              </p>
            </div>
            <div className="finished-auction-item">
              {
                <div className="row auction-items">
                  {(auctions || []).slice(0, 5).map((item, i) => {
                    return (
                      <Link to={`/place-bid/${item.id}`}>
                        <CardAuction
                          photo={item.product.images[0]}
                          item={item.product.product_name}
                          price={item.starting_price}
                          time={item.start_time}
                          bids={item.current_price}
                        />
                      </Link>
                    );
                  })}
                </div>
              }
            </div>
          </div>
        </section>

        <ViewMore viewHandler={viewMore} />

        </section>

        
      </section>

      <section className="testy-cont ">
        <div className="testimony-text">
          <p className="top-text">
            <strong>Testimonials</strong>
          </p>
        </div>
        <div className="row">
          <div className="testimony-container col-md-4">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Inventore amet expedita corporis consequatur sit repellat, ratione
              in necessitatibus, sequi mollitia vel, ut atque dolorem accusamus
              itaque ex blanditiis error recusandae.
            </p>
          </div>
          <div className="testimony-container col-md-4">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Inventore amet expedita corporis consequatur sit repellat, ratione
              in necessitatibus, sequi mollitia vel, ut atque dolorem accusamus
              itaque ex blanditiis error recusandae.
            </p>
          </div>
          <div className="testimony-container col-md-4">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Inventore amet expedita corporis consequatur sit repellat, ratione
              in necessitatibus, sequi mollitia vel, ut atque dolorem accusamus
              itaque ex blanditiis error recusandae.
            </p>
          </div>
        </div>
      </section>

              <section className="sell-desktop">
              <SellAds />
              </section>
     

      {/* store */}
      <section className="auc-store">
        <div className="topseller-cont">
          <div>
            <p className="top-text">
              <strong>Stores</strong>
            </p>
            <p>Weekly line up for our best sellers</p>
          </div>
          <h5
            className="market-text mr-2"
            onClick={() => history.push("/stores")}
          >
            View all >
          </h5>
        </div>

        <div className="store-items ">
          <div className="topseller-items">
            {(stores || []).slice(0, 5).map((item, i) => {
              return (
                <CardStore
                  like={item.favourite_stores.length < 1 ? false : true}
                  name={item.store_name !== undefined ? item.store_name : ""}
                  url={item.store_url}
                  logo={item.logo}
                  address={
                    item.store_location_address !== undefined
                      ? item.store_location_address
                      : ""
                  }
                  likes={item.likes}
                  key={i}
                  // follow={() => followStore(item.id)}
                  // unfollow={() => unfollowStore(item.id)}
                />
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

Auction.propTypes = {
  auctions: PropTypes.object.isRequired,
  fetchAuctions: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auctions: state.auctions,
  stores: state.stores,
});

const pageActions = { fetchAuctions, fetchStores };
export default connect(mapStateToProps, pageActions)(Auction);
