import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardItem from "../../component/CardItem/CardItem";
import { Link, useHistory } from "react-router-dom";
import SellAds from "../../component/SellAds/SellAds";
import ViewMore from "../../component/ViewMore/ViewMore";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCategoryProduct } from "../../redux/actions/category";
import {
  fetchFavoritePost,
  fetchFavoriteRemove,
} from "../../redux/actions/products";

import truncate from "../../utils/truncate";
import CardSaved from "../../component/CardSaved/CardSaved";

const ProductCategory = (props) => {
  const {
    match: { params },
  } = props;
 const history = useHistory();
  const [products, setProduct] = useState({ products: [] });
  const [category, setCategory] = useState("");
  const [pageCount, setPage] = useState(10);
  const [check, setCheck] = useState(false);

  function viewMore() {
    props.fetchCategoryProduct(params.category, pageCount);
    setPage(pageCount + 10);
  }

  const saveItem = (id) => {
    props.fetchFavoritePost(id);
    setCheck(true);
  };

  function removeFav(id) {
    props.fetchFavoriteRemove(id);
    setCheck(true);
  }

  useEffect(() => {
    props.fetchCategoryProduct(params.category);
  }, [params.category]);

  useEffect(() => {
    if (check) {
      props.fetchCategoryProduct(params.category, pageCount);
      //NotificationManager.success(props.favorites.favorites.message);
      setCheck(false);
    }
  }, [props.favorites.favorites]);

  useEffect(() => {
    if (props.category.category_prod.data) {
      console.log(props.category.category_prod.data.products);
      setCategory(props.category.category_prod.data.category.name);
      setProduct({
        ...products,
        products: props.category.category_prod.data.products,
      });
    }
  }, [props.category]);

  return (
    <Layout>
      <section className="category-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`/product-category/${params.category}`}>{category}</Link>
          <p className="title-page">{category}</p>
        </div>
        
          <div className="row">
            {products.products.map((item, i) => {
              return (
                <div className="category-items" key={i}>
                  
                    <CardSaved
                      photo={item.images[0]}
                      item={item.product_name}
                      price={item.price}
                      desc={truncate(item.description, 25)}
                      like={item.favourites.length > 0 ? true : false}
                      action={() => history.push(`/product/${item.product_slug}`)}
                      favourite={() => {
                        if (item.favourites.length > 0) {
                          removeFav(item.id);
                        } else {
                          saveItem(item.id);
                        }
                      }}
                    />
              
                </div>
              );
            })}
          </div>
        {products.products.length < 1 && <div className="text-center"><h5>No product available in this category</h5></div>}

        <ViewMore viewHandler={viewMore} />
      </section>

      <section className="category-selling">
        <SellAds />
      </section>
    </Layout>
  );
};

ProductCategory.propTypes = {
  category: PropTypes.object.isRequired,
  fetchCategoryProduct: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category_prod,
  favorites: state.favorites,
});

const pageActions = { fetchCategoryProduct,fetchFavoritePost,
  fetchFavoriteRemove, };
export default connect(mapStateToProps, pageActions)(ProductCategory);
