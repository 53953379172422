import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";

import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";
import {
  fetchSingleOrder,
  fetchPostReviews,
  fetchDisputesOrder,
} from "../../redux/actions/products";
import numberToComma from "../../utils/numberToComma";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";
import { isMobile, isDesktop } from "../../utils/isLayout";
import swal from "@sweetalert/with-react";
import ModalExample from "../../component/Modal/Modal";
import BeautyStars from "beauty-stars";
import dayjs from "dayjs";
import Axios from "axios";
import { BASE_URL } from "../../redux/constants";
import numberWithCommas from "../../utils/numberToComma";

const OrderInfo = (props) => {
  const {
    match: { params },
  } = props;
  const [form, setForm] = useState({});
  const [order, setOrder] = useState({});
  const [disputes, setDisputes] = useState({});
  const [check, setCheck] = useState(false);
  const [report, setReport] = useState({ dispute: "", options: "", reply: "" });
  const user = Auth.getUser();

  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  function addReview(id) {
    setCheck(true);
    props.fetchPostReviews({
      product_id: id,
      star: form.star,
      review: form.review,
    });
  }

  useEffect(()=>{
    setInterval(() => {
      props.fetchDisputesOrder(params.order);
    }, 30000);
  },[])

  async function reply(e) {
    e.preventDefault()
    console.log(report.reply);
    const data = { message: report.reply };
    try {
      const response = await Axios.post(
        `${BASE_URL}/orders/thread/${disputes.id}`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        props.fetchDisputesOrder(params.order);
        setReport({ ...report,reply: "" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function reportOrder(id) {
    //setCheck(true);

    const data = {
      dispute: report.dispute !== "" ? report.dispute : report.options,
    };
    try {
      const response = await Axios.post(
        `${BASE_URL}/orders/dispute/${id}`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        props.fetchDisputesOrder(params.order);
        setReport({ dispute: "" });
      }
    } catch (error) {
      console.log(error);
    }
    console.log(report, id);
  }

  useEffect(() => {
    if (props.review.review.data && check) {
      swal("Thank you for the review", "", "success");
      setCheck(false);
      setForm({ ...form, review: "", star: "" });
    }
  }, [props.review.review]);

  useEffect(() => {
    if (props.review.errors.errors && check) {
      swal(props.review.errors.errors.message, "", "error");
      setCheck(false);
      setForm({ ...form, review: "", star: "" });
    }
  }, [props.review.errors.errors]);

  useEffect(() => {
    props.fetchSingleOrder(params.order);
    props.fetchDisputesOrder(params.order);
  }, [params.order]);

  useEffect(() => {
    if (props.single_order.single_order.data) {
      setOrder(props.single_order.single_order.data.order);
    }
  }, [props.single_order.single_order]);

  useEffect(() => {
    if (props.disputes.disputes.data) {
      setDisputes(props.disputes.disputes.data.dispute);
    }
  }, [props.disputes.disputes]);

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/order">Orders</Link>
          <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`order-info/${order.id}`}>Order #{order.id}</Link>
          <p className="title-page">Order #{order.id}</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                {(order.items?.items || []).map((item, i) => {
                  return (
                    <Fragment>
                      <div className="order-item mb-3" key={i}>
                        <div className="order-item-body row">
                          <div className="col-md-2 order-image">
                            <img
                              src={item.product.images[0]}
                              width="50%"
                              alt="watch"
                            />
                          </div>
                          <div className="col-md-5 d-flex justify-content-between flex-column">
                            <Link to={`/product/${item.product.id}`}>
                              <p className="order-name">
                                {item.product.product_name}
                              </p>

                              {item.has_variation && (
                                <span className="status-var">
                                  {item.selected_variation.variation}
                                </span>
                              )}
                            </Link>

                            <p className="order-info">
                              <i className="fa fa-check" aria-hidden="true"></i>{" "}
                              Item created on{" "}
                              {dayjs(order.createdAt).format("MMMM D, YYYY h:mm A")}
                            </p>
                          </div>
                          <div className="col-md-3 d-flex justify-content-between flex-column">
                            <p className="order-date">
                              {dayjs(order.createdAt).format("MMMM D, YYYY h:mm A")}
                            </p>
                            <p
                              className={
                                order.order_proccessing_status !== "cancelled"
                                  ? "order-info"
                                  : "order-status"
                              }
                            >
                              {order.order_proccessing_status}
                            </p>
                          </div>
                          <div className="col-md-2 text-right d-flex justify-content-between flex-column">
                            <p className="order-name">
                              ₦ {numberToComma(item.total_price)}
                            </p>

                            <ModalExample
                              modalTitle="Add Review"
                              classBtn="btn btn-sm btn-outline-secondary bid-btn"
                              buttonLabel="review"
                              handleAction={() => addReview(item.product.id)}
                              handleTitle={check ? "sending" : "send"}
                            >
                              <div>
                                <form>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Review
                                    </label>
                                    <textarea
                                      type="text"
                                      class="form-control"
                                      aria-describedby="emailHelp"
                                      name="review"
                                      value={form.review}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          review: e.target.value,
                                        })
                                      }
                                      required
                                      autoFocus
                                      placeholder="enter your review"
                                    ></textarea>
                                  </div>

                                  <BeautyStars
                                    activeColor="#0279B8"
                                    value={form.star}
                                    onChange={(value) =>
                                      setForm({ ...form, star: value })
                                    }
                                  />
                                </form>
                              </div>
                            </ModalExample>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
                <h6>Delivery status</h6>
                <div className="d-flex justify-content-between">
                  <p
                    className={
                      order.order_proccessing_status === `paid` &&
                      `p-1 status-order`
                    }
                  >
                    paid
                  </p>
                  <p
                    className={
                      order.order_proccessing_status ===
                        (`processing` || `dispatched`) && `p-1 status-order`
                    }
                  >
                    processing
                  </p>
                  <p
                    className={
                      order.order_proccessing_status ===
                        (`cancelled` || `refunded`) && `p-1 status-order`
                    }
                  >
                    cancelled or refunded
                  </p>
                  <p
                    className={
                      order.order_proccessing_status === `received` &&
                      `p-1 status-order`
                    }
                  >
                    done
                  </p>
                </div>
                <h6>other Information</h6>

                <div className="card-checkout-summary m-3 p-3">
                  <h6>{order.address ? order.address.name : "no name"}</h6>
                  <p className="summary-text">
                    {order.address ? order.address.line1 : "no address"}
                  </p>
                  <p className="summary-text">
                    {order.address ? order.address.city : "no city"}
                  </p>
                  <p className="summary-text">
                    {order.address ? order.address.phone : "no phone"}
                  </p>
                  <p className="summary-text">
                    Zip code: {order.address ? order.address.postal : "no name"}
                  </p>
                </div>

                <div className="card-checkout-summary text-center">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td className="text-left summary-total">sub total :</td>
                        <td className="text-right">
                          ₦ {order ? numberWithCommas(order.price ?? '') : "no price"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left summary-total">
                          Shipping fee :
                        </td>
                        <td className="text-right">
                          {order ? "₦ " + order.logistics_value : "no fee"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left summary-total">
                          Tax ({order ? order.tax_value : "no tax"}%):
                        </td>
                        <td className="text-right">
                          {" "}
                          -₦{" "}
                          {order
                            ? numberWithCommas(((order.tax_value / 100) * order.price ?? ''))
                            : "no tax"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left summary-total">Discount:</td>
                        <td className="text-right">
                          -₦ {order ? order.discount_value : "no discount"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left summary-total">
                          Coupon discount:
                        </td>
                        <td className="text-right text-danger">
                          {order
                            ? order.coupon_value != null
                              ? (order.coupon_type === "value" && "-₦") +
                                order.coupon_value +
                                (order.coupon_type === "percent" ? "%" : "") +
                                " (" +
                                order.coupon_id +
                                ")"
                              : "no coupon"
                            : "no coupon"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left summary-total">Total:</td>
                        <td className="text-right">
                          <h4>₦ {order ? numberWithCommas(order.net_price ?? '') : "no price"}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  {disputes === null ? (
                    <div>
                      <h6>Dispute order</h6>
                      <p>Having problems with this order</p>
                      <ModalExample
                        modalTitle="Report order"
                        classBtn="btn btn-outline-primary btn-sm bid-btn"
                        buttonLabel="Open dispute"
                        handleAction={() => reportOrder(order.id)}
                        handleTitle={check ? "reporting" : "report"}
                      >
                        <div>
                          <p>Order ID {params.order}</p>
                          <input
                            type="text"
                            class="form-control mb-1"
                            value={user.full_name}
                            readOnly
                          />
                          <input
                            type="text"
                            class="form-control mb-1"
                            value={user.email}
                            readOnly
                          />
                          <input
                            type="text"
                            class="form-control mb-1"
                            value={user.phone}
                            readOnly
                          />

                          <div class="form-check m-2">
                            <input
                              class="form-check-input mb-1"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="Order has not arrived yet"
                              onChange={(e) =>
                                setReport({
                                  ...report,
                                  options: e.target.value,
                                })
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios1"
                            >
                              Order has not arrived yet
                            </label>
                          </div>
                          <div class="form-check m-2">
                            <input
                              class="form-check-input mb-1"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios2"
                              value="Order is incomplete"
                              onChange={(e) =>
                                setReport({
                                  ...report,
                                  options: e.target.value,
                                })
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios2"
                            >
                              Order isincomplete
                            </label>
                          </div>

                          <form>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Custom Message
                              </label>
                              <textarea
                                type="text"
                                class="form-control"
                                aria-describedby="emailHelp"
                                name="dispute"
                                value={report.dispute}
                                onChange={(e) =>
                                  setReport({
                                    ...report,
                                    dispute: e.target.value,
                                  })
                                }
                                required
                                autoFocus
                                placeholder="enter your message"
                              ></textarea>
                            </div>
                          </form>
                        </div>
                      </ModalExample>
                    </div>
                  ) : (
                    <div className="container">
                      <h3>Messages</h3>
                      <div className="text-center mb-3 mt-3">
                        <h6>{disputes.dispute}</h6>
                      </div>
                      {(disputes.dispute_threads || []).map((message,i)=>{
                        return(
                          <div key={i} className={message.role === 'you'?`alert alert-info text-right ml-5`:`alert alert-secondary text-left mr-5`}>
                          {message.message}
                          <br/>
                          <small>{dayjs(message.createdAt).format('MMMM D, YYYY h:mm A')}</small> 
                        </div>
                        )
                      })}
                      <form class="form-inline mb-2">
                       
                          <input
                            type="text"
                            className="form-control col-md-11 mb-1 mr-2"
                            id="inputPassword2"
                            placeholder="reply message"
                            value={report.reply}
                            onChange={(e) =>
                              setReport({ ...report, reply: e.target.value })
                            }
                          />
                       
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={reply}
                        >
                          send
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

OrderInfo.propTypes = {
  single_order: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  single_order: state.single_order,
  review: state.review,
  disputes: state.disputes,
});

const pageActions = { fetchSingleOrder, fetchPostReviews, fetchDisputesOrder };

export default connect(mapStateToProps, pageActions)(OrderInfo);
