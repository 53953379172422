import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import mc from "../../images/mc.svg";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import Coupon from "../Coupon/Coupon";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";
import { fetchPaymentInit } from "../../redux/actions/payment";
import numberWithCommas from "../../utils/numberToComma";

const CardSummary = (props) => {
  const { order, cancel } = props;
  const history = useHistory();
  const [pay, setPay] = useState({});

  const handleInit = (type) => {
    props.fetchPaymentInit({ payment_method: type });
  };

  useEffect(() => {
    if (props.payment.payment.data) {
      const { data } = props.payment.payment.data.order.transaction;
      console.log(data.authorization_url);
      setPay(data);
      window.open(data.authorization_url, "_self");
    }
  }, [props.payment.payment]);

  return (
    <Row className="pb-2">
      <NotificationContainer />
      <Col sm={12} xs={12} lg={7} md={7} className="checkout-summary-items">
        <div className="p-3">
          <p className="summary-text pb-3">
            You order ({order.items ? order.items.items.length : 0}) items
          </p>
          <table className="table table-borderless">
            <thead>
              <th className="summary-text"></th>
              <th className="summary-text">name</th>
              <th className="summary-text">quantity</th>
              <th className="summary-text">price</th>
            </thead>
            <tbody>
              {order.items ? (
                (order.items.items || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <img
                          src={item.product.images[0]}
                          width="80px"
                          className="summary-image"
                          alt="logo"
                        />
                      </td>
                      <td>
                        <p className="summary-text">
                          {item.product.product_name}
                        </p>
                         <small>{item.has_variation ? '('+item.selected_variation.variation+')' : ''}</small>
                      </td>
                      <td>
                        <p className="summary-text">{item.quantity}</p>
                      </td>
                      <td>
                        <p className="summary-text">₦ {numberWithCommas(item.total_price)}</p>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <h4>Order not placed yet</h4>
              )}
            </tbody>
          </table>
          <div className="text-center">
            <button
              className="btn btn-danger m-3"
              onClick={cancel}
              disabled={order.items === undefined ? true : false}
            >
              cancel order
            </button>
          </div>
        </div>
      </Col>
      <Col sm={12} xs={12} lg={5} md={5}>
        {/* <div className="card-checkout-summary d-flex justify-content-between text-center m-3 p-3">
          <p className="summary-text">5334 9343 3422 5432 3465</p>
          <span>
            <img src={mc} alt="mc" />
          </span>
        </div> */}
        <div className="card-checkout-summary m-3 p-3">
          <h6>{order.address ? order.address.name : "no name"}</h6>
          <p className="summary-text">
            {order.address ? order.address.line1 : "no address"}
          </p>
          <p className="summary-text">
            {order.address ? order.address.city : "no city"}
          </p>
          <p className="summary-text">
            {order.address ? order.address.phone : "no phone"}
          </p>
          <p className="summary-text">
            Zip code: {order.address ? order.address.postal : "no name"}
          </p>
        </div>

        <div className="card-cart-summary text-center">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="text-left summary-total">sub total :</td>
                <td className="text-right">
                  ₦ {order ? numberWithCommas(order.price ? order.price : '') : "no price"}
                </td>
              </tr>
              <tr>
                <td className="text-left summary-total">Shipping fee :</td>
                <td className="text-right">
                  {order ? "₦ " + order.logistics_value : "no fee"}
                </td>
              </tr>
              <tr>
                <td className="text-left summary-total">
                  Tax ({order ? order.tax_value : "no tax"}%):
                </td>
                <td className="text-right">
                  {" "}
                  ₦ {order ? (order.tax_value / 100) * order.price : "no tax"}
                </td>
              </tr>
              <tr>
                <td className="text-left summary-total">Discount:</td>
                <td className="text-right">
                  -₦ {order ? order.discount_value : "no discount"}
                </td>
              </tr>
              <tr>
                <td className="text-left summary-total">Coupon discount:</td>
                <td className="text-right text-danger">
                  {order ? (
                    order.coupon_value != null ? (
                      (order.coupon_type === 'value' && '-₦') + order.coupon_value  + (order.coupon_type === 'percent' ? '%' : '')+ ' ('+ order.coupon_id+')'
                    ) : (
                      <Coupon orderId={order.id} />
                    )
                  ) : (
                    "no coupon"
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-left summary-total">Total:</td>
                <td className="text-right">
                  <h4>₦ {order ? numberWithCommas(order.net_price ? order.net_price : '') : "no price"}</h4>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="row p-4">
            <div className="col-md-12">
              <button
                className="sell text-white mb-3"
                onClick={() => handleInit("1")}
              >
                {order
                  ? " pay ₦ " + numberWithCommas(order.net_price ? order.net_price : '') + " with Paystack"
                  : "no price"}
              </button>

              {/* <button className="flutter mb-3" onClick={() => handleInit("2")}>
                {order
                  ? " pay ₦ " + order.net_price + " with Flutterwave"
                  : "no price"}
              </button> */}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  payment: state.payment,
});

const pageActions = {
  fetchPaymentInit,
};

export default connect(mapStateToProps, pageActions)(CardSummary);
