import React from "react";

import "./styles.scss";
import numberToComma from "../../utils/numberToComma";
import truncate from "../../utils/truncate";

const CardCart = (props) => {
  const {
    photo,
    unitPrice,
    count,
    remove,
    down,
    up,
    name,
    save,
    goToItem,
    stock,
    variation,
    like
  } = props;
  return (
    <div className="cart-page-container row mb-3">
      <div className="col-md-3">
        {stock && <p className="text-danger">OUT OF STOCK</p>}
        <img src={photo} className="cart-image" alt="watch" />
      </div>
      <div className="col-md-4">
        <h6 style={{ cursor: "pointer" }} onClick={goToItem}>
          {truncate(name, 25)}
          <br/>
          {variation}
        </h6>
        <span className="count-cart-qty">Qty</span>
        <button onClick={down} className="count-cart">
          -
        </button>
        <span className="count-cart-number"> {count} </span>
        <button onClick={up} disabled={stock} className="count-cart">
          +
        </button>
        <div className=" pt-4 ">
          <button className="btn btn-danger btn-sm" onClick={remove}>
            <i className="fa fa-trash pr-2" aria-hidden="true"></i>
            remove
          </button>

          <button
            className="btn btn-outline-primary btn-sm ml-3"
            onClick={save}
          >
            {like ? <span style={{ color: "#0279B8" }}>
                <i class="fa fa-heart" aria-hidden="true"></i> saved
              </span> : <span> <i className="fa fa-heart-o pr-2" aria-hidden="true"></i> save</span>}
          </button>
        </div>
      </div>
      <div className="col-md-2 text-right p-2">
        {" "}
        <span className="price">unit price(₦) :</span>{" "}
        ₦ {numberToComma(unitPrice)}
      </div>
      <div className="col-md-3 p-2 text-right font-weight-bold">
        <span className="price">total price(₦) :</span>{" "}
        ₦ {numberToComma((unitPrice * count).toFixed(2))}
      </div>
    </div>
  );
};

export default CardCart;
