import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardItem from "../../component/CardItem/CardItem";
import photo from "../../images/photo.png";
import dress from "../../images/dress.png";
import phone from "../../images/phone.png";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import CardCart from "../../component/CardCart/CardCart";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchRemoveCart,
  fetchUpdateCart,
  fetchRemoveAllCart,
  fetchViewCart,
  fetchOrderSummary,
  fetchFavoritePost,
  fetchRecentView,
  fetchCancelOrder,
} from "../../redux/actions/products";
import swal from "sweetalert";
import CardFeature from "../../component/CardFeature/CardFeature";
import { isMobile } from "../../utils/isLayout";
import CardFeatureMobile from "../../component/CardFeatureMobile/CardFeatureMobile";

const ProductCart = (props) => {
  const history = useHistory();
  const [cardCart, setCardCart] = useState([]);
  const [cart, setCart] = useState({});
  const [recent, setRecent] = useState([]);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    props.fetchViewCart();
    props.fetchRecentView();
  }, []);
  useEffect(() => {
    props.fetchViewCart();
  }, [props.user.user]);

  useEffect(() => {
    if (props.carts.carts.data) {
      setCart(props.carts.carts.data.cart);
      setCardCart(props.carts.carts.data.cart.items);
    }
  }, [props.carts.carts]);

  useEffect(() => {
    if (props.recent.recent.data) {
      setRecent(props.recent.recent.data.recent);
    }
  }, [props.recent.recent]);

  // useEffect(() => {
  //   swal("pops up");
  // }, [props.cart.errors.errors]);

  useEffect(() => {
    if (check) {
      //NotificationManager.error(props.cart.errors.errors.message);
      swal({
        //title: "Are you sure?",
        title: props.cart.errors.errors.message,
        icon: "warning",
        buttons: {
          orders: "go to orders",
          cancelOrder: "cancel orders",
          cancel: true,
        },
      }).then((value) => {
        switch (value) {
          case "orders":
            history.push("/product-checkout");
            break;
          case "cancelOrder":
            props.fetchCancelOrder();
            setCheck(true);
            // swal("cancel orders", "success");
            break;
          default:
        }
      });
      setCheck(false);
    }

    if (check && props.cart.errors.errors.code === 412) {
      swal("Session timed out", "Please login to continue", "error");
      setCheck(false);
    }

    if (check && props.cart.errors.errors.code === 400) {
      swal(props.cart.errors.errors.message, "", "error");
      setCheck(false);
    }
  }, [props.cart.errors.errors]);

  const up = (id, quantity, vid) => {
    const data = {
      quantity: quantity + 1,
      variation_id: vid ?? null,
    };
    props.fetchUpdateCart(id, data);
    setCheck(true);
  };
  const down = (id, quantity, vid) => {
    if (quantity === 1) {
      return;
    }
    const data = {
      quantity: quantity - 1,
      variation_id: vid ?? null,
    };
    props.fetchUpdateCart(id, data);
    setCheck(true);
  };

  const remove = (item) => {
    setCheck(true);
    const data = {
      product_id: item.product.id,
      variation_id: item.has_variation ? item.selected_variation.id : null,
    };
    props.fetchRemoveCart(data);
  };

  const save = (id) => {
    setCheck(true);
    props.fetchFavoritePost(id);
  };

  const checkout = () => {
    // props.fetchOrderSummary();
    history.push("/product-checkout");
  };

  useEffect(() => {
    if (props.payment.errors.errors) {
      NotificationManager.error(props.payment.errors.errors.message);

      history.push("product-cart");
    }
  }, [props.payment.errors.errors]);

  useEffect(() => {
    if (check) {
      props.fetchViewCart();
      NotificationManager.success(props.favorites.favorites.message);
      setCheck(false);
    }
  }, [props.favorites.favorites]);

  return (
    <Layout>
      <section className="cart-section">
        <NotificationContainer />
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/product-cart">Cart</Link>
        </div>
        <div className="row pt-3">
          <div className="col-md-9 mb-5">
            <div className="cart-page-container">
              <div className="d-flex justify-content-around pt-3 ">
                <p>{cardCart.length} items avaliable</p>
                <p className="info-carts">Unit price(₦)</p>
                <p className="info-carts">Sub total(₦)</p>
              </div>
              <hr />
              {(cardCart || []).map((item, key) => {
                return (
                  <CardCart
                    name={item.product.product_name}
                    photo={item.product.images[0]}
                    unitPrice={item.unit_price}
                    up={() =>
                      up(
                        item.product.id,
                        item.quantity,
                        item.selected_variation.id
                      )
                    }
                    down={() =>
                      down(
                        item.product.id,
                        item.quantity,
                        item.selected_variation.id
                      )
                    }
                    count={item.quantity}
                    remove={() => remove(item)}
                    save={() => save(item.product.id)}
                    like={item.product.favourites.length < 1 ? false : true}
                    key={key}
                    goToItem={() =>
                      history.push(`/product/${item.product.product_slug}`)
                    }
                    stock={
                      item.has_variation
                        ? item.selected_variation.quantity < item.quantity
                          ? true
                          : false
                        : item.product.stock_limit < item.quantity
                        ? true
                        : false
                    }
                    variation={
                      item.has_variation
                        ? "(" + item.selected_variation.variation + ")"
                        : ""
                    }
                  />
                );
              })}
              {cardCart.length < 1 && (
                <h4 className="text-center p-5">No item on cart</h4>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="cart-page-container p-3">
              <h6>Order summary</h6>
              <hr />

              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="text-left">Total :</td>
                    <td className="text-right">
                      ₦{" "}
                      {numberToComma(
                        cart.total_price ? cart.total_price : "no total price"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="row text-center">
                <div className="col-md-12">
                  <Button
                    className="cart-btn-complete"
                    disabled={cardCart.length < 1 ? true : false}
                    onClick={checkout}
                  >
                    Complete your purchase
                  </Button>
                </div>
                <div className="col-md-12 safe-pay-container">
                  <p className="safe-pay-text">
                    {" "}
                    <i class="fa fa-shield fa-lg" aria-hidden="true"></i> All
                    payments are safe and secure
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="recently-viewed">
          <div className="recent-cart-cont mb-3">
            <div>
              <h5>Recently viewed items</h5>
            </div>
          </div>

          <div className="cart-recent-container">
            {(recent || []).map((item, i) => {
              return (
                <>
                  {isMobile ? (
                    <CardFeatureMobile
                      photo={item.product.images[0]}
                      item={truncate(item.product.product_name, 20)}
                      price={numberToComma(item.product.net_price)}
                      desc={truncate(item.product.description, 25)}
                      like={item.product.favourites.length > 0 ? true : false}
                      soldout={item.product.stock_limit < 1 ? true : false}
                      oldPrice={item.product.price}
                      discount={item.product.discount}
                      action={() =>
                        history.push(`/product/${item.product.product_slug}`)
                      }
                      key={i}
                    />
                  ) : (
                    <CardFeature
                      photo={item.product.images[0]}
                      item={truncate(item.product.product_name, 20)}
                      price={numberToComma(item.product.net_price)}
                      desc={truncate(item.product.description, 25)}
                      like={item.product.favourites.length > 0 ? true : false}
                      soldout={item.product.stock_limit < 1 ? true : false}
                      oldPrice={item.product.price}
                      discount={item.product.discount}
                      action={() =>
                        history.push(`/product/${item.product.product_slug}`)
                      }
                      key={i}
                    />
                  )}
                </>
              );
            })}
          </div>
        </section>
      </section>
    </Layout>
  );
};

ProductCart.propTypes = {
  carts: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  carts: state.carts,
  cart: state.cart,
  user: state.emailLogin,
  payment: state.payment,
  recent: state.recent,
  favorites: state.favorites,
});

const pageActions = {
  fetchRemoveCart,
  fetchUpdateCart,
  fetchRemoveAllCart,
  fetchViewCart,
  fetchOrderSummary,
  fetchFavoritePost,
  fetchRecentView,
  fetchCancelOrder,
};
export default connect(mapStateToProps, pageActions)(ProductCart);
