import axios from 'axios';
import {
  FETCH_CATEGORY_PRODUCT,
  FETCH_CATEGORY_PRODUCT_SUCCESS,
  FETCH_CATEGORY_PRODUCT_FAILED,
  FETCH_CATEGORY,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constants'

import Auth from '../../config/auth'

const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};

export const fetchCategory = () => dispatch => {

  axios.get(`${BASE_URL}/products/category/list/shobbu`)
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_CATEGORY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_CATEGORY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CATEGORY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchCategoryProduct = (id, count) => dispatch => {
  var pageCount = 10 + (count ? count : 0);
  axios.get(`${BASE_URL}/products/view/shobbu/${id}?pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_CATEGORY_PRODUCT_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_CATEGORY_PRODUCT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CATEGORY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
