import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import cart from "../../images/cart.png";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

const CardSaved
 = (props) => {
  const {
    item,
    price,
    desc,
    photo,
    action,
    like,
    favourite,
    // oldPrice,
    // discount,
    soldout,
  } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  return (
    <Container>
      <div className="card-feature">
        <div className="card-feature-body">
          <div className="likes-feat">
          {like ? (
            <span style={{ color: "#f5a623" }} onClick={favourite}>
              <i className="fa fa-heart" aria-hidden="true"></i>
            </span>
          ) : (
            <span onClick={favourite}>
              <i className="fa fa-heart-o" aria-hidden="true"></i>
            </span>
          )}
          <span style={{ color: "#f5a623" }}>
            <i className="fa fa-eye ml-2" aria-hidden="true"></i>
          </span>
          </div>
          <div className="top-sell-side">
            <div className="item-image-container" onClick={action}>
              <img
                src={photo}
                className="card-item-image-container rounded"
                width="100%"
                alt="deal day"
                onError={addDefaultSrc}
              />
            </div>
          </div>
          <div>
            <p className="card-feature-name">{truncate(item, 25)}</p>
            <p className="card-feature-desc">{truncate(desc, 25)}</p>
            <p className="card-feature-name">₦ {numberToComma(price)}</p>
          </div>
          {/* <div className="d-flex justify-content-center card-feature-small">
            <div className="mr-3">
              <div className="">
                <del>₦ {oldPrice}</del>
              </div>
            </div>
            <div className="discount-item">
              <span>-{discount}%</span>
            </div>
          </div> */}
          <div className="card-actions">
            {soldout ? (
              <button onClick={action} className="btn btn-sm sold-out mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>sold out</small>
              </button>
            ) : (
              <button onClick={action} className="btn btn-sm add-cart mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>add to cart</small>
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardSaved
;
