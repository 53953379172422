import React, { Fragment } from "react";
import { Container } from "./Container";
import "./styles.scss";
import cart from "../../images/cart.png";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";
import Nostar from "../../pages/Nostar";
import Star from "../../pages/Star";

const CardTopSell = (props) => {
  const { item, price, desc, photo, action, rate, like, favourite } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  const thestar = (x) => {
    let nost = 5 - x;
    let rating = [];
    while (x > 0) {
      rating.push(<Star />);
      x--;
    }
    while (nost > 0) {
      rating.push(<Nostar />);
      nost--;
    }

    return (
      <Fragment>
        <span>{rating}</span>
      </Fragment>
    );
  };

  return (
    <Container>
      <div className="card-top-sell">
        <div className="card-top-sell-body">
          <div className="top-sell-side">
            <div className="item-image-container" onClick={action}>
              <img
                src={photo}
                className="card-item-image-container rounded"
                width="100%"
                alt="deal day"
                onError={addDefaultSrc}
              />
            </div>
          </div>
          <div>
            <p className="card-top-sell-name">{truncate(item, 25)}</p>
            <p className="card-top-sell-desc">{truncate(desc, 25)}</p>
            <p className="card-top-sell-name">₦ {numberToComma(price)}</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="mr-3">
              <div className="star-icon">
               {/*  {rate}
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                  style={{ color: "#ff804c" }}
                ></i> */}
                {thestar(rate)}
                <p>{rate}.0</p>
              </div>
            </div>
            <div>
              <div>
                <i
                  className="fa fa-shopping-cart fa-lg"
                  aria-hidden="true"
                  style={{ color: "#0279B8" }}
                ></i>
              </div>
            </div>
          </div>
          <div className="card-actions">
            <div>
              <div className="heart-container-top" onClick={favourite}>
                {like ? (
                  <span style={{ color: "#0279B8" }}>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </span>
                ) : (
                  <i className="fa fa-heart-o" aria-hidden="true"></i>
                )}
                <p>Wishlist</p>
              </div>
            </div>
            <div onClick={action}>
              <div className="heart-container-top">
                <span style={{ color: "#0279B8" }}>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </span>
                <p>Quick view</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardTopSell;
