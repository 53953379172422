import axios from 'axios';
import {
  FETCH_PAYMENT_SUCCESS,
  FETCH_PAYMENT_FAILED
} from '../types';
import {
  BASE_URL
} from '../constants'
import Auth from '../../config/auth'
import {
  fetchRemoveAllCart
} from '../actions/products'


export const fetchPaymentInit = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/orders/init`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_PAYMENT_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_PAYMENT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PAYMENT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchPaymentHandle = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/orders/verify`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_PAYMENT_SUCCESS,
          payload: response.data
        })
        dispatch(fetchRemoveAllCart())
      } else {
        dispatch({
          type: FETCH_PAYMENT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PAYMENT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
