import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import photo from "../../images/car-sell.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SellAds = (props) => {
  const {navigate} = props;
  return (
    <Container>
      <section className="sell-ads-container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h3>Why become a seller ?</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam feliultricies nec, pellentesque eu, ptium quis, sem.
              Nulla consequat massa quis enim.
            </p>

            <Link to="/sell">
              <button className="btn-sell-ads">Start selling</button>
            </Link>
            <i className="fa fa-bar-chart pl-3 fa-2x" aria-hidden="true"></i>
          </div>
          <div className="col-sm-6 sell-car-img">
            <img src={photo} alt="car sell" width="70%" />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default SellAds;
