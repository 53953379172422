import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../images/logo.svg";
import "./styles.scss";
import Register from "../Register/Register";
import GoogleLogin from "react-google-login";

import { CLIENT_ID } from "../../redux/constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchUserGoogleLogin,
} from "../../redux/actions/authentication";
import { useHistory, useLocation } from "react-router-dom";

const Signup = props => {
  const { buttonLabel, className } = props;
  let history = useHistory();
  let location = useLocation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function responseGoogle(res) {
    console.log(res);
    const data = { token: res.tokenId };
    props.fetchUserGoogleLogin(data);
  }

  function responseGoogleError(res) {
    console.log(res)
  }

  useEffect(() => {
    //setLoading(false);
   // if (Auth.getToken()) {
      // NotificationManager.success("Logout success");
      if (props.user.user.message) {
        history.go(location.pathname);
        //setErrors({ ...errors, yesError: false });
        //setForm({ ...form, email: "", password: "" });
      }
    //}
  }, [props.user.user]);

  useEffect(() => {
   // console.log(props.user.errors.errors);
    //console.log(props.user);
    //setLoading(false);
    if (props.user.errors.errors) {
      //setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
      //setForm({ ...form, email: "", password: "" });
      //NotificationManager.error(props.user.errors.errors.message);
    }
  }, [props.user.errors]);

  return (
    <div>
      <Button className="text-white"  color="" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} className="text-center">
          <img src={logo} alt="logo" width="100" height="50" />
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div className="p-5">
              <h5>Hello, </h5>
              <h5> Welcome to Shobbu</h5>
            </div>
            <Register buttonLabel="Sign up" toggle={toggle} />
            <small class="form-text text-muted mt-4 mb-4">-or-</small>
            <GoogleLogin
                clientId={CLIENT_ID}
                // buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="btn-google mb-3"
                  >
                    <i className="fa fa-google fa-lg" aria-hidden="true"></i>
                    <span>Continue with Google</span>
                  </button>
                )}
              />
            <br />
            <button class="btn-facebook mb-3">
              <i class="fa fa-facebook" aria-hidden="true"></i> Continue with
              Facebook
            </button>
            <button class="btn-email">Open a store</button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

Signup.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserEmailLogin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.emailLogin,
});

const pageActions = { fetchUserGoogleLogin };

export default connect(mapStateToProps, pageActions)(Signup);
