import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import photo from "../../images/photo.png";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAuction, fetchPostBid } from "../../redux/actions/auctions";

import numberToComma from "../../utils/numberToComma";
import Auth from "../../config/auth";

import ImageGallery from "react-image-gallery";
import { Tabs, Tab } from "react-bootstrap";

import cart from "../../images/cart.png";
import Star from "../Star";
import Nostar from "../Nostar";
import dayjs from "dayjs";

const PlaceBid = (props) => {
  const {
    match: { params },
  } = props;
  const [auction, setAuction] = useState({});
  const [bids, setBids] = useState([]);
  const [amount, setAmount] = useState();
  const [check, setCheck] = useState(false);
  const [img, setImg] = useState([]);
  const user = Auth.getUser();

  const addBid = (e) => {
    e.preventDefault();
    setCheck(true);
    const data = { my_bid: parseInt(amount) };
    props.fetchPostBid(params.item, data);
  };

  useEffect(() => {
    props.fetchAuction(params.item);
  }, []);

  useEffect(() => {
    props.fetchAuction(params.item);
  }, [params.item]);

  useEffect(() => {
    if (props.auction.auction.data) {
      console.log(props.auction.auction.data);
      setAuction(props.auction.auction.data.auction);
      setBids(props.auction.auction.data.bids.bids);
      setImg(props.auction.auction.data.auction.product.images);
      if (check) {
        props.auction.auction.message != null &&
          swal(props.auction.auction.message, "", "success");
        setAmount();
        props.fetchAuction(params.item);
        setCheck(false);
      }
    }
  }, [props.auction.auction]);

  useEffect(() => {
    if (props.auctionBid.auctionBid.data) {
      if (check) {
        props.auctionBid.auctionBid.message != null &&
          swal(props.auctionBid.auctionBid.message, "", "success");
        setAmount("");
        setCheck(false);
      }
    }
  }, [props.auctionBid.auctionBid]);

  useEffect(() => {
    if (props.auctionBid.errors.errors) {
      if (check) {
        props.auctionBid.errors.errors.message != null &&
          swal(props.auctionBid.errors.errors.message, "", "error");
        setAmount("");
        setCheck(false);
      }
    }
  }, [props.auctionBid.errors.errors]);

  const prod_image = img.map((index, i) => {
    const imaged = {
      original: index,
      thumbnail: index,
      originalClass: "image-view",
    };
    return imaged;
  });

  const thestar = (x) => {
    let nost = 5 - x;
    let rating = [];
    while (x > 0) {
      rating.push(<Star />);
      x--;
    }
    while (nost > 0) {
      rating.push(<Nostar />);
      nost--;
    }

    return (
      <>
        <span>{rating}</span>
      </>
    );
  };

  return (
    <Layout>
      <section className="place-bid-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link>{" "}
          <i className="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/auction">Auction</Link>{" "}
          <i className="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`/place-bid/${auction.id}`}>
            Place bid -{auction.product ? auction.product.product_name : ""}
          </Link>
          <p className="title-page">Place bid</p>
        </div>

        <section className="row">
          <div className="col-md-7 image-gall">
            <ImageGallery
              items={prod_image}
              showPlayButton={false}
              onErrorImageURL={cart}
              thumbnailPosition="left"
            />
          </div>

          <div className="col-md-5">
            <div>
              <h3>{auction.product ? auction.product.product_name : ""}</h3>
              <p className="item-bid-price">
                <strong>
                  Price: ₦{" "}
                  {numberToComma(
                    auction.starting_price ? auction.starting_price : "no price"
                  )}
                </strong>
              </p>
              <p className="item-price">
                {" "}
                Current Bid: ₦{" "}
                {numberToComma(
                  auction.current_price ? auction.current_price : "no price"
                )}
              </p>
              <p>
                {thestar(auction.product ? auction.product.rating : 0)} (
                {auction.product ? auction.product.rating : 0} review yet)
              </p>
              <p>Item condition: New</p>
              <p>
                {" "}
                <strong>Time left: {auction.end_time}</strong>
              </p>
              <p>
                <small>
                  Auction ends:{" "}
                  {dayjs(auction.end_date).format("MMMM D, YYYY h:mm A")}
                </small>
              </p>
              <form onSubmit={addBid} className="row">
                <div className="form-group">
                  <input
                    type="number"
                    className="place-bid-input"
                    placeholder={`Enter Bid above ${numberToComma(
                      auction.current_price ? auction.current_price : "no price"
                    )}`}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-sm add-cart-bid">
                 <span> bid{" "}
                  <i className="fa fa-gavel" aria-hidden="true"></i></span>
                </button>
              </form>
              <p>
                <input type="checkbox" name="agree" id="" /> i agree with terms
                and conditions
              </p>
              <div className="pt-3">
                <h6>share with friends </h6>
                <span>
                  <i
                    className="fa fa-facebook fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-youtube fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-twitter fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-instagram fa-lg mr-1"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-4 mb-4">
        
          <Tabs
            defaultActiveKey="description"
            id="uncontrolled-tab-example"
            className="text-primary"
          >
            <Tab eventKey="description" title="Description">
              <p className="mt-2">{auction.product?.description}</p>
            </Tab>
            <Tab eventKey="specification" title="Specification">
              <div className="mt-2">{auction.product?.specification}</div>
            </Tab>
            {/* <Tab eventKey="review" title="Reviews">
              <div className="mt-2 pt-3">
                {(reviews || []).map((item, i) => {
                  return (
                    <div>
                      <div className="row text-left" key={i}>
                        <div className="col-md-3">
                          <p>{item.user.full_name}</p>
                        </div>
                        <div className="col-md-7">
                          <p>{item.review}</p>
                        </div>
                        <div className="col-md-2">
                          <p>
                            {item.star}{" "}
                            <i class="fa fa-star" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
                {reviews.length < 1 && <p>No reviews yet</p>}
              </div>
            </Tab> */}
          </Tabs>
        </section>

        <section>


        <h5 className="p-2">Leader board</h5>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Bidder no.</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(bids || []).map((item, i) => {
                      return (
                        <tr key={i}>
                          <th>
                            #{item.id} {user.id === item.user_id && "(YOU)"}
                          </th>
                          <td>{numberToComma(item.bid)}</td>
                          <td>{item.winner ? "Winning" : "Loosing"}</td>
                          <td>{dayjs(item.updated_at).format("MMMM D, YYYY h:mm A")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

        {/* <div className="place-bid-item">
              <div className="place-bid-item-body p-4">
                <h5 className="p-2">Leader board</h5>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Bidder no.</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(bids || []).map((item, i) => {
                      return (
                        <tr key={i}>
                          <th>
                            #{item.id} {user.id === item.user_id && "(YOU)"}
                          </th>
                          <td>{numberToComma(item.bid)}</td>
                          <td>{item.winner ? "Winning" : "Loosing"}</td>
                          <td>{dayjs(item.updated_at).format("MMMM D, YYYY h:mm A")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div> */}
        </section>
      </section>
    </Layout>
  );
};

PlaceBid.propTypes = {
  auction: PropTypes.object.isRequired,
  fetchAuction: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auction: state.auction,
  auctionBid: state.auctionBid,
});

const pageActions = { fetchAuction, fetchPostBid };
export default connect(mapStateToProps, pageActions)(PlaceBid);
