import React from "react";

import ios from "../../images/ios.svg";
import android from "../../images/android.svg";
import master from "../../images/master.png";
import paypal from "../../images/paypal.png";
import visa from "../../images/visa.png";
import verve from "../../images/verve.png";

import "./styles.scss";
import { Link } from "react-router-dom";

const Footer = props => {
  return (
    <footer className="footer">
      <div className="container text-center text-md-left">
        <div className="row justify-content-space-around p-5">
          <div className="col-md-3 mx-auto">
            <h6 className="font-weight-bold mt-3 mb-2">How Can We Help</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Become a seller</Link>
              </li>
              <li>
                <Link to="/">Market Place</Link>
              </li>
              <li>
                <Link to="/">Auction sales</Link>
              </li>
              <li>
                <Link to="/">Customize domain</Link>
              </li>
            </ul>
          </div>

          <hr className="clearfix w-100 d-md-none"></hr>

          <div className="col-md-3 mx-auto">
            <h6 className="font-weight-bold mt-3 mb-2">Get to Know Us</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/">Policies</Link>
              </li>
              <li>
                <Link to="/">Careers</Link>
              </li>
              <li>
                <Link to="/">Private Policy</Link>
              </li>
              <li>
                <Link to="/">Terms and conditions</Link>
              </li>
            </ul>
          </div>

          <hr className="clearfix w-100 d-md-none"></hr>

          <div className="col-md-3 mx-auto">
            <h6 className="font-weight-bold mt-3 mb-2">Download Shobbu</h6>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/">
                  <img src={ios} alt="ios" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={android} alt="android" />
                </Link>
              </li>
            </ul>
          </div>

          <hr className="clearfix w-100 d-md-none"></hr>

          <div className="col-md-3 mx-auto">
            <h6 className="font-weight-bold mt-3 mb-2">Follow Us</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">
                  <i className="fa fa-facebook" aria-hidden="true"></i> Facebook
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-youtube" aria-hidden="true"></i> Youtube
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-twitter" aria-hidden="true"></i> Twitter
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                  Instagram
                </Link>
              </li>
            </ul>
          </div>
          <hr className="clearfix w-100 d-md-none"></hr>
        </div>
      </div>

      <ul className="nav justify-content-center light nav-footer nav-foot pl-5 pr-5 pt-2">
        <li>
        Copyrights &copy; 2020 shobbu. All rights reserved
        </li>
        <li ><img src={master} alt="logo" width="50px"/></li>
        <li ><img src={visa} alt="logo" width="50px"/></li>
        <li ><img src={paypal} alt="logo" width="50px"/></li>
        <li ><img src={verve} alt="logo" width="50px"/></li>
      </ul>
    </footer>
  );
};

export default Footer;
