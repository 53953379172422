import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../images/logo.svg";
import "./styles.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import GoogleLogin from "react-google-login";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchUserEmailLogin,
  fetchUserGoogleLogin,
} from "../../redux/actions/authentication";

import isEmpty from "../../utils/isEmpty";
import Auth from "../../config/auth";
import { CLIENT_ID, BASE_URL } from "../../redux/constants";
import Axios from "axios";

const Login = (props) => {
  let history = useHistory();
  let location = useLocation();
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({
    message: "",
    code: "",
    yesError: false,
  });

  function responseGoogle(res) {
    console.log(res);
    const data = { token: res.tokenId };
    handleGoogle(data);
  }

  function responseGoogleError(res) {
    console.log(res);
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   setLoading(true);
  //   const userData = {
  //     email: form.email,
  //     password: form.password,
  //     store_id: "1585139305",
  //   };
  //   props.fetchUserEmailLogin(userData);
  // }

  async function handleGoogle(data) {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${BASE_URL}/users/google/login`,
        data
      );
      if (response.data.hasError) {
        NotificationManager.error(response.data.errors.message);
        setLoading(false);
      } else {
        const { user, token } = response.data.data;
        Auth.setToken(token, user);
        history.push(location.pathname);
        setLoading(false);
        NotificationManager.success(response.data.message);
        toggle();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const userData = {
      email: form.email,
      password: form.password,
      store_id: "1585139305",
    };
    try {
      const response = await Axios.post(
        `${BASE_URL}/users/login/email`,
        userData
      );
      if (response.data.hasError) {
        // setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
        setForm({ ...form, password: "" });
        NotificationManager.error(response.data.errors.message);
        setLoading(false);
      } else {
        const { user, token } = response.data.data;
        Auth.setToken(token, user);
        //console.log(user)
        
        history.push(location.pathname);
        //setErrors({ ...errors, yesError: false });
        setForm({ ...form, email: "", password: "" });
        setLoading(false);
        NotificationManager.success(response.data.message);
        toggle();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   setLoading(false);
  // NotificationManager.success("Logged in successfull");
  //  if (Auth.getToken()) {
  //   NotificationManager.success("Logged in successfull");
  // }

  // if (Auth.getToken()) {

  //   if (props.user.user.data) {

  //     history.push(location.pathname);
  //     setErrors({ ...errors, yesError: false });
  //     setForm({ ...form, email: "", password: "" });
  //   }
  // }
  //}, [props.user.user]);

  // useEffect(() => {
  //   console.log(props.user.errors.errors);
  //   console.log(props.user);
  //   setLoading(false);
  //   if (props.user.errors.errors) {
  //     setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
  //     setForm({ ...form, email: "", password: "" });
  //     NotificationManager.error(props.user.errors.errors.message);
  //   }
  // }, [props.user.errors]);

  return (
    <div>
      <Button className="text-white"  color="" onClick={toggle}>
        {buttonLabel}
      </Button>
      <NotificationContainer />
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div className="justify-content-center">
          <ModalHeader toggle={toggle} className="text-center">
            <div className="login-header">
              <img src={logo} alt="logo" width="100" height="50" />
            </div>
          </ModalHeader>
          <ModalBody>
            <NotificationContainer />
            <form className="text-center p-3" onSubmit={handleSubmit}>
              {errors.yesError && (
                <div className="alert alert-danger">{errors.message}</div>
              )}
              {!isEmpty(state.user) && (
                <div className="alert alert-success">
                  {props.user.user.message}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  name="email"
                  className="form-control login-input"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  autoFocus
                  required
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control login-input"
                  id="exampleInputPassword1"
                  autoFocus
                  required
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </div>

              <div className="d-flex justify-content-between">
                <label>
                  <input type="checkbox" id="checkbox2" /> keep me Logged in
                </label>
                <Link to="/forgot-password">forgot password</Link>
              </div>

              <button
                type="submit"
                className="btn login-btn text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Log In"}
              </button>
              <small className="form-text text-muted p-3">-or-</small>
              <GoogleLogin
                clientId={CLIENT_ID}
                // buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="btn-google mb-3"
                  >
                    <i className="fa fa-google fa-lg" aria-hidden="true"></i>
                    <span>Continue with Google</span>
                  </button>
                )}
              />
              <br />
              <button className="btn-facebook">
                <i
                  className="fa fa-facebook-official fa-lg"
                  aria-hidden="true"
                ></i>
                <span>Continue with Facebook</span>
              </button>
            </form>
          </ModalBody>
          <ModalFooter>
            <a href="/sell" className="btn btn-block btn-sm text-info">
              Create store
            </a>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserEmailLogin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // user: state.emailLogin,
});

const pageActions = { fetchUserEmailLogin, fetchUserGoogleLogin };

export default connect(mapStateToProps, pageActions)(Login);
