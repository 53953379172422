import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";
import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";
import { isMobile, isDesktop } from "../../utils/isLayout";

import { fetchNotifications } from "../../redux/actions/notifications";
import { connect } from "react-redux";
import CardNotification from "../../component/CardNotification/CardNotification";
import ViewMore from "../../component/ViewMore/ViewMore";

const Notifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPage] = useState(10);

  function viewMore() {
    props.fetchNotifications(pageCount);
    setPage(pageCount + 10);
  }

  useEffect(() => {
    props.fetchNotifications();
  }, []);

  useEffect(() => {
    if (props.notifications.notifications.data) {
      setNotifications(props.notifications.notifications.data.notification);
    }
  }, [props.notifications.notifications]);

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/notifications">Notifications</Link>
          <p className="title-page">Notifications</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                <table className="table table-borderless table-striped table-hover">
                  <tbody>
                  {notifications.map((notification, i) => (
                    <tr>
                      <td>
                        <CardNotification
                          title={notification.title}
                          date={notification.updatedAt}
                          message={notification.message}
                          key={i}
                        />
                      </td>
                    </tr>
                  ))}
                  </tbody>
                  
                </table>

                {notifications.length < 1 && (
                  <h4 className="text-center p-5">
                    No Notifications at the moment
                  </h4>
                )}
              </div>
            </div>
            <ViewMore viewHandler={viewMore} />
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const pageActions = {
  fetchNotifications,
};

export default connect(mapStateToProps, pageActions)(Notifications);
