import {
  FETCH_ADDRESS,
  FETCH_ADDRESS_FAILED,
  FETCH_ADDRESS_SUCCESS
} from '../../types';

const initialState = {
  address: {},
  isLoading: false,
  errors: {},
  error: false
}


const fetchAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESS:
      return {
        ...state,
        isLoading: true,
          address: action.payload
      }
      case FETCH_ADDRESS_SUCCESS:
        return {
          ...state,
          isLoading: false,
            address: action.payload
        }
        case FETCH_ADDRESS_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchAddressReducer;
