import React from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";

const OrderPlaced = (props) => {
  // Remove saved data from sessionStorage
  sessionStorage.removeItem("order_id");
  return (
    <Layout>
      <section className="order-placed-section">
        <div className="p-2 text-info">
          <div className="bread-crumbs pt-3 pl-5 pb-3">
            <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
            <Link to="/product-cart">Cart</Link>
            <i class="fa fa-caret-right pl-2 pr-2"></i>
            <Link to="/product-checkout">Checkout</Link>
          </div>
          <div className="order-placed-container">
            <div className="text-center">
              <h1>Congratulations</h1>
              <h6>Order placed successfully</h6>
              <div className="row p-4">
                <div className="col-md-12">
                  <Link to="/order">
                    <button className="sell text-white">go to my orders</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OrderPlaced;
