import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";
import Market from "./pages/Market";
import Auction from "./pages/Auction";
import Product from "./pages/Product";
import ProductCategory from "./pages/ProductCategory";
import ProductSaved from "./pages/ProductSaved";
import ProductCart from "./pages/ProductCart";
import ProductCheckout from "./pages/ProductCheckout";
import OrderPlaced from "./pages/OrderPlaced/OrderPlaced";
import PlaceBid from "./pages/PlaceBid";
import Order from "./pages/Order";
import Bid from "./pages/Bid";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";

import Auth from "./config/auth";
import Sell from "./pages/Sell/Sell";
import Setting from "./pages/Setting";
import OrderVerify from "./pages/OrderVerify/OrderVerify";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Notifications from "./pages/Notifications";
import Stores from "./pages/Stores";
import OrderInfo from "./pages/OrderInfo/OrderInfo";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Search from "./pages/Search/Search";
import Revamp from "./pages/Revamp";

const App = (props) => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Switch>
      <Route exact path="/revamp" component={Revamp} />
        <Route exact path="/" component={Market} />
        <Route exact path="/auction" component={Auction} />
        <Route exact path="/sell" component={Sell} />
        <Route exact path="/product/:item" component={Product} />
        <Route
          exact
          path="/product-category/:category"
          component={ProductCategory}
        />
        <PrivateRoute exact path="/place-bid/:item" component={PlaceBid} />
        <Route exact path="/bid" component={Bid} />
        <Route exact path="/product-cart" component={ProductCart} />
        <PrivateRoute exact path="/order" component={Order} />
        <PrivateRoute
          exact
          path="/product-checkout"
          component={ProductCheckout}
        />
        <PrivateRoute exact path="/product-saved" component={ProductSaved} />
        <PrivateRoute exact path="/order-placed" component={OrderPlaced} />
        <PrivateRoute exact path="/setting" component={Setting} />
        <PrivateRoute exact path="/change-password" component={ChangePassword} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/order-verify" component={OrderVerify} />
        <PrivateRoute exact path="/stores" component={Stores} /> 
        <PrivateRoute exact path="/order-info/:order" component={OrderInfo} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/search" component={Search} />
        <Route exact path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default App;
