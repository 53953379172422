import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import ads from "../../images/ads.png";
import banner1 from "../../images/banner1.jpg";
import banner3 from "../../images/banner3.jpg";
import banner4 from "../../images/banner4.jpg";
import banna from "../../images/banna.jpeg";
import home from "../../images/home.png";
import item from "../../images/item.png";
import avocado from "../../images/avocado.png";
import chair from "../../images/chair.png";
import light from "../../images/light.png";
import automobile from "../../images/automobile.png";
import furniture from "../../images/furniture.png";
import groceries from "../../images/groceries.png";
import electronics from "../../images/electronics.png";
import sports from "../../images/sports.png";
import fashion from "../../images/fashion.png";

import brand1 from "../../images/brand1.png";
import brand2 from "../../images/brand2.png";
import brand3 from "../../images/brand3.png";
import brand4 from "../../images/brand4.png";
import brand5 from "../../images/brand5.png";
import brand6 from "../../images/brand6.png";

import CardCategory from "../../component/CardCategory/CardCategory";
import ViewMore from "../../component/ViewMore/ViewMore";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAllProducts,
  fetchTrendingProducts,
  fetchFavoritePost,
  fetchFavoriteRemove,
} from "../../redux/actions/products";
import { fetchCategory } from "../../redux/actions/category";
import { fetchStores } from "../../redux/actions/stores";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import {
  InputGroup,
  InputGroupButtonDropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  Button,
} from "reactstrap";
import CardDealDay from "../../component/CardDealDay/CardDealDay";
import CardTopSeller from "../../component/CardTopSeller/CardTopSeller";
import SellAds from "../../component/SellAds/SellAds";
import { Link, useHistory } from "react-router-dom";
import Slider from "../../component/Slider/Slider";
import CardSmall from "../../component/CardSmall/CardSmall";
import CardStore from "../../component/CardStore/CardStore";

import Axios from "axios";
import { BASE_URL } from "../../redux/constants";
import Auth from "../../config/auth";
import swal from "@sweetalert/with-react";
import CardTopSell from "../../component/CardTopSell/CardTopSell";
import CardFeature from "../../component/CardFeature/CardFeature";
import CardArrival from "../../component/CardArrival/CardArrival";

import { isMobile, isDesktop } from "../../utils/isLayout";
import CardFeatureMobile from "../../component/CardFeatureMobile/CardFeatureMobile";
import CardTopSellMobile from "../../component/CardTopSellMobile/CardTopSellMobile";
import CardArrivalMobile from "../../component/CardArrivalMobile/CardArrivalMobile";
import CardStoreMobile from "../../component/CardStoreMobile/CardStoreMobile";

const Market = (props) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const [state, setState] = useState({ data: [], banners: [] });
  const [data, setData] = useState([]);
  const [category, setCategory] = useState({ category: [] });
  const [trends, setTrends] = useState([]);
  const [stores, setStores] = useState([]);
  const [search, setSearch] = useState({ name: "", category: "" });
  const [check, setCheck] = useState(false);
  const [pageCount, setPage] = useState(15);

  const [view, setView] = useState(false);
  const tog = () => setView(!view);

  function viewMore() {
    props.fetchAllProducts(pageCount);
    setPage(pageCount + 10);
  }

  const saveItem = (id) => {
    props.fetchFavoritePost(id);
    // props.fetchTrendingProducts();
    setCheck(true);
  };

  function removeFav(id) {
    props.fetchFavoriteRemove(id);
    // props.fetchTrendingProducts();
    setCheck(true);
  }

  async function followStore(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { store_id: id };
    try {
      const response = await Axios.post(
        `${BASE_URL}/merchants/store-favourite`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        //swal(response.data.message,"","success")
        props.fetchStores();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function unfollowStore(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(
        `${BASE_URL}/merchants/store-favourite/${id}`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        //swal(response.data.message,"","success")
        props.fetchStores();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.fetchAllProducts();
    props.fetchTrendingProducts();
    props.fetchStores();
  }, []);

  useEffect(() => {
    if (check) {
      props.fetchAllProducts(pageCount);
      //NotificationManager.success(props.favorites.favorites.message);
      setCheck(false);
    }
  }, [props.favorites.favorites]);

  useEffect(() => {
    const { error, products } = props.products;
    if (!error) {
      if (products.data) {
        setData(products.data.product);
        // setState({ ...state, data: products.data.product });
        setState({ ...state, banners: products.data.store.banners });
      }
    }
  }, [props.products.products]);

  useEffect(() => {
    const { error, category } = props.category;
    if (!error) {
      if (category.data) {
        setCategory({ ...category, category: category.data.store.categories });
      }
    }
    //console.log(props.user);
  }, [props.category.category]);

  useEffect(() => {
    const { trends } = props.trends;
    if (trends.data) {
      setTrends(trends.data.trending);
    }
  }, [props.trends.trends]);

  useEffect(() => {
    if (props.stores.stores.data) {
      setStores(props.stores.stores.data.stores.docs);
    }
  }, [props.stores.stores]);

  return (
    <Layout>
      <NotificationContainer />

      <div className="carousel-container">
        <Carousel
          interval={3000}
          indicators
          nextIcon={<div></div>}
          prevIcon={<div></div>}
        >
          <Carousel.Item className="img-wrap">
            <div className="carosel-text">
              <p>SHOP TO GET WHAT YOU LOVE</p>
              <h1>Furniture that makes</h1>
              <h1>
                a statement up to <span className="carou-h1">70% off</span>
              </h1>
              <button className="btn-sm carou-btn">SHOP NOW</button>
            </div>

            <img
              className="d-block w-100 carosel"
              src={home}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item className="img-wrap">
            <div className="carosel-text">
              <p>SHOP TO GET WHAT YOU LOVE</p>
              <h1>Furniture that makes</h1>
              <h1>
                a statement up to <span className="carou-h1">70% OFF</span>
              </h1>
              <button className="btn-sm carou-btn">SHOP NOW</button>
            </div>
            <img
              className="d-block w-100 carosel"
              src={home}
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="market">
        <section className="top-1">
          <div className="row">
            <div className="col-md-7 row">
              <div className="col-md-6 grocery mb-2 text-center">
                <Link to="/product-category/3">
                  <img src={groceries} alt="bid" className="" width="100%" />
                </Link>
              </div>
              <div className="col-md-6 fashion">
                <Link to="/product-category/4">
                  <img src={fashion} alt="bid" className="" width="100%" />
                </Link>
              </div>
              <div className="col-md-12 mt-3 electronics">
                <Link to="/product-category/4">
                  <img src={electronics} alt="bid" className="" width="100%" />
                </Link>
              </div>
              <div className="col-md-12 mt-3 sports">
                <Link to="/product-category/8">
                  <img src={sports} alt="bid" className="" width="100%" />
                </Link>
              </div>
            </div>
            <div className="col-md-5 mt-2">
              <div className="col-md-12 furniture">
                <Link to="/product-category/14">
                  <img src={furniture} alt="bid" className="" width="100%" />
                </Link>
              </div>
              <div className="col-md-12 mt-3 automobile">
                <Link to="/product-category/7">
                  <img src={automobile} alt="bid" className="" width="100%" />
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="topseller-cont">
            <div className="top-sell-text-cont">
              <p className="top-text">
                {" "}
                <strong>Top</strong> Seller
              </p>
              <p>Weekely line up for our best sellers</p>
            </div>
            <div className="arrow-top-sell">
              <span className="">
                <i
                  className="fa fa-arrow-left arrow-icon"
                  aria-hidden="true"
                ></i>
              </span>
              <span className="">
                <i
                  className="fa fa-arrow-right arrow-icon"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          </div>

          <div className="topseller-items">
            {(trends || []).map((item, i) => {
              return (
                <>
                  {isMobile ? (
                    <CardTopSellMobile
                      photo={item.product.images[0]}
                      item={item.product.product_name}
                      rate={item.product.rating}
                      price={item.product.net_price}
                      desc={item.product.description}
                      like={item.product.favourites.length > 0 ? true : false}
                      favourite={() => {
                        if (item.product.favourites.length > 0) {
                          removeFav(item.product_id);
                        } else {
                          saveItem(item.product_id);
                        }
                      }}
                      action={() =>
                        history.push(`product/${item.product.product_slug}`)
                      }
                    />
                  ) : (
                    <CardTopSell
                      photo={item.product.images[0]}
                      item={item.product.product_name}
                      rate={item.product.rating}
                      price={item.product.net_price}
                      desc={item.product.description}
                      like={item.product.favourites.length > 0 ? true : false}
                      favourite={() => {
                        if (item.product.favourites.length > 0) {
                          removeFav(item.product_id);
                        } else {
                          saveItem(item.product_id);
                        }
                      }}
                      action={() =>
                        history.push(`product/${item.product.product_slug}`)
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        </section>

        {/* advertist */}
        <section className="mt-3 mb-2">
          <div className="row">
            <img
              src={chair}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
              height="150px"
            />
            <img
              src={light}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
              height="150px"
            />
          </div>
        </section>

        {/* featured */}
        <section>
          <div className="topseller-cont">
            <div className="top-sell-text-cont">
              <h5>Featured</h5>
            </div>
          </div>

          <div className="feature-items">
            <ul className="feature-items-list">
              {data.map((item, i) => {
                return (
                  <li className="" key={i}>
                    {isMobile ? (
                      <CardFeatureMobile
                        photo={item.images[0]}
                        item={truncate(item.product_name, 20)}
                        price={numberToComma(item.net_price)}
                        desc={truncate(item.description, 25)}
                        like={item.favourites.length > 0 ? true : false}
                        soldout={item.stock_limit < 1 ? true : false}
                        oldPrice={item.price}
                        discount={item.discount}
                        favourite={() => {
                          if (item.favourites.length > 0) {
                            removeFav(item.id);
                          } else {
                            saveItem(item.id);
                          }
                        }}
                        action={() =>
                          history.push(`/product/${item.product_slug}`)
                        }
                      />
                    ) : (
                      <CardFeature
                        photo={item.images[0]}
                        item={truncate(item.product_name, 20)}
                        price={numberToComma(item.net_price)}
                        desc={truncate(item.description, 25)}
                        like={item.favourites.length > 0 ? true : false}
                        soldout={item.stock_limit < 1 ? true : false}
                        oldPrice={item.price}
                        discount={item.discount}
                        favourite={() => {
                          if (item.favourites.length > 0) {
                            removeFav(item.id);
                          } else {
                            saveItem(item.id);
                          }
                        }}
                        action={() =>
                          history.push(`/product/${item.product_slug}`)
                        }
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <ViewMore viewHandler={viewMore} />
        </section>

        {/* advertist */}
        <section className="phone-ads mt-3 mb-2">
          <div className="row">
            <img
              src={banner1}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
            <img
              src={banner3}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
          </div>
        </section>

        {/* start selling */}

        <section className=" selling pt-5 pb-3">
          <SellAds />
        </section>

        {/* arrivals */}
        <section>
          <div className="topseller-cont">
            <div className="top-sell-text-cont">
              <p className="top-text">
                {" "}
                <strong>New</strong> Arrivals
              </p>
              <p>Weekly line up for our new arrivals</p>
            </div>
          </div>

          <div className="feature-items">
            <ul className="feature-items-list">
              {data.map((item, i) => {
                return (
                  <li className="" key={i}>
                    {isMobile ? (
                      <CardArrivalMobile
                        photo={item.images[0]}
                        item={truncate(item.product_name, 20)}
                        price={numberToComma(item.net_price)}
                        desc={truncate(item.description, 25)}
                        like={item.favourites.length > 0 ? true : false}
                        soldout={item.stock_limit < 1 ? true : false}
                        oldPrice={item.price}
                        discount={item.discount}
                        favourite={() => {
                          if (item.favourites.length > 0) {
                            removeFav(item.id);
                          } else {
                            saveItem(item.id);
                          }
                        }}
                        action={() =>
                          history.push(`/product/${item.product_slug}`)
                        }
                      />
                    ) : (
                      <CardArrival
                        photo={item.images[0]}
                        item={truncate(item.product_name, 20)}
                        price={numberToComma(item.net_price)}
                        desc={truncate(item.description, 25)}
                        like={item.favourites.length > 0 ? true : false}
                        soldout={item.stock_limit < 1 ? true : false}
                        oldPrice={item.price}
                        discount={item.discount}
                        favourite={() => {
                          if (item.favourites.length > 0) {
                            removeFav(item.id);
                          } else {
                            saveItem(item.id);
                          }
                        }}
                        action={() =>
                          history.push(`/product/${item.product_slug}`)
                        }
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        {/* advertist */}
        <section className="avocado-selling mt-3 mb-2">
          <div className="row">
            <div className="col-md-6">
              <div className="img-wrap-2 mb-2">
                <div className="image-text">
                  <p className="ad-title">Fresh Avocado</p>
                  <h5 className="ad-text">70% OFF</h5>
                </div>
                <img src={avocado} className="bid-img" alt="bid"  width="100%" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-wrap-2 ">
                <div className="image-text">
                  <p className="ad-title">Brand New Chevrolet</p>
                  <h5 className="ad-text">70% OFF</h5>
                </div>
                <img src={avocado} className="bid-img" alt="bid" width="100%" />
              </div>
            </div>
          </div>
        </section>

        {/* brands */}
        <section>
          <div className="topseller-cont mb-3">
            <div className="top-sell-text-cont">
              <p className="top-text">
                {" "}
                <strong>Brands</strong> We Love
              </p>
            </div>
          </div>

          <div className="brand-items">
            <ul className="brands">
              <li>
                <img src={brand1} alt=" brand" width="100%" />
              </li>
              <li>
                <img src={brand2} alt=" brand" width="100%" />
              </li>
              <li>
                <img src={brand3} alt=" brand" width="100%" />
              </li>
              <li>
                <img src={brand4} alt=" brand" width="100%" />
              </li>
              <li>
                <img src={brand5} alt=" brand" width="100%" />
              </li>
              <li>
                <img src={brand6} alt=" brand" width="100%" />
              </li>
            </ul>
          </div>
        </section>

        {/* store */}
        <section>
          <div className="topseller-cont">
            <div className="top-sell-text-cont">
              <p className="top-text">
                {" "}
                <strong>Stores</strong>
              </p>
              <p>Weekly line up for our best sellers</p>
            </div>
            <h5
              className="market-text mr-2"
              onClick={() => history.push("/stores")}
            >
              View all >
            </h5>
          </div>

          <div className="store-items">
            <div className="topseller-items">
              {(stores || []).slice(0, 3).map((item, i) => {
                return (
                  <>
                    {isMobile ? (
                      <CardStoreMobile
                        like={item.favourite_stores.length < 1 ? false : true}
                        name={
                          item.store_name !== undefined ? item.store_name : ""
                        }
                        url={item.store_url}
                        logo={item.logo}
                        address={
                          item.store_location_address !== undefined
                            ? item.store_location_address
                            : ""
                        }
                        likes={item.likes}
                        key={i}
                        follow={() => followStore(item.id)}
                        unfollow={() => unfollowStore(item.id)}
                      />
                    ) : (
                      <CardStore
                        like={item.favourite_stores.length < 1 ? false : true}
                        name={
                          item.store_name !== undefined ? item.store_name : ""
                        }
                        url={item.store_url}
                        logo={item.logo}
                        address={
                          item.store_location_address !== undefined
                            ? item.store_location_address
                            : ""
                        }
                        likes={item.likes}
                        key={i}
                        follow={() => followStore(item.id)}
                        unfollow={() => unfollowStore(item.id)}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

Market.propTypes = {
  products: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  fetchAllProducts: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  products: state.products,
  category: state.category,
  user: state.emailLogin,
  trends: state.trends,
  stores: state.stores,
  favorites: state.favorites,
});

const pageActions = {
  fetchAllProducts,
  fetchCategory,
  fetchTrendingProducts,
  fetchStores,
  fetchFavoritePost,
  fetchFavoriteRemove,
};

export default connect(mapStateToProps, pageActions)(Market);
