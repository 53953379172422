import React from 'react'
import { Button } from "reactstrap";
import './styles.scss'

const ViewMore = props => {
    const { viewHandler } = props
    return (
      <div className="view-more-container">
      <Button className="view-more" size="sm" outline color="dark" onClick={viewHandler}>
        view more
      </Button>
    </div>
    )
}

export default ViewMore;