import {
  FETCH_AUCTION,
  FETCH_AUCTION_SUCCESS,
  FETCH_AUCTION_FAILED
} from '../../types';

const initialState = {
  auction: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchAuctionReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_AUCTION:
      return {
        ...state,
        isLoading: true,
          auction: action.payload
      }
      case FETCH_AUCTION_SUCCESS:
        return {
          ...state,
          isLoading: false,
            auction: action.payload
        }
        case FETCH_AUCTION_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchAuctionReducer;
