/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from "react";
import ModalExample from "../Modal/Modal";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchUserEditProfile } from "../../redux/actions/authentication";
import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

const EditProfile = (props) => {
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setForm(Auth.getUser());
    console.log(props.profile.profile);
  }, []);

  useEffect(() => {
    if (check) {
      NotificationManager.success(props.profile.profile.message);
      setCheck(false);
    }
  }, [props.profile.profile]);

  useEffect(() => {
    if (check) {
      NotificationManager.error(props.profile.errors.errors.message);
      setCheck(false);
    }
  }, [props.profile.errors.errors]);

  const activate = (e) => {
    e.preventDefault();
    let data = { full_name: form.full_name, birth_date: form.birth_date };
    props.fetchUserEditProfile(data);
    setCheck(true);
  };

  return (
    <div>
      <NotificationContainer />
      <ModalExample
        modalTitle="Edit Profile"
        classBtn=""
        buttonLabel={
          <span>
            <i class="fa fa-pencil" aria-hidden="true"></i> edit
          </span>
        }
        handleAction={activate}
        handleTitle="Save"
      >
        <div>
          {/* <div className="">{props.user.message}</div> */}
          <form action="post" onSubmit={activate}>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Full name</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                name="fullname"
                value={form.full_name}
                onChange={(e) =>
                  setForm({ ...form, full_name: e.target.value })
                }
                required
                autoFocus
              />
            </div>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Birthday</label>
              <input
                type="date"
                class="form-control"
                aria-describedby="emailHelp"
                name="birthday"
                value={form.birth_date}
                onChange={(e) =>
                  setForm({ ...form, birth_date: e.target.value })
                }
                required
                autoFocus
              />
            </div>
          </form>
        </div>
      </ModalExample>
    </div>
  );
};

EditProfile.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  fetchUserEditProfile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.emailLogin,
  profile: state.profile,
});

const pageActions = { fetchUserEditProfile };

export default connect(mapStateToProps, pageActions)(EditProfile);
