import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILED
} from '../../types';

const initialState = {
  category: [],
  isLoading: false,
  errors: {},
  error: false
}

const fetchCategoryReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        isLoading: true,
          category: action.payload
      }
      case FETCH_CATEGORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
            category: action.payload
        }
        case FETCH_CATEGORY_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchCategoryReducer;
