import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardItem from "../../component/CardItem/CardItem";
import { Link, useHistory } from "react-router-dom";
import SellAds from "../../component/SellAds/SellAds";
import ViewMore from "../../component/ViewMore/ViewMore";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCategory } from "../../redux/actions/category";
import { fetchSearch } from "../../redux/actions/products";

import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

import CardSmall from "../../component/CardSmall/CardSmall";
import CardFeature from "../../component/CardFeature/CardFeature";
import CardTopSell from "../../component/CardTopSell/CardTopSell";
import { isMobile } from "../../utils/isLayout";
import CardTopSellMobile from "../../component/CardTopSellMobile/CardTopSellMobile";

const Search = (props) => {
  const {
    match: { params },
    location,
  } = props;
  const searchparam = new URLSearchParams(location.search);
  const history = useHistory();
  const [products, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [pageCount, setPage] = useState(10);
  const [check, setCheck] = useState(false);
  const [catKey, setCatKey] = useState(0);
  const [search, setSearch] = useState({
    text: searchparam.get("q"),
    pageCount: 10,
    max: 0,
  });

  function viewMore() {
    props.fetchSearch(search);
    setSearch({ ...search, pageCount: pageCount + 10 });
  }

  function handleChange(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchSearch(search);
  }

  function changeOrder(e) {
    e.target.value === "oldest" &&
      setSearch({
        ...search,
        order: "createdAt",
        orderVal: "ASC",
        pageCount: 0,
      });
    e.target.value === "latest" &&
      setSearch({
        ...search,
        order: "createdAt",
        orderVal: "DESC",
        pageCount: 0,
      });
    e.target.value === "lowest_p" &&
      setSearch({
        ...search,
        order: "net_price",
        orderVal: "ASC",
        pageCount: 0,
      });
    e.target.value === "highest_p" &&
      setSearch({
        ...search,
        order: "net_price",
        orderVal: "DESC",
        pageCount: 0,
      });
    e.target.value === "lowest_r" &&
      setSearch({
        ...search,
        order: "rating",
        orderVal: "ASC",
        pageCount: 0,
      });
    e.target.value === "highest_r" &&
      setSearch({
        ...search,
        order: "rating",
        orderVal: "DESC",
        pageCount: 0,
      });
  }

  function handleCategory(e) {
    setSearch({ ...search, catId: category[e.target.value].id });
    setCatKey(e.target.value);
  }

  function handleSubCat(e) {
    setSearch({ ...search, subCat: e.target.value });
  }

  useEffect(() => {
    props.fetchSearch(search);
    props.fetchCategory();
  }, []);

  useEffect(() => {
    props.fetchSearch(search);
  }, [searchparam.get("q")]);

  useEffect(() => {
    if (props.search.search.data) {
      setProduct(props.search.search.data.product);
      //setSearch({ ...search, text: "" });
      setCheck(false);
    }
  }, [props.search.search.data]);

  useEffect(() => {
    if (props.search.errors.errors) {
      //setProduct(props.search.search.data.product);
      //setSearch({...search, text:""});
      setCheck(false);
    }
  }, [props.search.errors.errors]);

  useEffect(() => {
    if (props.category.category.data) {
      setCategory(props.category.category.data.store.categories);
    }
  }, [props.category.category.data]);

  return (
    <Layout>
      <section className="category-section">
        {console.log()}
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>{" "}
          Search
          <p className="title-page">Search</p>
        </div>

        <section className="row">
          <div className="col-md-3 mr-3">
            <p>Filter by Categories</p>
            <div className="input-group">
              <select
                className="custom-select"
                id="inputGroupSelect01"
                value={search.catId}
                onChange={(e) =>
                  setSearch({ ...search, catId: e.target.value })
                }
              >
                <option value="" selected>
                  select categories
                </option>
                {category.map((index, i) => {
                  return (
                    <option key={i} value={index.id}>
                      {index.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-3 mr-3">
            <p>Filter by Price</p>

            <input
              type="range"
              className="input-group"
              name="price"
              id="price"
              min="0"
              max="100000"
              value={search.max}
              onChange={(e) => setSearch({ ...search, max: e.target.value })}
            />
            <div className="d-flex justify-content-between">
              <span>0</span>
              <span>{search.max < 1 ? 100000 : search.max}</span>
            </div>

            {/* <div className="form-group ">
              <label htmlFor="">Minimum price</label>
              <input
                type="number"
                name="min"
                className="form-control"
                placeholder="0"
                value={search.min}
                onChange={(e) => setSearch({ ...search, min: e.target.value })}
              />
            </div>
            <div className="form-group ">
              <label htmlFor="">Maximum price</label>
              <input
                type="number"
                name="max"
                className="form-control"
                placeholder="1000000"
                value={search.max}
                onChange={(e) => setSearch({ ...search, max: e.target.value })}
              />
            </div> */}
          </div>
        </section>

        <button
          className="btn btn-outline-info btn-sm mt-2"
          onClick={handleChange}
        >
          {!check ? (
            "Filter"
          ) : (
            <div>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Filtering...
            </div>
          )}
        </button>

        <section className="sort-cont">
          <p>Showing of 1 - 10 of 30 results</p>
          <div className="col-md-3 ">
            <div className="input-group ">
              <select
                className="custom-select"
                id="inputGroupSelect01"
                //value={e.target.value}
                onChange={changeOrder}
              >
                <option value="" selected>
                  sorting
                </option>
                <option value="latest">Latest</option>
                <option value="oldest">Oldest </option>
                <option value="lowest_p">Lowest price </option>
                <option value="highest_p">Highest price </option>
                <option value="lowest_r">Lowest rating </option>
                <option value="highest_r">Highest rating </option>
              </select>
            </div>
          </div>
        </section>

        <div className="search-items">
          {/* <div className="col-md-12 row justify-content-center">
            <input
              type="text"
              placeholder="search for anything"
              //value={search.text}
              onChange={(e) => setSearch({ ...search, text: e.target.value })}
              className="form-control col-md-6 ml-2 mr-2 mb-2"
            />
            <button
              className="btn btn-outline-secondary ml-2"
              onClick={() => history.push(`/search?q=${search.text}`)}
            >
              Search
            </button>
          </div> */}
          {/* <div className="col-md-2">
            <h6 className="mb-2">Filter search</h6>
            <div>
              <label htmlFor="order">Category</label>
              <div className="input-group mb-3 ">
                <select
                  className="custom-select"
                  id="inputGroupSelect01"
                  value={search.catId}
                  onChange={(e) =>
                    setSearch({ ...search, catId: e.target.value })
                  }
                >
                  <option value="" selected>
                    select categories
                  </option>
                  {category.map((index, i) => {
                    return (
                      <option key={i} value={index.id}>
                        {index.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="">Minimum price</label>
              <input
                type="number"
                name="min"
                className="form-control"
                placeholder="0"
                value={search.min}
                onChange={(e) => setSearch({ ...search, min: e.target.value })}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="">Maximum price</label>
              <input
                type="number"
                name="max"
                className="form-control"
                placeholder="1000000"
                value={search.max}
                onChange={(e) => setSearch({ ...search, max: e.target.value })}
              />
            </div>
            <div>
              <label htmlFor="order">Order by</label>
              <div className="input-group mb-3 ">
                <select
                  className="custom-select"
                  id="inputGroupSelect01"
                  //value={e.target.value}
                  onChange={changeOrder}
                >
                  <option value="latest">Latest</option>
                  <option value="oldest">Oldest </option>
                  <option value="lowest_p">Lowest price </option>
                  <option value="highest_p">Highest price </option>
                  <option value="lowest_r">Lowest rating </option>
                  <option value="highest_r">Highest rating </option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-outline-info btn-block"
              onClick={handleChange}
            >
              {!check ? (
                "Filter"
              ) : (
                <div>
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Filtering...
                </div>
              )}
            </button>
          </div> */}
          <div className="col-md-12">
            {
              <Fragment>
                <div className="row d-flex justify-content-center">
                  {products.map((item, i) => {
                    return (
                      <div className="" key={i}>
                        {isMobile ? (
                          <CardTopSellMobile
                            photo={item.images[0]}
                            item={truncate(item.product_name, 20)}
                            price={numberToComma(item.net_price)}
                            desc={truncate(item.description, 25)}
                            // like={item.favourites.length > 0 ? true : false}
                            soldout={item.stock_limit < 1 ? true : false}
                            oldPrice={item.price}
                            discount={item.discount}
                            action={() =>
                              history.push(`/product/${item.product_slug}`)
                            }
                          />
                        ) : (
                          <CardTopSell
                            photo={item.images[0]}
                            item={truncate(item.product_name, 20)}
                            price={numberToComma(item.net_price)}
                            desc={truncate(item.description, 25)}
                            // like={item.favourites.length > 0 ? true : false}
                            soldout={item.stock_limit < 1 ? true : false}
                            oldPrice={item.price}
                            discount={item.discount}
                            action={() =>
                              history.push(`/product/${item.product_slug}`)
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
                {products.length < 1 && (
                  <div className="text-center pt-5">
                    <h6>There are no results for {search.text}</h6>
                    <ul className="list-search">
                      <li> Check your spelling for typing errors</li>
                      <li>Try searching with short and simple keywords</li>
                      <li>
                        Try searching more general terms - you can then filter
                        the search results
                      </li>
                    </ul>
                  </div>
                )}
              </Fragment>
            }
          </div>
        </div>

        <ViewMore viewHandler={viewMore} />
      </section>
    </Layout>
  );
};

Search.propTypes = {
  search: PropTypes.object.isRequired,
  fetchSearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  search: state.search,
  category: state.category,
});

const pageActions = { fetchSearch, fetchCategory };
export default connect(mapStateToProps, pageActions)(Search);
