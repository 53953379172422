import React from 'react';

const Star = props => {
  return (
    <i
      className="fa fa-star"
      aria-hidden="true"
      style={{ color: "#ff804c" }}
    ></i>
  )
}

export default Star;