import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import CardOrder from "../../component/CardOrder/CardOrder";
import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";
import { isMobile, isDesktop } from "../../utils/isLayout";
import swal from "@sweetalert/with-react";

import { fetchMyOrder } from "../../redux/actions/products";
import { connect } from "react-redux";
import ViewMore from "../../component/ViewMore/ViewMore";
import { Spinner } from "reactstrap";

const Order = (props) => {
  const history = useHistory();
  const [order, setOrder] = useState([]);
  const [pageCount, setPage] = useState(10);
  const [check, setCheck] = useState(false);
  const [filter, setFilter] = useState("all");

  function viewMore() {
    props.fetchMyOrder(pageCount, filter);
    setPage(pageCount + 10);
  }

  function handleChange(e) {
    setFilter(e.target.value);
    props.fetchMyOrder(pageCount, e.target.value);
  }

  useEffect(() => {
    props.fetchMyOrder(pageCount, filter);
  }, []);

  useEffect(() => {
    if (props.orders.orders.data) {
      setOrder(props.orders.orders.data.orders);
    }
  }, [props.orders.orders]);

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/order">Orders</Link>
          <p className="title-page">{filter.toUpperCase()} Orders</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                <div className="input-group mb-3 col-md-4">
                  <select
                    className="custom-select"
                    id="inputGroupSelect01"
                    //value={form.filter}
                    onChange={handleChange}
                  >
                    <option selected>filter orders</option>
                    <option>all</option>
                    <option>pending</option>
                    <option>received</option>
                  </select>
                </div>
                <table className="table table-borderless table-striped table-hover">
                  <tbody>
                    {order.map((item, i) => (
                      <tr>
                        <td>
                          <CardOrder
                            order={item}
                            key={i}
                            navigate={() =>
                              history.push(`order-info/${item.id}`)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {order === undefined && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="p-3 m-3"
                  />
                )}
                {order.length < 1 && !check && (
                  <h4 className="text-center p-5">No {filter === 'all' ? '' : filter} Order at the moment</h4>
                )}
              </div>
            </div>
            <ViewMore viewHandler={viewMore} />
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

const pageActions = {
  fetchMyOrder,
};

export default connect(mapStateToProps, pageActions)(Order);
