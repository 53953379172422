import React from 'react';
import Layout from '../component/Layout/Layout';

const ErrorPage = props => {
   return(
    <Layout>
    <section className="product-category-section">
      <div className="p-2 text-info">
        <div className="order-placed-container">
          <div className="text-center">
          <h1>404</h1>
            <h6>Page not found !</h6>
          </div>
        
        </div>
      </div>
     </section>
  </Layout>
   )
}

export default ErrorPage;