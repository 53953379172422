import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavLink,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroup,
  InputGroupAddon,
  Input,
  Badge,
} from "reactstrap";

import Logo from "../../images/logo.png";
import "./styles.scss";
import Login from "../../pages/Login/Login";
import Signup from "../../pages/Signup/Signup";
import photo from "../../images/phone.png";
import dollar from "../../images/dollar-sign.png";
import shop from "../../images/shop.png";

import Auth from "../../config/auth";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCategory } from "../../redux/actions/category";
import { fetchViewCart } from "../../redux/actions/products";
import { fetchNotifications } from "../../redux/actions/notifications";
import swal from "sweetalert";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

const Header = (props) => {
  let history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState({ name: "" });
  const toggle = () => setIsOpen(!isOpen);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const toggleDrop2 = () => setDropdownOpen2((prevState) => !prevState);

  const [category, setCategories] = useState({ category: [] });
  const [badge, setBadge] = useState("");

  function handleLogout() {
    Auth.destroyToken();
    history.go(location.pathname);
    // history.go('/')
    NotificationManager.success("Logout successful");
  }

  useEffect(() => {
    props.fetchCategory();
    props.fetchViewCart();
    setUser(Auth.getUser());
  }, []);

  useEffect(() => {
    if (props.category.category.data) {
      setCategories({
        ...category,
        category: props.category.category.data.store.categories,
      });
    }
  }, [props.category]);

  useEffect(() => {
    if (props.carts.carts.data) {
      setBadge(
        Auth.isAuthenticated()
          ? props.carts.carts.data.cart.items.length > 0
            ? props.carts.carts.data.cart.items.length
            : 0
          : null
      );
    }
  }, [props.carts.carts]);

  return (
    <header className="header">
      <NotificationContainer />
      <div className="nav-one-header">
        <ul className="nav-one">
          <li>
            <i className="fa fa-instagram" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-youtube" aria-hidden="true"></i>
          </li>
          <li className="nav-icons">
            <i className="fa fa-envelope" aria-hidden="true">
              info@shobbu.com
            </i>
          </li>

          <li>
            <span>30days</span> free return
          </li>
          <li>
            <span>247</span> customer service
          </li>
          <li>
            <span>Best</span> Nationwide delivery
          </li>

          <li>
            <Link to="/auction">
              <img src={dollar} width="20px" alt="dollar" /> Auction
            </Link>
          </li>
          <li>
            <Link to="/sell">
              {" "}
              <img src={shop} width="20px" alt="shop" /> sell
            </Link>
          </li>
        </ul>
      </div>

      <div className="nav-two-header">
        <Navbar light expand="md navbar" className="navlink">
          <i
            class="fa fa-bars fa-lg icon-menu"
            aria-hidden="true"
            onClick={toggle}
          ></i>
          <Link to="/">
            <img src={Logo} alt="" width="80%" />
          </Link>
          <i
            className="fa fa-ellipsis-v fa-lg icon-menu"
            aria-hidden="true"
            onClick={toggle}
          ></i>

          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <div className="nav-one-mobile">
                <li>
                  <span>30days</span> free return
                </li>
                <li>
                  <span>247</span> customer service
                </li>
                <li>
                  <span>Best</span> Nationwide delivery
                </li>

                <li>
                  <Link to="/auction">
                    <img src={dollar} width="20px" alt="dollar" /> Auction
                  </Link>
                </li>
                <li>
                  <Link to="/sell">
                    {" "}
                    <img src={shop} width="20px" alt="shop" /> sell
                  </Link>
                </li>
                <li className="info">
                  <span>
                    <i className="fa fa-headphones" aria-hidden="true"></i>
                  </span>
                  <span className="info-contact">
                    <a href="tel:+2348093433333">Call us: (+234) 8093433333</a>
                    <span>Open from 8am - 6pm (Mon - Sat)</span>
                  </span>
                </li>
              </div>
            </Nav>
          </Collapse>
        </Navbar>
      </div>

      <div className="nav-three-header">
        <Navbar
          light
          expand="md navbar "
          className="navlink justify-content-between"
          //fixed="top"
        >
          <Link to="/">
            <img src={Logo} alt="" width="80%" />
          </Link>

          <div className="col-md-4">
            <InputGroup>
              <Input
                className="search-admin"
                onChange={(e) => setSearch({ ...search, name: e.target.value })}
              />
              <InputGroupAddon addonType="append">
                <Button
                  className="search-btn"
                  onClick={() => history.push(`/search?q=${search.name}`)}
                >
                  <i className="fa fa-search fa-la" aria-hidden="true"></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>

          <li className="info">
            <span>
              <i className="fa fa-headphones" aria-hidden="true"></i>
            </span>
            <span className="info-contact">
              <a href="tel:+2348093433333">Call us: (+234) 8093433333</a>
              <span>Open from 8am - 6pm (Mon - Sat)</span>
            </span>
          </li>
        </Navbar>
      </div>

      <div className="nav-four-header">
        <ul className="nav justify-content-center light nav-header">
          {category.category.slice(0, 6).map((item, i) => {
            return (
              <li className="nav-item" key={i}>
                <Link
                  to={`/product-category/${item.id}`}
                  className="nav-link text-white"
                >
                  {item.name}
                  <i className="fa fa-caret-down pl-2" aria-hidden="true"></i>
                </Link>
              </li>
            );
          })}

          {Auth.isAuthenticated() && (
            <li className="nav-item">
              <Link to="/notifications" className="nav-link text-white">
                <div className="badge-cart">
                  <i className="fa fa-bell fa-lg" aria-hidden="true"></i>
                  <Badge color="danger" className="badge-count">
                    1
                  </Badge>
                </div>
              </Link>
            </li>
          )}

          {Auth.isAuthenticated() && (
            <li className="nav-item">
              <Link to="/product-saved" className="nav-link text-white">
                <i className="fa fa-heart fa-lg" aria-hidden="true"></i>
              </Link>
            </li>
          )}

          <li className="nav-item">
            <Link to="/product-cart" className="nav-link text-white">
              <div className="badge-cart">
                <i className="fa fa-shopping-cart fa-lg" aria-hidden="true"></i>
                <Badge color="danger" className="badge-count">
                  {badge}
                </Badge>
              </div>
            </Link>
          </li>

          {!Auth.isAuthenticated() && (
            <li className="nav-item text-white">
              <Login buttonLabel="Login" />
            </li>
          )}
          {!Auth.isAuthenticated() && (
            <li className="nav-item text-white">
              <Signup buttonLabel="Sign up" />
            </li>
          )}
          {Auth.isAuthenticated() && (
            <li>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDrop}>
                <DropdownToggle className="profile-toggle text-white">
                  <i className="fa fa-user fa-lg" aria-hidden="true"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="user-dropdown">
                    <Link to="/setting">
                      <p>
                        <i
                          style={{ color: "black" }}
                          className="fa fa-user-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Account
                      </p>
                    </Link>
                    <Link to="/order">
                      <p>
                        {" "}
                        <i
                          className="fa fa-list"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        My order
                      </p>
                    </Link>
                    <Link to="/bid">
                      <p>
                        <i
                          className="fa fa-clipboard"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        My bids
                      </p>
                    </Link>
                    <Link to="/product-saved">
                      <p>
                        <i
                          className="fa fa-heart"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        Saved items
                      </p>
                    </Link>
                    <div className="text-center logout" onClick={handleLogout}>
                      <hr />
                      <p className="text-danger">Logout</p>
                      <hr />
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </li>
          )}

          {Auth.isAuthenticated() && (
            <li className="nav-item" onClick={handleLogout}>
              <strong className="nav-link text-white"> Logout</strong>
            </li>
          )}
        </ul>
      </div>

      <div className="nav-five-header">
        <ul className="nav justify-content-center light nav-header">
          <li className="nav-item">
            <span className="nav-link text-white">
              <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link text-white">
              <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link text-white">
              <i class="fa fa-twitter fa-lg" aria-hidden="true"></i>
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link text-white">
              <i class="fa fa-youtube fa-lg" aria-hidden="true"></i>
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link text-white">
              <i class="fa fa-envelope fa-lg" aria-hidden="true"></i>{" "}
              info@shobbu.com
            </span>
          </li>
          {Auth.isAuthenticated() && (
            <li className="nav-item">
              <Link to="/notifications" className="nav-link text-white">
                <div className="badge-cart">
                  <i className="fa fa-bell fa-lg" aria-hidden="true"></i>
                  <Badge color="danger" className="badge-count">
                    1
                  </Badge>
                </div>
              </Link>
            </li>
          )}

          {Auth.isAuthenticated() && (
            <li className="nav-item">
              <Link to="/product-saved" className="nav-link text-white">
                <i className="fa fa-heart fa-lg" aria-hidden="true"></i>
              </Link>
            </li>
          )}

          <li className="nav-item">
            <Link to="/product-cart" className="nav-link text-white">
              <div className="badge-cart">
                <i className="fa fa-shopping-cart fa-lg" aria-hidden="true"></i>
                <Badge color="danger" className="badge-count">
                  {badge}
                </Badge>
              </div>
            </Link>
          </li>

          {!Auth.isAuthenticated() && (
            <li className="nav-item text-white">
              <Login buttonLabel="Login" />
            </li>
          )}
          {!Auth.isAuthenticated() && (
            <li className="nav-item text-white">
              <Signup buttonLabel="Sign up" />
            </li>
          )}
          {Auth.isAuthenticated() && (
            <li>
              <Dropdown isOpen={dropdownOpen2} toggle={toggleDrop2}>
                <DropdownToggle className="profile-toggle text-white">
                  <i class="fa fa-user fa-lg" aria-hidden="true"></i>
                </DropdownToggle>
                <DropdownMenu >
                  <div className="user-dropdown">
                    <Link to="/setting">
                      <p>
                        <i
                          style={{ color: "black" }}
                          className="fa fa-user-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Account
                      </p>
                    </Link>
                    <Link to="/order">
                      <p>
                        {" "}
                        <i
                          className="fa fa-list"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        My order
                      </p>
                    </Link>
                    <Link to="/bid">
                      <p>
                        <i
                          className="fa fa-clipboard"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        My bids
                      </p>
                    </Link>
                    <Link to="/product-saved">
                      <p>
                        <i
                          className="fa fa-heart"
                          style={{ color: "black" }}
                          aria-hidden="true"
                        ></i>{" "}
                        Saved items
                      </p>
                    </Link>
                    <div className="text-center logout" onClick={handleLogout}>
                      <hr />
                      <p className="text-danger">Logout</p>
                      <hr />
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </li>
          )}
        </ul>
      </div>

      <div className="nav-six-header m-2">
        <div className="col-md-4">
          <InputGroup>
            <Input
              className="search-admin"
              onChange={(e) => setSearch({ ...search, name: e.target.value })}
              placeholder="search anything"
            />
            <InputGroupAddon addonType="append">
              <Button
                className="search-btn"
                onClick={() => history.push(`/search?q=${search.name}`)}
              >
                <i className="fa fa-search fa-la" aria-hidden="true"></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  category: PropTypes.object.isRequired,
  carts: PropTypes.object.isRequired,
  fetchCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  carts: state.carts,
  login: state.emailLogin,
});

const pageActions = { fetchCategory, fetchViewCart, fetchNotifications };
export default connect(mapStateToProps, pageActions)(Header);
