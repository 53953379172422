import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";

import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";
import {fetchChangePassword} from "../../redux/actions/authentication"

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";
import { isMobile, isDesktop } from "../../utils/isLayout";
import swal from "@sweetalert/with-react";

const ChangePassword = (props) => {
  //const [user, setUser] = useState({});
  const [form, setForm] = useState({});
  const [check,setCheck] = useState(false)

  function changePass(e) {
    e.preventDefault();
    setCheck(true)
    props.fetchChangePassword(form)
  }

  useEffect(() => {
    if(props.user.user.data && check){
      swal("updated","","success")
      setCheck(false)
      setForm({password:"", old_password:""})
    }
  }, [props.user.user]);

  useEffect(() => {
    if(props.user.errors.errors && check){
      swal(props.user.errors.errors.message,"","error")
      setCheck(false)
      setForm({password:"", old_password:""})
    }
  }, [props.user.errors.errors]);

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/change-password">Change password</Link>
          <p className="title-page">Change password</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="order-item">
                      <div className="order-item-body">
                        <div className="">
                          <p>CHANGE PASSWORD</p>
                        </div>
                        <hr />
                        <div className="pl-3">
                          <form className="form" onSubmit={changePass}>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="enter new password"
                                required
                                value={form.password}
                                onChange={(e) =>
                                  setForm({ ...form, password: e.target.value })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="enter old password"
                                required
                                value={form.old_password}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    old_password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <button
                              className="btn btn-outline-secondary"
                              type="submit"
                             // onClick={changePass}
                            >
                              {check ? 'updating' : 'update'}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

ChangePassword.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.emailLogin,
});

const pageActions = {fetchChangePassword};

export default connect(mapStateToProps, pageActions)(ChangePassword);
