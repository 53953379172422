import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";

import { fetchPaymentHandle } from "../../redux/actions/payment";

const OrderVerify = (props) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  let order_id = sessionStorage.getItem("order_id");

  useEffect(() => {
    props.fetchPaymentHandle({
      reference: searchParams.get("reference"),
      order_id: order_id,
    });
  }, []);

  useEffect(() => {
    if (props.payment.payment.data) {
      history.push("/order-placed");
    }
  }, [props.payment.payment.data]);

  useEffect(() => {
    if (props.payment.errors.errors) {
      // NotificationManager.error(props.payment.errors.errors.message);

      history.push("product-cart");
    }
  }, [props.payment.errors.errors]);

  return (
    <Layout>
      <section className="order-placed-section">
        <NotificationContainer />
        <div className="p-2 text-info">
          <div className="order-placed-container">
            <div className="text-center">
              <h1>Verifing your order</h1>
              <Spinner
                animation="border"
                variant="primary"
                className="p-5 m-5"
              />
              <h6>Verifying...</h6>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  order: state.order,
  payment: state.payment,
});

const pageActions = {
  fetchPaymentHandle,
};

export default connect(mapStateToProps, pageActions)(OrderVerify);
