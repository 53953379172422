import {
  FETCH_STORES,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_FAILED
} from '../../types';

const initialState = {
  stores: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchStoresReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_STORES:
      return {
        ...state,
        isLoading: true,
          stores: action.payload
      }
      case FETCH_STORES_SUCCESS:
        return {
          ...state,
          isLoading: false,
            stores: action.payload
        }
        case FETCH_STORES_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchStoresReducer;
