import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import logo from "../../images/logo.svg";
import "./styles.scss";
import ConfirmAccount from "../ConfirmAccount/ConfirmAccount";
import { useHistory,useLocation } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchUserEmailRegistration } from "../../redux/actions/authentication";

import isEmpty from "../../utils/isEmpty";
import Auth from "../../config/auth";

import { CLIENT_ID, BASE_URL } from "../../redux/constants";

//import GoogleLogin from "react-google-login";
import Axios from "axios";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const Register = (props) => {
  const { buttonLabel, className, toggle } = props;
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleRegister = () => {
    setModal(!modal);
  };
  let history = useHistory();
  let location = useLocation();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    store_id: "1585139305",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({
    message: "",
    code: "",
    yesError: false,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const userData = {
      ...form,
      verifyPassword: form.cpassword,
    };
    try {
      const response = await Axios.post(
        `${BASE_URL}/users/register/email`,
        userData
      );
      if (response.data.hasError) {
        // setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
        setForm({ ...form, password: "" });
        NotificationManager.error(response.data.errors.message);
        setLoading(false);
      } else {
        const { user, token } = response.data.data;
        Auth.setToken(token, user);
        console.log(response.data.data);

        history.push(location.pathname);
        //setErrors({ ...errors, yesError: false });
        setForm({
          ...form,
          name: "",
          email: "",
          phone: "",
          password: "",
          cpassword: "",
        });
        setLoading(false);
        NotificationManager.success(response.data.message);
        toggle();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   setLoading(true);

  //   const userData = {
  //     name: form.name,
  //     email: form.email,
  //     phone: form.phone,
  //     password: form.password,
  //     verifyPassword: form.cpassword,
  //     store_id: "1585139305",
  //   };
  //   props.fetchUserEmailRegistration(userData);
  // }

  // useEffect(() => {
  //   setLoading(false);
  //   if (!isEmpty(props.user.user)) {
  //     const { user } = props.user;
  //     if (!user.hasError) {
  //       setSuccess(user.message);
  //       setForm({
  //         ...form,
  //         name: "",
  //         email: "",
  //         phone: "",
  //         password: "",
  //         cpassword: "",
  //       });
  //       setErrors({ ...errors, yesError: false });
  //       // history.push("/order");
  //       // setModal(false);
  //       // setConfirmModal(true);
  //     }
  //   } else {
  //     // setModal(false);
  //     // setConfirmModal(true);
  //     setForm({
  //       ...form,
  //       name: "",
  //       email: "",
  //       phone: "",
  //       password: "",
  //       cpassword: "",
  //     });
  //     if (props.user.errors.errors) {
  //       setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
  //     }
  //   }
  // }, [props.user.user]);

  return (
    <div>
      <button class="btn-email mb-3" onClick={toggleRegister}>
        {buttonLabel}
      </button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} className="text-center">
          <img src={logo} alt="logo" width="100" height="50" />
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div className="p-2">
              <h5>Lets get started</h5>
            </div>
            <form
              className="text-center p-3"
              method="post"
              onSubmit={handleSubmit}
            >
              {errors.yesError && (
                <div className="alert alert-danger">{errors.message}</div>
              )}
              {!isEmpty(props.user.user) && (
                <div className="alert alert-success">
                  {props.user.user.message}
                </div>
              )}
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Full name</label>
                <input
                  type="text"
                  class="form-control register-input"
                  aria-describedby="emailHelp"
                  name="fullname"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  required
                  autoFocus
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control register-input"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="email"
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  required
                  autoFocus
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Mobile number</label>
                <input
                  type="tel"
                  class="form-control register-input"
                  aria-describedby="emailHelp"
                  name="phone"
                  value={form.phone}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  required
                  autoFocus
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  class="form-control register-input"
                  id="exampleInputPassword1"
                  name="password"
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  required
                  autoFocus
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Confirm password</label>
                <input
                  type="password"
                  class="form-control register-input"
                  name="cpassword"
                  value={form.cpassword}
                  onChange={(e) =>
                    setForm({ ...form, cpassword: e.target.value })
                  }
                  required
                  autoFocus
                />
              </div>
              <button
                type="submit"
                className="btn login-btn text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Register"}
              </button>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ConfirmAccount
        email={form.email}
        phone={form.phone}
        class="btn login-btn text-white"
        buttonLabel="Register"
        modal={confirmModal}
        setModal={setConfirmModal}
      />
    </div>
  );
};

Register.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserEmailRegistration: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.emailRegister,
});

const pageActions = { fetchUserEmailRegistration };

export default connect(mapStateToProps, pageActions)(Register);
