/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from "react";
import ModalExample from "../Modal/Modal";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchApplyCoupon } from "../../redux/actions/products";
import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

const Coupon = (props) => {
  const { orderId } = props;
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (check) {
      NotificationManager.success(props.order.order.message);
      setCheck(false);
      setForm({ ...form, coupon: "" });
    }
  }, [props.order.order]);

  useEffect(() => {
    if (check) {
      NotificationManager.error(props.order.errors.errors.message);
      setCheck(false);
      setForm({ ...form, coupon: "" });
    }
  }, [props.order.errors.errors]);

  const applyCoupon = (e) => {
    e.preventDefault();
    let data = { coupon: form.coupon, order_id: orderId };
    props.fetchApplyCoupon(data);
    setCheck(true);
  };

  return (
    <div>
      <NotificationContainer />
      <ModalExample
        classBtn="text-primary font-weight-bold"
        modalTitle="Apply coupon"
        buttonLabel={<p>apply coupon</p>}
        handleAction={applyCoupon}
        handleTitle="Apply coupon"
      >
        <div>
          {/* <div className="">{props.user.message}</div> */}
          <form action="post" onSubmit={applyCoupon}>
            <div class="form-group">
              <label htmlFor="exampleInputEmail1">Enter coupon</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                name="fullname"
                value={form.coupon}
                onChange={(e) => setForm({ ...form, coupon: e.target.value })}
                required
                autoFocus
              />
            </div>
          </form>
        </div>
      </ModalExample>
    </div>
  );
};

Coupon.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  fetchUserEditProfile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  order: state.order,
});

const pageActions = { fetchApplyCoupon };

export default connect(mapStateToProps, pageActions)(Coupon);
