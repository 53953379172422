import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardItem from "../../component/CardItem/CardItem";
import { Link } from "react-router-dom";
import SellAds from "../../component/SellAds/SellAds";
import ViewMore from "../../component/ViewMore/ViewMore";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchStores } from "../../redux/actions/stores";

import truncate from "../../utils/truncate";
import CardStore from "../../component/CardStore/CardStore";

import Axios from "axios";
import { BASE_URL } from "../../redux/constants";
import Auth from "../../config/auth";
import swal from "@sweetalert/with-react";

const Stores = (props) => {

  const [stores, setStores] = useState([])
  const [pageCount, setPage] = useState(12);

  function viewMore() {
    props.fetchStores(pageCount);
    setPage(pageCount + 12);
  }

  async function followStore (id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {store_id: id}
    try {
      const response = await Axios.post(`${BASE_URL}/merchants/store-favourite`,data,{headers});
      if(response.data.hasError){
        console.log(response.data)
      } else {
        //swal(response.data.message,"","success")
        props.fetchStores()
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function unfollowStore (id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(`${BASE_URL}/merchants/store-favourite/${id}`,{headers});
      if(response.data.hasError){
        console.log(response.data)
      } else {
       // swal(response.data.message,"","success")
        props.fetchStores()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    props.fetchStores();
  }, []);

  useEffect(() => {
    if (props.stores.stores.data) {
      setStores(props.stores.stores.data.stores.docs);
    }
  }, [props.stores.stores]);


  return (
    <Layout>
      <section className="category-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`/stores`}>Stores</Link>
          <p className="title-page">Stores</p>
        </div>
        {
          <div className="row">
            {stores.map((item, i) => {
              return (
                <CardStore
                    like={item.favourite_stores.length < 1 ? false : true}
                    name={item.store_name}
                    url={item.store_url}
                    logo={item.logo}
                    address={item.store_location_address}
                    likes={item.likes}
                    key={i}
                    follow={() => followStore(item.id)}
                    unfollow={() => unfollowStore(item.id)}
                  />
              );
            })}
          </div>
        }
        <ViewMore viewHandler={viewMore} />
      </section>

      <section className="category-selling">
        <SellAds />
      </section>
    </Layout>
  );
};

Stores.propTypes = {
  stores: PropTypes.object.isRequired,
  fetchStores: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  stores: state.stores,
});

const pageActions = { fetchStores };
export default connect(mapStateToProps, pageActions)(Stores);
