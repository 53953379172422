import {
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILED
} from '../../types';

const initialState = {
  reviews: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchReviewsReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_REVIEWS:
      return {
        ...state,
        isLoading: true,
          review: action.payload
      }
      case FETCH_REVIEWS_SUCCESS:
        return {
          ...state,
          isLoading: false,
            reviews: action.payload
        }
        case FETCH_REVIEWS_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchReviewsReducer;
