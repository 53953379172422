import React, { useState } from 'react';
import Layout from '../component/Layout/Layout';

import Axios from "axios";
import { BASE_URL } from "../redux/constants";
import Auth from "../config/auth";
import swal from '@sweetalert/with-react';

const ForgotPassword = props => {
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState(false);

  const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

  async function resetEmail(e) {
    e.preventDefault();
    setCheck(true)
      const token = Auth.getToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const data = {store_id:"1585139305",email: email}
        const response = await Axios.post(`${BASE_URL}/users/reset-password`,data,{headers});
        if(response.data.hasError){
          swal(response.data.errors.message,"","error")
          setCheck(false)
        } else {
         swal(response.data.message,"","success")
         setCheck(false)
        }
      } catch (error) {
        console.log(error)
      }
  }
  return (
    <Layout>
      <section className="product-category-section">
        <div className="p-2">
          <h3 className="mt-3 ml-3">Forgot password</h3>
          <div className="order-placed-container">
            <form className="form col-md-4" onSubmit={resetEmail}>
              <div class="form-group">
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  name="name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoFocus
                  placeholder="enter email"
                />
              </div>
              <button
                className="btn btn-primary btn-block"
                type="submit"
                // onClick={changePass}
              >
                {check ? "reseting" : "reset password"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ForgotPassword;