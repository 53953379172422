export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
export const FETCH_ALL_PRODUCTS_SUCCESS = "FETCH_ALL_PRODUCTS_SUCCESS";
export const FETCH_ALL_PRODUCTS_FAILED = "FETCH_ALL_PRODUCTS_FAILED";

export const FETCH_SINGLE_PRODUCTS = "FETCH_SINGLE_PRODUCTS";
export const FETCH_SINGLE_PRODUCTS_SUCCESS = "FETCH_SINGLE_PRODUCTS_SUCCESS";
export const FETCH_SINGLE_PRODUCTS_FAILED = "FETCH_SINGLE_PRODUCTS_FAILED";

export const FETCH_REGISTRATION = "FETCH_REGISTRATION";
export const FETCH_REGISTRATION_SUCCESS = "FETCH_REGISTRATION_SUCCESS";
export const FETCH_REGISTRATION_FAILED = "FETCH_REGISTRATION_FAILED";

export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILED = "FETCH_LOGIN_FAILED";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export const FETCH_VERIFY = "FETCH_VERIFY";
export const FETCH_VERIFY_SUCCESS = "FETCH_VERIFY_SUCCESS";
export const FETCH_VERIFY_FAILED = "FETCH_VERIFY_FAILED";

export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";

export const FETCH_CART = "FETCH_CART";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILED = "FETCH_CART_FAILED";

export const FETCH_CART_ITEM = "FETCH_CART_ITEM";
export const FETCH_CART_ITEM_SUCCESS = "FETCH_CART_ITEM_SUCCESS";
export const FETCH_CART_ITEM_FAILED = "FETCH_CART_ITEM_FAILED";

export const FETCH_CATEGORY_PRODUCT = "FETCH_CATEGORY_PRODUCT";
export const FETCH_CATEGORY_PRODUCT_SUCCESS = "FETCH_CATEGORY_PRODUCT_SUCCESS";
export const FETCH_CATEGORY_PRODUCT_FAILED = "FETCH_CATEGORY_PRODUCT_FAILED";

export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILED = "FETCH_ADDRESS_FAILED";

export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILED = "FETCH_ORDERS_FAILED";

export const FETCH_FAVORITE = "FETCH_FAVORITE";
export const FETCH_FAVORITE_SUCCESS = "FETCH_FAVORITE_SUCCESS";
export const FETCH_FAVORITE_FAILED = "FETCH_FAVORITE_FAILED";

export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILED = "FETCH_PAYMENT_FAILED";

export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAILED = "FETCH_REVIEWS_FAILED";

export const FETCH_REVIEW = "FETCH_REVIEW";
export const FETCH_REVIEW_SUCCESS = "FETCH_REVIEW_SUCCESS";
export const FETCH_REVIEW_FAILED = "FETCH_REVIEW_FAILED";

export const FETCH_RECENTLY_VIEW = "FETCH_RECENTLY_VIEW";
export const FETCH_RECENTLY_VIEW_SUCCESS = "FETCH_RECENTLY_VIEW_SUCCESS";
export const FETCH_RECENTLY_VIEW_FAILED = "FETCH_RECENTLY_VIEW_FAILED";

export const FETCH_AUCTIONS = "FETCH_AUCTIONS";
export const FETCH_AUCTIONS_SUCCESS = "FETCH_AUCTIONS_SUCCESS";
export const FETCH_AUCTIONS_FAILED = "FETCH_AUCTIONS_FAILED";

export const FETCH_AUCTION = "FETCH_AUCTION";
export const FETCH_AUCTION_SUCCESS = "FETCH_AUCTION_SUCCESS";
export const FETCH_AUCTION_FAILED = "FETCH_AUCTION_FAILED";

export const FETCH_AUCTION_BID = "FETCH_AUCTION_BID";
export const FETCH_AUCTION_BID_SUCCESS = "FETCH_AUCTION_BID_SUCCESS";
export const FETCH_AUCTION_BID_FAILED = "FETCH_AUCTION_BID_FAILED";

export const FETCH_BIDS = "FETCH_BIDS";
export const FETCH_BIDS_SUCCESS = "FETCH_BIDS_SUCCESS";
export const FETCH_BIDS_FAILED = "FETCH_BIDS_FAILED";

export const FETCH_TRENDING = "FETCH_TRENDING";
export const FETCH_TRENDING_SUCCESS = "FETCH_TRENDING_SUCCESS";
export const FETCH_TRENDING_FAILED = "FETCH_TRENDING_FAILED";

export const FETCH_PASSWORD = "FETCH_PASSWORD";
export const FETCH_PASSWORD_SUCCESS = "FETCH_PASSWORD_SUCCESS";
export const FETCH_PASSWORD_FAILED = "FETCH_PASSWORD_FAILED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";

export const FETCH_STORES = "FETCH_STORES";
export const FETCH_STORES_SUCCESS = "FETCH_STORES_SUCCESS";
export const FETCH_STORES_FAILED = "FETCH_STORES_FAILED";

export const FETCH_STORE = "FETCH_STORES";
export const FETCH_STORE_SUCCESS = "FETCH_STORE_SUCCESS";
export const FETCH_STORE_FAILED = "FETCH_STORE_FAILED";

export const FETCH_SINGLE_ORDER = "FETCH_SINGLE_ORDER";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAILED = "FETCH_SINGLE_ORDER_FAILED";

export const FETCH_DISPUTES = "FETCH_DISPUTES";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILED = "FETCH_DISPUTES_FAILED";

export const FETCH_SEARCH = "FETCH_SEARCH";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAILED = "FETCH_SEARCH_FAILED";