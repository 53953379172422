import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";

import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";
import EditProfile from "../../component/EditProfile/EditProfile";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";
import { isMobile, isDesktop } from "../../utils/isLayout";

import { fetchUserEditProfile, fetchUserProfile } from "../../redux/actions/authentication";
import { Input } from "reactstrap";
import swal from "@sweetalert/with-react";

import Axios from "axios";
import { BASE_URL } from "../../redux/constants";

const Setting = (props) => {
  const [user, setUser] = useState({});
  const [file, setFile] = useState();
  const [check, setCheck] = useState(false)

  function uploadFile(e) {
    e.persist()
    console.log(e.target.files[0])
    setFile(e.target.files[0]);
  }
  // function uploadFiles(e) {
  //   e.preventDefault();
  //   setCheck(true)
  //   const fd = new FormData();
  //   fd.append( "profile_pic", file);
  //   props.fetchUserEditProfile(fd);
  //   console.log(file);
  // }


  async function uploadPic(e) {
    e.preventDefault();
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setCheck(true);
    const fd = new FormData();
    fd.append( "profile_pic", file);
    try {
      const response = await Axios.put(`${BASE_URL}/users/profile/edit`, fd, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message, "", "error");
        setCheck(false);
      } else {
        const {
          profile
        } = response.data.data;
        Auth.setToken(token, profile);
        swal(response.data.message, "", "success");
        console.log(response.data)
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setUser(Auth.getUser());
  }, [props.user]);

  useEffect(() => {
    if(check){
      swal('profile picture changed',"","success")
      setCheck(false)
    }
  }, [props.user.user?.data]);

  useEffect(() => {
    if(check){
      swal(props.user.errors.errors.message,"","error")
      setCheck(false)
    }
  }, [props.user.errors.errors]);

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/setting">Setting</Link>
          <p className="title-page">Setting</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="order-item">
                      <div className="order-item-body">
                        <div className="d-flex justify-content-between">
                          <p>ACCOUNT DETAILS</p>
                          <EditProfile />
                        </div>
                        <hr />
                        <div className="pl-3">
                          <p>
                            Name: <strong>{user.full_name}</strong>
                          </p>
                          <p>
                            Email: <strong>{user.email}</strong>
                          </p>
                          <p>
                            Phone: <strong>{user.phone}</strong>
                          </p>
                          <p>
                            Birthday: <strong>{user.birth_date}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="order-item">
                      <div className="order-item-body">
                        <div className="d-flex justify-content-between">
                          <p>ADDRESS BOOK</p>
                        </div>
                        <hr />
                        <div className="pl-3">
                          <p>Your default shipping address :</p>
                          <p>
                            {" "}
                            <strong>
                              {!isEmpty(user.addresses) &&
                                user.addresses[0].line1}
                            </strong>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="order-item">
                    <div className="order-item-body">
                      <p>CHANGE PROFILE PICTURE</p>
                        <div className="form-group">
                        <Input type="file" name="file" id="exampleFile" onChange={uploadFile} />
                        </div>
                        <button className="btn btn-sm btn-outline-secondary" onClick={uploadPic}>{check ? 'updating':'update'}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

Setting.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.profile,
});

const pageActions = {fetchUserEditProfile};

export default connect(mapStateToProps, pageActions)(Setting);
