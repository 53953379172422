import React, { useState, useEffect, Fragment } from "react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import swal from "@sweetalert/with-react";
import numberToComma from "../../utils/numberToComma";
import cart from "../../images/cart.png";
import truncate from "../../utils/truncate";

import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardTiny from "../../component/CardTiny/CardTiny";

import { Card, Col, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import ImageGallery from "react-image-gallery";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchSingleProduct,
  fetchAddCart,
  fetchFavoritePost,
  fetchCancelOrder,
  fetchReviews,
  fetchFavoriteRemove,
} from "../../redux/actions/products";
import Auth from "../../config/auth";
import Axios from "axios";
import { BASE_URL } from "../../redux/constants";
import { fetchStores } from "../../redux/actions/stores";
import CardItem from "../../component/CardItem/CardItem";
import CardFeature from "../../component/CardFeature/CardFeature";
import { Tabs, Tab } from "react-bootstrap";
import Star from "../Star";
import Nostar from "../Nostar";
import { isMobile } from "../../utils/isLayout";
import CardFeatureMobile from "../../component/CardFeatureMobile/CardFeatureMobile";

const Product = (props) => {
  const {
    match: { params },
  } = props;
  const [count, setCount] = useState(1);
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(2);
  const style = "product-infos";
  const [product, setProduct] = useState({ product: {} });
  const [store, setStore] = useState({ store: {} });
  const [related, setRelated] = useState({});
  const [variation, setVariation] = useState({ variation: [] });
  const [img, setImg] = useState({ img: [] });
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [activeVaria, setActiveVaria] = useState({});
  const [reviews, setReview] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const up = (i) => {
    setCount(count + 1);
  };
  const down = (i) => {
    if (count > 1) setCount(count - 1);
  };

  const addToCart = () => {
    const data = {
      quantity: count,
      product_id: product.product.id,
      variation_id: activeVaria?.id !== undefined ? activeVaria.id : null,
    };
    props.fetchAddCart(data);
    setCheck(true);
  };

  const saveItem = (id) => {
    props.fetchFavoritePost(id);
    setCheck(true);
  };

  function removeFav(id) {
    props.fetchFavoriteRemove(id);
    setCheck(true);
  }

  const selectVariation = (item) => {
    setActiveVaria(item);
    setSelected(item.id);
  };

  async function followStore() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { store_id: store.store.id };
    try {
      const response = await Axios.post(
        `${BASE_URL}/merchants/store-favourite`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        //swal(response.data.message,"","success")
        props.fetchStores();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function unfollowStore() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(
        `${BASE_URL}/merchants/store-favourite/${store.store.id}`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        //swal(response.data.message,"","success")
        props.fetchStores();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const thestar = (x) => {
    let nost = 5 - x;
    let rating = [];
    while (x > 0) {
      rating.push(<Star />);
      x--;
    }
    while (nost > 0) {
      rating.push(<Nostar />);
      nost--;
    }

    return (
      <Fragment>
        <span>{rating}</span>
      </Fragment>
    );
  };

  useEffect(() => {
    setCount(count);
    props.fetchSingleProduct(params.item);
    props.fetchReviews(params.item);
  }, []);

  useEffect(() => {
    setCount(count);
    props.fetchSingleProduct(params.item);
    props.fetchReviews(params.item);
  }, [params.item]);

  useEffect(() => {
    if (check) {
      if (props.carts.carts.data) {
        // swal(popUpCart);
        swal(popUpCart, {
          buttons: {
            checkout: {
              text: "proceed to checkout",
              className: "proceed-btn",
            },
            continue: {
              text: "continue shopping",
              className: "continue-btn",
            },
            cancel: false,
          },
        }).then((value) => {
          switch (value) {
            case "checkout":
              history.push("/product-cart");
              break;
            case "continue":
              setCheck(true);
              // swal("cancel orders", "success");
              break;
            default:
          }
        });
      }
      //swal(popUpCart);
      setMessage(props.cart.cart.message);
      NotificationManager.success(props.cart.cart.message);
      setCheck(false);
    }
  }, [props.cart.cart]);

  useEffect(() => {
    if (check && props.cart.errors.errors.code !== 412) {
      setMessage(props.cart.errors.errors);
      //NotificationManager.error(props.cart.errors.errors.message);
      swal({
        //title: "Are you sure?",
        title: props.cart.errors.errors.message,
        icon: "warning",
        buttons: {
          orders: "go to orders",
          cancelOrder: "cancel orders",
          cancel: true,
        },
      }).then((value) => {
        switch (value) {
          case "orders":
            history.push("/product-checkout");
            break;
          case "cancelOrder":
            props.fetchCancelOrder();
            setCheck(true);
            // swal("cancel orders", "success");
            break;
          default:
        }
      });
      setCheck(false);
    }
    if (check && props.cart.errors.errors.code === 412) {
      swal("Session timed out", "Please login to continue", "error");
      setCheck(false);
    }

    if (check && props.cart.errors.errors.code === 400) {
      swal(props.cart.errors.errors.message, "", "error");
      setCheck(false);
    }
  }, [props.cart.errors.errors]);

  useEffect(() => {
    if (check) {
      swal("You have no pending orders", "", "success");
    }
    setCheck(false);
  }, [props.order.order.data]);

  useEffect(() => {
    if (props.product.product.data) {
      setProduct({ ...product, product: props.product.product.data.product });
      setStore({ ...store, store: props.product.product.data.store });
      setRelated(props.product.product.data.related);
      setVariation({
        ...variation,
        variation: props.product.product.data.product.product_variations,
      });
      setActiveVaria(props.product.product.data.product?.product_variations[0]);
      if (props.product.product.data.product.images) {
        setImg({ ...img, img: props.product.product.data.product.images });
      }
    }
  }, [props.product]);

  useEffect(() => {
    if (check) {
      // setMessage(props.favorites.favorites.message);
      // NotificationManager.success(props.favorites.favorites.message);

      props.fetchSingleProduct(params.item);
      NotificationManager.success(props.favorites.favorites.message);
      setCheck(false);
    }
  }, [props.favorites.favorites]);

  useEffect(() => {
    if (props.reviews.reviews.data) {
      setReview(props.reviews.reviews.data.reviews);
    }
  }, [props.reviews.reviews]);

  const popUpCart = (
    <div>
      <h4 className="text-left">1 Item added</h4>
      <hr />
      <div className="d-flex justify-content-around">
        <img src={img.img[0]} alt="..." className="image-proceed" />
        <h6> {product.product.product_name}</h6>
      </div>
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td className="text-left">Total :</td>
            <td className="text-right">
              ₦
              {numberToComma(
                activeVaria?.net_price !== undefined
                  ? activeVaria.net_price
                  : product.product.net_price
                  ? product.product.net_price
                  : "no price"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const prod_image = img.img.map((index, i) => {
    const imaged = {
      original: index,
      thumbnail: index,
      originalClass: "image-view",
    };
    return imaged;
  });

  return (
    <Layout>
      <section className="product-container">
        <div className="bread-crumbs mb-4">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`/product-category/${product.product.categoty_id}`}>
            {product.product.category_title}
          </Link>
          <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to={`/product/${product.product.product_slug}`}>
            {product.product.product_name}
          </Link>
        </div>
        <NotificationContainer />

        <section className="row">
          <div className="col-md-7 image-gall">
            <ImageGallery
              items={prod_image}
              showPlayButton={false}
              onErrorImageURL={cart}
              thumbnailPosition="left"
            />
          </div>
          <div className="col-md-5">
            <div>
              {(activeVaria?.quantity === undefined
                ? product.product.stock_limit < 1
                : activeVaria?.quantity < 1) && (
                <h5 className="text-danger">OUT OF STOCK</h5>
              )}
              <h3>{product.product.product_name}</h3>
              <p className="item-brand">
                <strong>
                  Store:{" "}
                  <span
                    className="prod m-2"
                    onClick={() =>
                      window.open(
                        `http://${store.store.store_url}.shobbu.com`,
                        "_blank"
                      )
                    }
                  >
                    {store.store.store_name}
                  </span>
                </strong>
              </p>
              <p className="item-price">
                ₦{" "}
                {numberToComma(
                  activeVaria?.net_price !== undefined
                    ? activeVaria.net_price
                    : product.product.net_price
                    ? product.product.net_price
                    : "no price"
                )}
              </p>
              <p>
                <del className="item-price-old">
                  ₦{" "}
                  {numberToComma(
                    activeVaria?.price !== undefined
                      ? activeVaria.price
                      : product.product.price
                      ? product.product.price
                      : "no price"
                  )}
                </del>
                <span className="item-price-dis">
                  -
                  {activeVaria?.discount_type !== undefined &&
                  activeVaria?.discount_type === "value"
                    ? "₦"
                    : product.product.discount_type === "value"
                    ? "₦"
                    : ""}
                  {activeVaria?.discount !== undefined
                    ? activeVaria.discount
                    : product.product.discount
                    ? product.product.discount
                    : "no discount"}
                  {activeVaria?.discount_type !== undefined &&
                  activeVaria?.discount_type === "percent"
                    ? "% "
                    : product.product.discount_type === "percent"
                    ? "% "
                    : ""}
                </span>
              </p>
              <p>{thestar(product.product.rating)} (no review yet)</p>
              <p>Qty</p>
              <div className="quantites">
                <button onClick={down} className="count-cart">
                  <strong>-</strong>
                </button>
                <span className="count-cart-number">
                  {" "}
                  <strong>{count}</strong>{" "}
                </span>
                <button onClick={up} className="count-cart">
                  <strong>+</strong>
                </button>
              </div>

              {product.product.hasVariation && (
                <div className="mb-2">
                  <p>Variation</p>
                  {(variation.variation || []).map((item, i) => {
                    return (
                      <div key={i} className="variation-cont">
                        <p
                          className={
                            activeVaria.id === item.id
                              ? `variation-selected`
                              : `variation-cart`
                          }
                          onClick={() => selectVariation(item)}
                        >
                          {item.variation}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}

              <span
                className=""
                onClick={() => {
                  if (product.product.favourites.length > 0) {
                    removeFav(product.product.id);
                  } else {
                    saveItem(product.product.id);
                  }
                }}
              >
                {product.product.favourites ? (
                  product.product.favourites.length > 0 ? (
                    <span style={{ color: "#0279B8" }}>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </span>
                  ) : (
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                  )
                ) : (
                  <i class="fa fa-heart-o" aria-hidden="true"></i>
                )}{" "}
                add to wishlist
              </span>

              <p>
                <input type="checkbox" name="agree" id="" /> i agree with terms
                and conditions
              </p>

              <button
                onClick={addToCart}
                className="addtocart mt-4"
                disabled={
                  (
                    activeVaria?.quantity === undefined
                      ? product.product.stock_limit < 1
                      : activeVaria?.quantity < 1
                  )
                    ? true
                    : false
                }
              >
                {(
                  activeVaria?.quantity === undefined
                    ? product.product.stock_limit < 1
                    : activeVaria?.quantity < 1
                ) ? (
                  "Sold out"
                ) : (
                  <strong>
                    Add to cart{" "}
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                  </strong>
                )}
              </button>

              <div className="pt-3">
                <h6>share with friends </h6>
                <span>
                  <i
                    className="fa fa-facebook fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-youtube fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-twitter fa-lg mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                </span>
                <span>
                  <i
                    className="fa fa-instagram fa-lg mr-1"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </section>

        {/* <section>
          <div className="recent-cart-cont mb-3">
            <div>
              <h5>Store</h5>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <img
                className="bid-image-container m-2"
                src={store.store.logo}
                alt="store"
              />

              <h2 className="m-2">{store.store.store_name}</h2>
            </div>
            <h6
              className="prod m-2"
              onClick={() =>
                window.open(
                  `http://${store.store.store_url}.shobbu.com`,
                  "_blank"
                )
              }
            >
              View site
            </h6>
          </div>
        </section> */}

        <section className="mt-4 mb-4">
          <div className="recent-cart-cont mb-3">
            <div>
              <h5>Product Details</h5>
            </div>
          </div>

          <Tabs
            defaultActiveKey="description"
            id="uncontrolled-tab-example"
            className="text-primary"
          >
            <Tab eventKey="description" title="Description">
              <p className="mt-2">{product.product.description}</p>
            </Tab>
            <Tab eventKey="specification" title="Specification">
              <div className="mt-2">{product.product.specification}</div>
            </Tab>
            <Tab eventKey="review" title="Reviews">
              <div className="mt-2 pt-3">
                {(reviews || []).map((item, i) => {
                  return (
                    <div>
                      <div className="row text-left" key={i}>
                        <div className="col-md-3">
                          <p>{item.user.full_name}</p>
                        </div>
                        <div className="col-md-7">
                          <p>{item.review}</p>
                        </div>
                        <div className="col-md-2">
                          <p>
                            {item.star}{" "}
                            <i class="fa fa-star" aria-hidden="true"></i>{" "}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
                {reviews.length < 1 && <p>No reviews yet</p>}
              </div>
            </Tab>
          </Tabs>
        </section>

        <section className="recently-viewed">
          <div className="recent-cart-cont mb-3">
            <div>
              <h5>Related Product</h5>
            </div>
          </div>

          <div className="cart-recent-container">
            {(related.products || []).map((item, i) => {
              return (
                <>
                  {isMobile ? (
                    <CardFeatureMobile
                      photo={item.images[0]}
                      item={truncate(item.product_name, 20)}
                      price={numberToComma(item.net_price)}
                      desc={truncate(item.description, 25)}
                      //like={item.favourites.length > 0 ? true : false}
                      soldout={item.stock_limit < 1 ? true : false}
                      oldPrice={item.price}
                      discount={item.discount}
                      action={() =>
                        history.push(`/product/${item.product_slug}`)
                      }
                    />
                  ) : (
                    <CardFeature
                      photo={item.images[0]}
                      item={truncate(item.product_name, 20)}
                      price={numberToComma(item.net_price)}
                      desc={truncate(item.description, 25)}
                      //like={item.favourites.length > 0 ? true : false}
                      soldout={item.stock_limit < 1 ? true : false}
                      oldPrice={item.price}
                      discount={item.discount}
                      action={() =>
                        history.push(`/product/${item.product_slug}`)
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        </section>

        <section className="recently-viewed">
          <div className="recent-cart-cont mb-3">
            <div>
              <h5>More from this Merchant</h5>
            </div>
          </div>

          <div className="cart-recent-container">
            {(related.products || []).map((item, i) => {
              return (
                <>
                  {isMobile ? (
                    <CardFeatureMobile
                      photo={item.images[0]}
                      item={truncate(item.product_name, 20)}
                      price={numberToComma(item.net_price)}
                      desc={truncate(item.description, 25)}
                      //like={item.favourites.length > 0 ? true : false}
                      soldout={item.stock_limit < 1 ? true : false}
                      oldPrice={item.price}
                      discount={item.discount}
                      action={() =>
                        history.push(`/product/${item.product_slug}`)
                      }
                    />
                  ) : (
                    <CardFeature
                      photo={item.images[0]}
                      item={truncate(item.product_name, 20)}
                      price={numberToComma(item.net_price)}
                      desc={truncate(item.description, 25)}
                      //like={item.favourites.length > 0 ? true : false}
                      soldout={item.stock_limit < 1 ? true : false}
                      oldPrice={item.price}
                      discount={item.discount}
                      action={() =>
                        history.push(`/product/${item.product_slug}`)
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        </section>
      </section>
    </Layout>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  product: state.product,
  carts: state.carts,
  favorites: state.favorites,
  order: state.order,
  cart: state.cart,
  reviews: state.reviews,
});

const pageActions = {
  fetchSingleProduct,
  fetchAddCart,
  fetchFavoritePost,
  fetchCancelOrder,
  fetchReviews,
  fetchFavoriteRemove,
  fetchStores,
};
export default connect(mapStateToProps, pageActions)(Product);
