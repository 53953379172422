import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import cart from "../../images/cart.png";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

const CardArrivalMobile = (props) => {
  const {
    item,
    price,
    desc,
    photo,
    action,
    like,
    favourite,
    oldPrice,
    discount,
    soldout,
  } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  return (
    <Container>
      <div className="card-arrival-mobile">
        <div className="card-arrival-mobile-body">
          <div className="d-flex justify-content-between ">
            <div>
              {like ? (
                <span style={{ color: "#f5a623" }} onClick={favourite}>
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </span>
              ) : (
                <i className="fa fa-heart-o" aria-hidden="true"></i>
              )}
              <span style={{ color: "#f5a623" }}>
                <i className="fa fa-eye ml-2" aria-hidden="true"></i>
              </span>
            </div>
            <div className="discount-item p-1">
              <strong>New</strong>
            </div>
          </div>
          <div className="top-sell-side mt-1" onClick={action}>
            <img
              src={photo}
              className="card-item-image-container-mobile rounded"
              alt="deal day"
              onError={addDefaultSrc}
            />
          </div>
          <div>
            <p className="card-arrival-mobile-name">{truncate(item, 17)}</p>
            <p className="card-arrival-mobile-desc">{truncate(desc, 20)}</p>
            <p className="card-arrival-mobile-name">₦ {numberToComma(price)}</p>
          </div>
          <div className="d-flex justify-content-center card-arrival-mobile-small">
            <div className="mr-3">
              <div className="">
                <del>₦ {oldPrice}</del>
              </div>
            </div>
            <div className="discount">
              <span>-{discount}%</span>
            </div>
          </div>
          <div className="card-actions">
            {soldout ? (
              <button onClick={action} className="btn btn-sm sold-out mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>sold out</small>
              </button>
            ) : (
              <button onClick={action} className="btn btn-sm add-cart mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>add to cart</small>
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardArrivalMobile;
