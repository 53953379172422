import {
  FETCH_REVIEW,
  FETCH_REVIEW_SUCCESS,
  FETCH_REVIEW_FAILED
} from '../../types';

const initialState = {
  review: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchReviewReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_REVIEW:
      return {
        ...state,
        isLoading: true,
          review: action.payload
      }
      case FETCH_REVIEW_SUCCESS:
        return {
          ...state,
          isLoading: false,
            review: action.payload
        }
        case FETCH_REVIEW_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchReviewReducer;
