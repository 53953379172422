import React from 'react';

const Nostar = props => {
  return (
    <i
      className="fa fa-star-o"
      aria-hidden="true"
      style={{ color: "#ff804c" }}
    ></i>
  )
}

export default Nostar;