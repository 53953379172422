import React, { useState } from "react";
import { Container } from "./Container";
import "./styles.scss";
import { UncontrolledCollapse, Card } from "reactstrap";
import swal from "@sweetalert/with-react";
import ModalExample from "../Modal/Modal";
import numberToComma from "../../utils/numberToComma";
import BeautyStars from "beauty-stars";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { fetchPostReviews } from "../../redux/actions/products";
import { useEffect } from "react";
import dayjs from "dayjs";

const CardOrder = (props) => {
  const { order, navigate } = props;

 
  return (
    <Container>
      <div className="" onClick={navigate}>
        <div className="order-item-body row">
          {/* <div className="col-md-3 order-image">
            <img
              src={order.items.items[0].product.images[0]}
              width="70%"
              alt="watch"
            />
          </div> */}
          <div className="col-md-6 d-flex justify-content-between flex-column">
            <p className="order-name">Order ID: #{order.id}</p>
            {/* <p className="order-id">Order ID: #{order.id}</p> */}
            <p className="order-info">
             {order.items?.items.length} item(s)
            </p>
          </div>
          <div className="col-md-3 d-flex justify-content-between flex-column">
            <p className="order-date">
              {dayjs(order.createdAt).format("MMMM D, YYYY h:mm A")}
            </p>
            <strong
              className={
                order.order_proccessing_status !== "cancelled"
                  ? "order-info"
                  : "order-status"
              }
            >
              {order.order_proccessing_status}
            </strong>
          </div>
          <div className="col-md-3 text-right d-flex justify-content-between flex-column">
            <p className="order-name">₦ {numberToComma(order.net_price)}</p>
           
          </div>
        </div>
      </div>

      {/* <UncontrolledCollapse toggler={`#toggler${order.id}`}>
        {(order.items.items || []).map((item, i) => {
          return (
            <div className="order-item mb-3" key={i}>
              <div className="order-item-body row">
                <div className="col-md-2 order-image">
                  <img src={item.product.images[0]} width="50%" alt="watch" />
                </div>
                <div className="col-md-5 d-flex justify-content-between flex-column">
                  <Link to={`/product/${item.product.id}`}>
                    <p className="order-name">{item.product.product_name}</p>
                  </Link>

                 
                  <p className="order-info">
                    <i className="fa fa-check" aria-hidden="true"></i> Item
                    delivered on {dayjs(order.createdAt).format("YYYY MM DD")}
                  </p>
                </div>
                <div className="col-md-3 d-flex justify-content-between flex-column">
                  <p className="order-date">
                    {dayjs(order.createdAt).format("YYYY MM DD")}
                  </p>
                  <p
                    className={
                      order.order_proccessing_status !== "cancelled"
                        ? "order-info"
                        : "order-status"
                    }
                  >
                    {order.order_proccessing_status}
                  </p>
                </div>
                <div className="col-md-2 text-right d-flex justify-content-between flex-column">
                  <p className="order-name">
                    ₦ {numberToComma(item.total_price)}
                  </p>

                  <ModalExample
                    modalTitle="Add Review"
                    classBtn="btn btn-sm btn-primary bid-btn"
                    buttonLabel="review"
                    handleAction={() => addReview(item.product.id)}
                    handleTitle={check ? "sending" : "send"}
                  >
                    <div>
                      <form>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Review</label>
                          <textarea
                            type="text"
                            class="form-control"
                            aria-describedby="emailHelp"
                            name="review"
                            value={form.review}
                            onChange={(e) =>
                              setForm({ ...form, review: e.target.value })
                            }
                            required
                            autoFocus
                            placeholder="enter your review"
                          ></textarea>
                        </div>

                        <BeautyStars
                          activeColor="#0279B8"
                          value={form.star}
                          onChange={(value) =>
                            setForm({ ...form, star: value })
                          }
                        />
                      </form>
                    </div>
                  </ModalExample>
                </div>
              </div>
            </div>
          );
        })}
      </UncontrolledCollapse> */}
    </Container>
  );
};

CardOrder.propTypes = {
  review: PropTypes.object.isRequired,
  fetchUserEditProfile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  review: state.review,
});

const pageActions = { fetchPostReviews };

export default connect(mapStateToProps, pageActions)(CardOrder);
