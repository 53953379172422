import React from "react";
import { Container } from "./Container";
import "./styles.scss";

import CardContainer1 from "../CardContainer1/CardContainer1";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

const CardTopSeller = (props) => {
  const { item, desc, price, photo, favourite, like, rate, navigate } = props;
  return (
    <Container>
      <CardContainer1
        cardLeft={
          <div className="heart-container-top" onClick={favourite}>
            {like ? (
              <span style={{ color: "#0279B8" }}>
                <i className="fa fa-heart" aria-hidden="true"></i>
              </span>
            ) : (
              <i className="fa fa-heart-o" aria-hidden="true"></i>
            )}
            <p>Wishlist</p>
          </div>
        }
        cardRight={
          <div className="heart-container-top">
             <span style={{ color: "#0279B8" }}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </span>
            <p>Quick view</p>
          </div>
        }
        cardRate={
          <div className="star-icon">
            {rate}
            <i
              className="fa fa-star"
              aria-hidden="true"
              style={{ color: "#ff804c" }}
            ></i>
          </div>
        }
        cardCart={
          <div>
            <i
              className="fa fa-shopping-cart fa-lg"
              aria-hidden="true"
              style={{ color: "#0279B8" }}
            ></i>
          </div>
        }
        //cardCenter={<button className="btn btn-sm btn-primary"><i className="fa fa-shopping-cart" aria-hidden="true"></i> <small>add to cart</small></button>}
        photo={photo}
        item={truncate(item, 25)}
        desc={truncate(desc, 25)}
        price={numberToComma(price)}
        action={navigate}
      />
    </Container>
  );
};

export default CardTopSeller;
