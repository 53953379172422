import axios from 'axios';
import {
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constants'

import Auth from '../../config/auth'

// export const fetchCategory = () => dispatch => {

//   axios.get(`${BASE_URL}/products/category/list/shobbu`)
//     .then(response => {
//       console.log(response)
//       if (!response.hasError) {
//         dispatch({
//           type: FETCH_CATEGORY_SUCCESS,
//           payload: response.data
//         })
//       } else {
//         dispatch({
//           type: FETCH_CATEGORY_FAILED,
//           payload: response.data
//         })
//       }
//     })
//     .catch(err => {
//       dispatch({
//         type: FETCH_CATEGORY_FAILED,
//         payload: {
//           hasError: true,
//           errors: {
//             message: "network error",
//             error: err.data
//           }
//         }
//       })
//     })
// }


export const fetchNotifications = (count) => dispatch => {
  var pageCount = 10 + (count ? count : 0);
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.get(`${BASE_URL}/notification/?pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_NOTIFICATIONS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_NOTIFICATIONS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
