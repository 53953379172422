import {
  FETCH_ORDER,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILED
} from '../../types';

const initialState = {
  order: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchOrderReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        isLoading: true,
          order: action.payload
      }
      case FETCH_ORDER_SUCCESS:
        return {
          ...state,
          isLoading: false,
            order: action.payload
        }
        case FETCH_ORDER_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchOrderReducer;
