import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import numberToComma from "../../utils/numberToComma";

const CardBids = (props) => {
  const {
    bids,
    cardRight,
    item,
    price,
    addCart,
    photo,
    action,
    win,
    name,
  } = props;
  return (
    <Container>
      <div className="order-item mb-3">
        <div className="order-item-body row">
          <div className="col-md-3 order-image">
            <img src={photo} width="70%" alt="watch" />
          </div>
          <div className="col-md-4 d-flex justify-content-between flex-column">
            <div>
              <p className="order-name">{name}</p>
              <p className="order-bidding">20 Bids</p>
            </div>
            {win ? (
              <div>
                <p className="order-info">
                  <i className="fa fa-check" aria-hidden="true"></i> Awesome,
                  you are the highest bidder
                </p>
                <p className="order-info">
                  <i className="fa fa-check" aria-hidden="true"></i> Item
                  delivered on 30-01-2020
                </p>
              </div>
            ) : (
              <p className="order-status">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>{" "}
                You are not the highest bidder
              </p>
            )}
          </div>
          <div className="col-md-2 d-flex justify-content-between flex-column">
            <p className="order-date">COMPLETE</p>
            {/* <p className="order-status">Delivery didn’t happen!</p> */}
          </div>
          <div className="col-md-3 text-right d-flex justify-content-between flex-column">
            <p className="order-name">₦ {numberToComma(price)}</p>
            {win && <button
              className="btn btn-outline-primary bid-btn"
              onClick={addCart}
            >
              add cart
            </button>}
            
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardBids;
