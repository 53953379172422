import axios from 'axios';
import {
  FETCH_STORES,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_FAILED,
  FETCH_STORE_SUCCESS,
  FETCH_STORE_FAILED,
  FETCH_CATEGORY,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constants'

import Auth from '../../config/auth'

// const token = Auth.getToken();
// const headers = {
//   Authorization: `Bearer ${token}`
// };

// export const fetchCategory = () => dispatch => {

//   axios.get(`${BASE_URL}/products/category/list/shobbu`)
//     .then(response => {
//       console.log(response)
//       if (!response.hasError) {
//         dispatch({
//           type: FETCH_CATEGORY_SUCCESS,
//           payload: response.data
//         })
//       } else {
//         dispatch({
//           type: FETCH_CATEGORY_FAILED,
//           payload: response.data
//         })
//       }
//     })
//     .catch(err => {
//       dispatch({
//         type: FETCH_CATEGORY_FAILED,
//         payload: {
//           hasError: true,
//           errors: {
//             message: "network error",
//             error: err.data
//           }
//         }
//       })
//     })
// }


export const fetchStores = (count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.get(`${BASE_URL}/merchants/all-stores?pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_STORES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_STORES_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_STORES_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchFavouriteStores = (count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.get(`${BASE_URL}/merchants/store-favourite?pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_STORE_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_STORE_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_STORE_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
