import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import cart from "../../images/cart.png";

const CardContainer1 = (props) => {
  const { cardLeft, cardRight, cardCenter, cardRate, cardCart, item, price, desc, photo, action } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  return (
    <Container>
      <div className="card-item">
        <div className="card-item-body">
          <div className="d-flex justify-content-center">
            {/* <div>{cardLeft}</div> */}
            <div className="item-image-container" onClick={action}>
              <img
                src={photo}
                className="card-item-image-container rounded"
                width="100%"
                alt="deal day"
                onError={addDefaultSrc}
              />
            </div>
            {/* <div>{cardRight}</div> */}
          </div>
          <div onClick={action}>
            <p className="card-item-item-name">{item}</p>
            <p className="card-item-item-desc">{desc}</p>
            <p className="card-item-item-name">₦ {price}</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="mr-3">{cardRate}</div>
            <div>{cardCart}</div>
          </div>
          <div className="card-actions">
          <div>{cardLeft}</div> 
          <div> {cardCenter}</div>
          <div onClick={action}>{cardRight}</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardContainer1;
