import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import swal from "@sweetalert/with-react";
import { Link } from "react-router-dom";
import CardBids from "../../component/CardBids/CardBids";
import OrderSiderbar from "../../component/OrderSiderbar/OrderSidebar";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchBids } from "../../redux/actions/auctions";
import { fetchAddCart } from "../../redux/actions/products";

import { isMobile, isDesktop } from "../../utils/isLayout";

const Bid = (props) => {
  const [auction, setAuction] = useState([]);
  const [check, setCheck] = useState(false);
  useEffect(() => {
    props.fetchBids();
  }, []);
  useEffect(() => {
    if (props.bids.bids.data) {
      setAuction(props.bids.bids.data.personalBids);
    }
  }, [props.bids.bids]);

  useEffect(() => {
    if (check) {
      if (props.cart.cart.data) {
        swal("Product added to cart", "", "success");

        // swal(popUpCart);
        // swal(popUpCart, {
        //   buttons: {
        //     checkout: {
        //       text: "proceed to checkout",
        //       className: "proceed-btn",
        //     },
        //     continue: {
        //       text: "continue shopping",
        //       className: "continue-btn",
        //     },
        //     cancel: false,
        //   },
        // }).then((value) => {
        //   switch (value) {
        //     case "checkout":
        //       history.push("/product-cart");
        //       break;
        //     case "continue":
        //       setCheck(true);
        //       // swal("cancel orders", "success");
        //       break;
        //     default:
        //   }
        // });
      }
      // //swal(popUpCart);
      // setMessage(props.cart.cart.message);
      // NotificationManager.success(props.cart.cart.message);
      setCheck(false);
    }
  }, [props.cart.cart]);

  useEffect(() => {
    if (check && props.cart.errors.errors) {
      swal(props.cart.errors.errors.message, "", "error");
      setCheck(false);
    }
  }, [props.cart.errors.errors]);

  function addCart(id) {
    setCheck(true);
    props.fetchAddCart({ product_id: id, quantity: 1 });
  }

  return (
    <Layout>
      <section className="order-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/bid">Bids</Link>
          <p className="title-page">Bids</p>
        </div>

        <div className="row">
          <div className="col-md-8">
            {isMobile && <OrderSiderbar />}
            <div className="order-item">
              <div className="order-item-body">
                {auction.map((item, i) => {
                  return (
                    <CardBids
                      price={item.bid}
                      name={item.auction.product.product_name}
                      photo={item.auction.product.images[0]}
                      win={item.winner}
                      key={i}
                      addCart={() => addCart(item.auction.product_id)}
                    />
                  );
                })}
                {auction.length < 1 && (
                  <h4 className="text-center p-5">No Bids at the moment</h4>
                )}
              </div>
            </div>
          </div>
          {isDesktop && (
            <div className="col-md-4">
              <OrderSiderbar />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

Bid.propTypes = {
  bids: PropTypes.object.isRequired,
  fetchBids: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  bids: state.bids,
  cart: state.cart,
});

const pageActions = { fetchBids, fetchAddCart };
export default connect(mapStateToProps, pageActions)(Bid);
