import axios from 'axios';
import {
  FETCH_ALL_PRODUCTS,
  FETCH_ALL_PRODUCTS_FAILED,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCTS,
  FETCH_SINGLE_PRODUCTS_FAILED,
  FETCH_SINGLE_PRODUCTS_SUCCESS,
  FETCH_CART,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILED,
  FETCH_CART_ITEM,
  FETCH_CART_ITEM_SUCCESS,
  FETCH_CART_ITEM_FAILED,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILED,
  FETCH_FAVORITE_SUCCESS,
  FETCH_FAVORITE_FAILED,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILED,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILED,
  FETCH_REVIEW_SUCCESS,
  FETCH_REVIEW_FAILED,
  FETCH_RECENTLY_VIEW_SUCCESS,
  FETCH_RECENTLY_VIEW_FAILED,
  FETCH_TRENDING_SUCCESS,
  FETCH_TRENDING_FAILED,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_SINGLE_ORDER_FAILED,
  FETCH_DISPUTES_FAILED,
  FETCH_DISPUTES_SUCCESS,
  FETCH_SEARCH_FAILED,
  FETCH_SEARCH_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constants'
import Auth from '../../config/auth'

const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};


export const fetchAllProducts = (count) => dispatch => {
  var pageCount = 15 + (count ? count : 0);

  axios.get(`${BASE_URL}/products/view/all/shobbu?pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ALL_PRODUCTS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ALL_PRODUCTS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ALL_PRODUCTS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchSingleProduct = (id) => dispatch => {

  axios.get(`${BASE_URL}/products/slug/${id}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SINGLE_PRODUCTS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SINGLE_PRODUCTS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SINGLE_PRODUCTS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAddCart = (data) => dispatch => {

  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/cart/add`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_CART_SUCCESS,
          payload: response.data
        })
        dispatch(fetchViewCart())
      } else {
        dispatch({
          type: FETCH_CART_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CART_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            code: 412,
            error: err.data
          }
        }
      })
    })
}

export const fetchViewCart = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/cart/view`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_CART_ITEM_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_CART_ITEM_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CART_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUpdateCart = (id, data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/cart/update/${id}`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {

        dispatch({
          type: FETCH_CART_SUCCESS,
          payload: response.data
        })
        dispatch(fetchViewCart())
      } else {
        dispatch({
          type: FETCH_CART_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CART_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchRemoveCart = (data) => dispatch => {
  const token = Auth.getToken();
  axios.delete(`${BASE_URL}/cart/remove`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {

        dispatch({
          type: FETCH_CART_SUCCESS,
          payload: response.data
        })
        dispatch(fetchViewCart())
      } else {
        dispatch({
          type: FETCH_CART_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CART_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchRemoveAllCart = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/cart/empty`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_CART_SUCCESS,
          payload: response.data
        })
        dispatch(fetchViewCart())
      } else {
        dispatch({
          type: FETCH_CART_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CART_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchOrderSummary = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/orders/view`, {}, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchMyOrder = (count, filter) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  var pageCount = 10 + (count ? count : 0);

  axios.get(`${BASE_URL}/orders/view?filter=${filter}&pages=1&paginate=${pageCount}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDERS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ORDERS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}



export const fetchCancelOrder = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/orders/cancel`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
        dispatch(fetchRemoveAllCart())
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchApplyCoupon = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/orders/coupon`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchFavoritePost = (id) => dispatch => {
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.post(`${BASE_URL}/products/favourites/${id}`, {}, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_FAVORITE_SUCCESS,
          payload: response.data
        })
        dispatch(fetchViewFavorite())
        dispatch(fetchTrendingProducts())
      } else {
        dispatch({
          type: FETCH_FAVORITE_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_FAVORITE_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchFavoriteRemove = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/products/favourites/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        // dispatch({
        //   type: FETCH_FAVORITE_SUCCESS,
        //   payload: response.data
        // })
        dispatch(fetchViewFavorite())
        dispatch(fetchTrendingProducts())
      } else {
        dispatch({
          type: FETCH_FAVORITE_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_FAVORITE_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchViewFavorite = (count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/products/favourites?pages=1&paginate=${pageCount}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_FAVORITE_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_FAVORITE_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_FAVORITE_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}



export const fetchReviews = (id, count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.get(`${BASE_URL}/products/review/${id}?pages=1&paginate=${pageCount}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_REVIEWS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_REVIEWS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_REVIEWS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchPostReviews = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/products/review`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_REVIEW_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_REVIEW_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_REVIEW_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchRecentView = (count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/products/recentview?pages=1&paginate=${pageCount}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_RECENTLY_VIEW_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_RECENTLY_VIEW_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_RECENTLY_VIEW_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchTrendingProducts = (count) => dispatch => {
  // var pageCount = 12 + (count ? count : 0);
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};
  axios.get(`${BASE_URL}/products/trending?store_url=shobbu&days=14`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_TRENDING_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_TRENDING_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_TRENDING_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchSingleOrder = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/orders/view/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SINGLE_ORDER_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SINGLE_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SINGLE_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchDisputesOrder = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/orders/dispute/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_DISPUTES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_DISPUTES_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_DISPUTES_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchSearch = (item) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  var pageCount = 10 + (item.pageCount ? item.pageCount : 0);

  axios.get(`${BASE_URL}/products/search/1585139305?pages=1&paginate=${pageCount}&search=${item.text || ''}&category_id=${item.catId || ''}&subcategory=${item.subCat || ''}&minPrice=${item.min || 0}&maxPrice=${item.max || ''}&order=${item.order || ''}&order_value=${item.orderVal || 'DESC'}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SEARCH_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SEARCH_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SEARCH_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
