import {
  combineReducers
} from 'redux';

import fetchProductsReducer from './productReducers/fetchProductReducer';
import fetchSingleProductsReducer from './productReducers/fetchSingleProductReducer';
import fetchCategoryReducer from './productReducers/fetchCategoryReducer';
import fetchCategoryProductReducer from './productReducers/fetchCategoryProductReducer';
import fetchRegisterEmailReducer from './authReducers/fetchRegisterEmailReducer';
import fetchLoginEmailReducer from './authReducers/fetchLoginEmailReducer';
import fetchUserVerifyReducer from './authReducers/fetchUserVerifyReducer';
import fetchAddressReducer from './authReducers/fetchAddressReducer';
import fetchCartReducer from './productReducers/fetchCartReducer';
import fetchCartItemReducer from './productReducers/fetchCartItemReducer';
import fetchUserReducer from './authReducers/fetchUserReducer';
import fetchOrderReducer from './productReducers/fetchOrderReducer';
import fetchFavoriteReducer from './productReducers/fetchFavoriteReducer';
import fetchPaymentReducer from './paymentReducers/fetchPaymentReducer';
import fetchOrdersReducer from './productReducers/fetchOrdersReducer'
import fetchReviewReducer from './productReducers/fetchReviewReducer';
import fetchReviewsReducer from './productReducers/fetchReviewsReducer';
import fetchRecentViewReducer from './productReducers/fetchRecentViewReducer';
import fetchAuctionsReducer from './auctionReducer/fetchAuctionsReducer';
import fetchAuctionReducer from './auctionReducer/fetchAuctionReducer';
import fetchAuctionBidReducer from './auctionReducer/fetchAuctionBidReducer';
import fetchBidsReducer from './auctionReducer/fetchBidsReducer';
import fetchTrendingReducer from './productReducers/fetchTrendingReducer';
import fetchNotificationsReducer from './notificationReducers/fetchNotificationReducer';
import fetchStoresReducer from './storeReducers/fetchStoresReducer';
import fetchStoreReducer from './storeReducers/fetchStoreReducer';
import fetchSingleOrderReducer from './productReducers/fetchSingleOrderReducer';
import fetchDisputesReducer from './productReducers/fetchDisputesReducer';
import fetchSearchReducer from './productReducers/fetchSearchReducer';



export default combineReducers({
  products: fetchProductsReducer,
  emailRegister: fetchRegisterEmailReducer,
  emailLogin: fetchLoginEmailReducer,
  profile: fetchUserReducer,
  verifyUser: fetchUserVerifyReducer,
  category: fetchCategoryReducer,
  category_prod: fetchCategoryProductReducer,
  product: fetchSingleProductsReducer,
  address: fetchAddressReducer,
  cart: fetchCartReducer,
  carts: fetchCartItemReducer,
  order: fetchOrderReducer,
  orders: fetchOrdersReducer,
  single_order: fetchSingleOrderReducer,
  favorites: fetchFavoriteReducer,
  payment: fetchPaymentReducer,
  review: fetchReviewReducer,
  reviews: fetchReviewsReducer,
  recent: fetchRecentViewReducer,
  auctions: fetchAuctionsReducer,
  auction: fetchAuctionReducer,
  auctionBid: fetchAuctionBidReducer,
  bids: fetchBidsReducer,
  trends: fetchTrendingReducer,
  notifications: fetchNotificationsReducer,
  stores: fetchStoresReducer,
  store: fetchStoreReducer,
  disputes: fetchDisputesReducer,
  search: fetchSearchReducer
})
