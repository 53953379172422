import React, { Fragment } from "react";
import Layout from "../component/Layout/Layout";
import dollar from "../images/dollar-sign.png";
import shop from "../images/shop.png";
import catban1 from "../images/catban1.png";
import catban2 from "../images/catban2.png";
import item from "../images/item.png";
import chair from "../images/chair.png";
import light from "../images/light.png";
import avocado from "../images/avocado.png";
import logo from "../images/logo.svg";
import Carousel from "react-bootstrap/Carousel";
import "./styles.scss";
import banna from "../images/banna.jpeg";

import banner1 from "../images/banner1.jpg";
import banner3 from "../images/banner3.jpg";
import home from "../images/home.png";
import SellAds from "../component/SellAds/SellAds";
import CardTopSeller from "../component/CardTopSeller/CardTopSeller";

const Revamp = (props) => {
  return (
    <Fragment>
      {/* nav one */}
      <ul className="nav-one" role="navigation">
        <li>
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-youtube" aria-hidden="true"></i>
        </li>
        <li className="nav-icons">
          <i className="fa fa-envelope" aria-hidden="true">
            info@shobbu.com
          </i>
        </li>

        <li>
          <span>30days</span> free return
        </li>
        <li>
          <span>247</span> customer service
        </li>
        <li>
          <span>Best</span> Nationwide delivery
        </li>

        <li>
          <img src={dollar} width="20px" alt="dollar" /> Auction
        </li>
        <li>
          <img src={shop} width="20px" alt="shop" /> sell
        </li>
      </ul>

      {/* nav two */}
      <ul className="nav-two" role="navigation">
        <li>
          <img src={logo} width="80px" alt="logo" />
        </li>
        <li className="nav-search">
          <input type="text" className="input-search" />
          <i class="fa fa-search" aria-hidden="true"></i>
        </li>
        <li className="info">
          <span>
            <i className="fa fa-headphones" aria-hidden="true"></i>
          </span>
          <span className="info-contact">
            <a href="tel:+2348093433333">Call us: (+234) 8093433333</a>
            <span>Open from 8am - 6pm (Mon - Sat)</span>
          </span>
        </li>
      </ul>

      {/* nav one */}
      <ul className="nav-three">
        <li>
          <span>
            All categories
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Groceries <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Electronics <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Fashion <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Health and beauty
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Fashion <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
        <li>
          <span>
            Health and beauty
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </li>
      </ul>

      {/* carousel */}
      <div className="carousel-container">
        <Carousel interval={3000}>
          <Carousel.Item>
         
            <h1 className="carosel-text">FURNITURE THAT MAKES UP TO 70% OFF</h1>
            <img
              className="d-block w-100 carosel"
              src={home}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <h1 className="carosel-text">FURNITURE THAT MAKES UP TO 70% OFF</h1>
            <img
              className="d-block w-100 carosel"
              src={home}
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <main className="main-container">
        {/* categories */}
        <div className="categories-main">
          <ul className="categories">
            {/* <li><img src={catban2} alt="cat" width="100%" height="200px"/> </li>
            <li><img src={catban2} alt="cat" width="100%" height="200px"/> </li>
            <li><img src={catban2} alt="cat" width="100%" height="200px"/> </li> */}
          </ul>
          <ul className="categories-large">
            {/* <li><img src={catban1} alt="cat" width="100%" height="400px" /></li>
            <li><img src={catban1} alt="cat" width="100%" height="400px"/></li> */}
          </ul>
        </div>
        {/* top sellers */}
        <div className="topseller-cont">
          <div>
            <h5>Top Sellers</h5>
            <p>Weekely line up for our best sellers</p>
          </div>
          <div>
            <span className="arrow-icon">
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </span>
            <span className="arrow-icon">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </span>
          </div>
        </div>

        <div className="topseller-items">
        <CardTopSeller
                    photo={item}
                    item="Spiderman bag"
                    rate={4}
                    price={10000}
                    desc="spider man bag"
                    like={true}
                  
                  />
                   <CardTopSeller
                    photo={item}
                    item="Spiderman bag"
                    rate={4}
                    price={10000}
                    desc="spider man bag"
                    like={true}
                  
                  />
                   <CardTopSeller
                    photo={item}
                    item="Spiderman bag"
                    rate={4}
                    price={10000}
                    desc="spider man bag"
                    like={true}
                  
                  />
        </div>

         {/* advertist */}
         <section className="mt-3 mb-2">
          <div className="row">
            <img
              src={chair}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
            <img
              src={light}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
          </div>
        </section>


         {/* featured */}
         <div className="topseller-cont">
          <div>
            <h5>Featured</h5>
           
          </div>
         
        </div>

        <div className="feature-items"></div>

        {/* advertist */}
        <section className="market-ads-2 mt-3 mb-2">
          <div className="row">
            <img
              src={banner1}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
            <img
              src={banner3}
              alt="bid"
              className="ads-image col-md-6"
              width="100%"
            />
          </div>
        </section>

        {/* start selling */}

        <section className="pt-5 pb-3">
          <SellAds />
        </section>

         {/* arrivals */}
         <div className="topseller-cont">
          <div>
            <h5>New Arrivals</h5>
           
          </div>
         
        </div>

        <div className="arrivals-items"></div>

        {/* advertist */}
        <section className="mt-3 mb-2">
          <div className="row">
            <div className="ads-image-2  col-md-6">
            <h5 className="image-text">Fresh Avocado</h5>
              <img
                src={avocado}
                alt="bid"
                className="ads-image-2"
                width="100%"
              />
            </div>
            <div className="ads-image-2  col-md-6">
              <h5 className="image-text">Fresh Avocado</h5>
              <img
                src={avocado}
                alt="bid"
                className="ads-image-2"
                width="100%"
              />
            </div>
          </div>
        </section>


         {/* brands */}
         <div className="topseller-cont">
          <div>
            <h5>Brands We Love</h5>
           
          </div>
         
        </div>

        <div className="brands-items"></div>


         {/* store */}
         <div className="topseller-cont">
          <div>
            <h5>Stores</h5>
            <p>Weekely line up for our best sellers</p>
          </div>
         
        </div>

        <div className="store-items"></div>
          

      </main>
    </Fragment>
  );
};

export default Revamp;
