import {
  FETCH_FAVORITE,
  FETCH_FAVORITE_SUCCESS,
  FETCH_FAVORITE_FAILED
} from '../../types';

const initialState = {
  favorites: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchFavoriteReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_FAVORITE:
      return {
        ...state,
        isLoading: true,
          favorites: action.payload
      }
      case FETCH_FAVORITE_SUCCESS:
        return {
          ...state,
          isLoading: false,
            favorites: action.payload
        }
        case FETCH_FAVORITE_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchFavoriteReducer;
