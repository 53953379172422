import React from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link } from "react-router-dom";
import ios from "../../images/ios.svg";
import android from "../../images/android.svg";

const Sell = props => {
  return (
    <Layout>
      <section className="sell-app-section">
        <div className="p-5">
          <div className="bread-crumbs pb-5">
            <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
            <Link to="/sell">Sell</Link>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h1>Start selling, get started</h1>
              <p>
                Shobbu provides mobile and web e-commerce mobile app and website
                services where buyers meets sellers. Create a new experience for
                your customers today, download Shobbu app to get started.
              </p>
              <span>
                <Link to="/">
                  <img src={ios} alt="ios" />
                </Link>
              </span>
              <span>
                <Link to="/">
                  <img src={android} alt="android" />
                </Link>
              </span>
            </div>
            <div className="col-md-3 t">
              <div className="sell-card d-flex justify-content-between flex-column">
                <div>
                  <p>Shobbu lite</p>
                  <span className="pt-3 pb-5 sell-price">FREE</span>
                </div>

                <div>
                  <ul className="sell-list-free">
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#025D8D" }}
                      ></i>{" "}
                      30 day trial
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true "
                        style={{ color: "#025D8D" }}
                      ></i>{" "}
                      50 product listing
                    </li>
                  </ul>
                  <p className="sell-text">Get the app</p>
                  <p>
                    <span>
                      <i class="fa fa-apple" aria-hidden="true"></i>{" "}
                    </span>
                    <span>
                      {" "}
                      <i class="fa fa-android" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 t">
              <div className="sell-card-two  d-flex justify-content-between flex-column">
                <div>
                  <p>Shobbu Business</p>
                  <span className="pt-3 pb-5">
                    ₦ <span className="sell-price">5,000</span> /MONTH
                  </span>
                </div>

                <div>
                  <ul className="sell-list-biz">
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      30 day trial
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true "
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      50 product listing
                    </li>
                  </ul>
                  <p className="sell-text">Get the app</p>
                  <p>
                    <span>
                      <i class="fa fa-apple" aria-hidden="true"></i>{" "}
                    </span>
                    <span>
                      {" "}
                      <i class="fa fa-android" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 t">
              <div className="sell-card-three d-flex justify-content-between flex-column">
                <div>
                  <p>Advance</p>
                  <span className="pt-3 pb-1">
                    ₦ <span className="sell-price">9,000</span> /MONTH
                  </span>
                </div>

                <div>
                  <ul className="sell-list-adv">
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Unlimited product listing
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Analytics
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Insights (Products and Competitor)
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Dedicated support team
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true "
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Dedicated account manager
                    </li>
                  </ul>
                  <p className="sell-text">Get the app</p>
                  <p>
                    <span>
                      <i class="fa fa-apple" aria-hidden="true"></i>{" "}
                    </span>
                    <span>
                      {" "}
                      <i class="fa fa-android" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Sell;
