import {
  FETCH_SINGLE_PRODUCTS,
  FETCH_SINGLE_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCTS_FAILED
} from '../../types';

const initialState = {
  product: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchSingleProductReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_SINGLE_PRODUCTS:
      return {
        ...state,
        isLoading: true,
          product: action.payload
      }
      case FETCH_SINGLE_PRODUCTS_SUCCESS:
        return {
          ...state,
          isLoading: false,
            product: action.payload
        }
        case FETCH_SINGLE_PRODUCTS_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchSingleProductReducer;
