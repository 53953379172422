import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Row, Col, ModalHeader } from "reactstrap";
import "./styles.scss";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAddressPost } from "../../redux/actions/authentication";
import Auth from "../../config/auth";


const NewAddress = (props) => {
  //const history = useHistory();
  const user = Auth.getUser();
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [form, setForm] = useState({name: user.full_name, phone: user.phone, email:user.email});
  const [check, setCheck] = useState(false);

  function handleAddress(e) {
    e.preventDefault();
    props.fetchAddressPost(form);
    setCheck(true);
  }

  useEffect(() => {
    if (check) {
      NotificationManager.success("Added successfully");
      setForm({...form, postal:"", line1:""})
      setCheck(false);
      toggle()
    }
  }, [props.address.address]);

  useEffect(() => {
    if (check) {
      NotificationManager.error(props.address.errors.errors.message);
      setCheck(false);
    }
  }, [props.address.errors.errors]);

  return (
    <div>
      <button className={className} onClick={toggle}>
        {buttonLabel}
      </button>
      {/* <NotificationContainer /> */}
      <Modal isOpen={modal} toggle={toggle} className="modal-container">
        <ModalHeader toggle={toggle}>Add new Address</ModalHeader>
        <ModalBody>
          {/* <NotificationContainer /> */}
          <form action="post" onSubmit={handleAddress}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Full Name*</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Full name"
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email Address*</label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Phone*</label>
                  <input
                    type="tel"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Phone"
                    value={form.phone}
                    onChange={(e) =>
                      setForm({ ...form, phone: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Postal code*</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="postal code"
                    value={form.postal}
                    onChange={(e) =>
                      setForm({ ...form, postal: e.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Address*</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Address"
                    value={form.line1}
                    onChange={(e) =>
                      setForm({ ...form, line1: e.target.value })
                    }
                  />
                </div>
              </Col>
              {/* <Col xs={12} sm={12} md={4} lg={4}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email Address*</label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </div>
              </Col> */}
              {/* <Col xs={12} sm={12} md={4} lg={4}>
                
              </Col>
            </Row>
            <Row> */}
              {/* <Col xs={12} sm={12} md={4} lg={4}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Region*</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="region"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Town/city*</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="town / city"
                    value={form.city}
                    onChange={(e) => setForm({ ...form, city: e.target.value })}
                  />
                </div>
              </Col> */}
              {/* <Col xs={12} sm={12} md={4} lg={4}>
                
              </Col>
            </Row> */}
            {/* <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
               
              </Col> */}
            </Row> 
            <div className="text-center">
              <button className="sell text-white pl-5 pr-5 mt-3 mb-3" disabled={check}>
                {check ? 'Adding address' :'Add new address'}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

NewAddress.propTypes = {
  user: PropTypes.object.isRequired,
  fetchAddressPost: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  address: state.address,
});

const pageActions = { fetchAddressPost };

export default connect(mapStateToProps, pageActions)(NewAddress);
