import {
  FETCH_SEARCH,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILED
} from '../../types';

const initialState = {
  search: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchSearchReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_SEARCH:
      return {
        ...state,
        isLoading: true,
          search: action.payload
      }
      case FETCH_SEARCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
            search: action.payload
        }
        case FETCH_SEARCH_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchSearchReducer;
