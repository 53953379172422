import React from "react";

import "./styles.scss";
import { Link } from "react-router-dom";

const OrderSiderbar = (props) => {
  return (
    <div className="order-item">
      <div className="order-item-body">
      <Link to="/notifications">
          <p className="order-side">Notifications</p>
        </Link>
        <Link to="/bid">
          <p className="order-side">Bids</p>
        </Link>
        <Link to="/order">
          <p className="order-side">My orders</p>
        </Link>
        <Link to="/sell">
          <p className="order-side">Start selling</p>
        </Link>
        <Link to="/setting">
          <p className="order-side">Settings</p>
        </Link>
        <Link to="/change-password">
          <p className="order-side">Change password</p>
        </Link>
        <Link to="/">
          <p className="order-side">Help</p>
        </Link>
      </div>
    </div>
  );
};

export default OrderSiderbar;
