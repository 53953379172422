import React, { useState } from "react";
import Layout from "../component/Layout/Layout";
import { Link, useHistory, useLocation } from "react-router-dom";

import Axios from "axios";
import { BASE_URL } from "../redux/constants";
import Auth from "../config/auth";
import swal from "@sweetalert/with-react";

const ResetPassword = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = useState({ confirmpassword: "", newpassword: "" });
  const [check, setCheck] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const tokenReset = searchParams.get("token");
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  console.log(email, tokenReset);

  async function resetEmail(e) {
    e.preventDefault();
    setCheck(true);
    if (form.newpassword !== form.confirmpassword) {
      swal("try again", "password mismatch", "error");
      setForm({...form, confirmpassword: "", newpassword: "" })
      setCheck(false);
      return;
    }
    try {
      const data = {
        token: tokenReset,
        email: email,
        new_password: form.newpassword,
      };
      const response = await Axios.post(
        `${BASE_URL}/users/new-password`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
        setForm({...form, confirmpassword: "", newpassword: "" })
        setCheck(false);
      } else {
        swal(response.data.message, "", "success");
        setCheck(false);
        setForm({...form, confirmpassword: "", newpassword: "" })
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Layout>
      <section className="product-category-section">
        <div className="p-2">
          <h3 className="mt-3 ml-3">Reset password</h3>
          <div className="order-placed-container">
            <form className="form col-md-4" onSubmit={resetEmail}>
              <div class="form-group">
                <input
                  type="password"
                  className="form-control"
                  aria-describedby="emailHelp"
                  name="newpass"
                  value={form.newpassword}
                  onChange={(e) =>
                    setForm({ ...form, newpassword: e.target.value })
                  }
                  required
                  placeholder="enter new password"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  className="form-control"
                  aria-describedby="emailHelp"
                  name="name"
                  value={form.confirmpassword}
                  onChange={(e) =>
                    setForm({ ...form, confirmpassword: e.target.value })
                  }
                  required
                  placeholder="enter confirm new password"
                />
              </div>
              <button
                className="btn btn-primary btn-block"
                type="submit"
                // onClick={changePass}
              >
                {check ? "reseting" : "reset password"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
