import React from "react";
import { Col, Row } from "reactstrap";
import EditAddress from "../EditAddress/EditAddress";
import "./styles.scss";

const CardAddress = (props) => {
  const {
    trash,
    name,
    address,
    phone,
    zipcode,
    id,
    checked,
    setChecked,
  } = props;

  const form = {
    name: name,
    line1: address,
    phone: phone,
  };

  return (
    <Row className="card-address" >
      <Col sm={2} xs={2} lg={2} md={2}>
        <div class="float-right p-4" onClick={setChecked}>
          <div class="round">
            <input type="checkbox" id="checkbox" checked={checked} />
            <label for="checkbox"></label>
          </div>
        </div>
      </Col>
      <Col sm={10} xs={10} lg={10} md={10}>
        <div className="address-checkout-card">
          <div className="card-body row">
            <div className="col-md-10" onClick={setChecked}>
            <p className="checkout-name">{name}</p>
            <p className="checkout-adds">{address}</p>
            <p className="checkout-adds"> {phone}</p>
            <p className="checkout-adds">Zip code: {zipcode}</p>
            </div>
            <div className="col-md-2">
            <p className="text-danger action-address" onClick={trash}>
                DELETE
              </p>
              <EditAddress id={id} data={form} />
            </div>
           
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CardAddress;
