import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import CardItem from "../../component/CardItem/CardItem";
import { Link, useHistory } from "react-router-dom";
import SellAds from "../../component/SellAds/SellAds";
import ViewMore from "../../component/ViewMore/ViewMore";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchViewFavorite,
  fetchFavoriteRemove,
} from "../../redux/actions/products";
import { fetchFavouriteStores } from "../../redux/actions/stores";
import CardStore from "../../component/CardStore/CardStore";

import Axios from "axios";
import { BASE_URL } from "../../redux/constants";
import Auth from "../../config/auth";
import CardFeature from "../../component/CardFeature/CardFeature";
import CardSaved from "../../component/CardSaved/CardSaved";
import { isMobile } from "../../utils/isLayout";
import CardSavedMobile from "../../component/CardSavedMobile/CardSavedMobile";
import CardStoreMobile from "../../component/CardStoreMobile/CardStoreMobile";

const ProductSaved = (props) => {
  const [activeTab, setActiveTab] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [stores, setStores] = useState([]);
  const history = useHistory();
  const toggle = () => setActiveTab(!activeTab);
  const btn_class = activeTab ? "active-btn" : "saved-btn";
  const btn_class2 = activeTab ? "saved-btn" : "active-btn";
  const [pageCount, setPage] = useState(6);

  function viewMore() {
    activeTab && props.fetchViewFavorite(pageCount);
    activeTab && props.fetchFavouriteStores(pageCount);
    setPage(pageCount + 6);
  }

  function removeFav(id) {
    props.fetchFavoriteRemove(id);
  }

  async function unfollowStore(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(
        `${BASE_URL}/merchants/store-favourite/${id}`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        //swal(response.data.message,"","success")
        props.fetchFavouriteStores();
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.fetchViewFavorite();
    props.fetchFavouriteStores();
  }, []);

  useEffect(() => {
    if (props.favorites.favorites.data) {
      //NotificationManager.success(props.favorites.favorites.message);
      setFavorites(props.favorites.favorites.data.favourites);
    }
  }, [props.favorites.favorites]);

  useEffect(() => {
    if (props.store.store.data) {
      setStores(props.store.store.data.favourite_stores);
      console.log(props.store.store.data.favourite_stores);
    }
  }, [props.store.store]);

  return (
    <Layout>
      <NotificationContainer />
      <section className="saved-section">
        <div className="bread-crumbs">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/product-category">Saved</Link>
        </div>
        <div className="p-3 d-flex justify-content-center">
          <p className={btn_class} onClick={toggle}>
            Products
          </p>
          <p className={btn_class2} onClick={toggle}>
            Stores
          </p>
        </div>

        <div className="row saved-section-items d-flex justify-content-center">
          {activeTab && (
            <Fragment>
              {(favorites || []).map((item, i) => {
                return (
                  <>
                    {isMobile ? (
                      <CardSavedMobile
                        like
                        photo={item.product.images[0]}
                        item={item.product.product_name}
                        price={item.product.price}
                        desc={item.product.description}
                        soldout={item.stock_limit < 1 ? true : false}
                        // oldPrice={item.price}
                        // discount={item.discount}
                        favourite={() => removeFav(item.product_id)}
                        action={() =>
                          history.push(`/product/${item.product.product_slug}`)
                        }
                        key={i}
                      />
                    ) : (
                      <CardSaved
                        like
                        photo={item.product.images[0]}
                        item={item.product.product_name}
                        price={item.product.price}
                        desc={item.product.description}
                        soldout={item.stock_limit < 1 ? true : false}
                        // oldPrice={item.price}
                        // discount={item.discount}
                        favourite={() => removeFav(item.product_id)}
                        action={() =>
                          history.push(`/product/${item.product.product_slug}`)
                        }
                        key={i}
                      />
                    )}
                  </>
                );
              })}
              {favorites.length < 1 && (
                <h4 className="text-center p-5">No Saved Product</h4>
              )}
            </Fragment>
          )}
          {
            !activeTab && (
              <Fragment>
                {stores.length < 1 && (
                  <h4 className="text-center p-5">No Saved Store</h4>
                )}
                <div className="row">
                  {(stores || []).map((item, i) => {
                    return (
                      <>
                        {isMobile ? (
                          <CardStoreMobile
                            like
                            name={item.store.store_name}
                            url={item.store.store_url}
                            logo={item.store.logo}
                            address={item.store.store_location_address}
                            likes={item.store.likes}
                            unfollow={() => unfollowStore(item.store.id)}
                            key={i}
                          />
                        ) : (
                          <CardStore
                            like
                            name={item.store.store_name}
                            url={item.store.store_url}
                            logo={item.store.logo}
                            address={item.store.store_location_address}
                            likes={item.store.likes}
                            unfollow={() => unfollowStore(item.store.id)}
                            key={i}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </Fragment>
            )
            // deals.map((item, i) => {
            //   return (
            //     <div className="special-card">
            //       <CardItem
            //         like
            //         photo={item.photo}
            //         item={item.item}
            //         price={item.price}
            //         desc={item.desc}
            //       />
            //     </div>
            //   );
            // })
          }
        </div>

        <ViewMore viewHandler={viewMore} />
      </section>
      <SellAds />
    </Layout>
  );
};

//export default ProductSaved;
ProductSaved.propTypes = {
  favorites: PropTypes.object.isRequired,
  fetchAddressDelete: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  favorites: state.favorites,
  store: state.store,
});

const pageActions = {
  fetchViewFavorite,
  fetchFavoriteRemove,
  fetchFavouriteStores,
};

export default connect(mapStateToProps, pageActions)(ProductSaved);
