import {
  FETCH_RECENTLY_VIEW,
  FETCH_RECENTLY_VIEW_SUCCESS,
  FETCH_RECENTLY_VIEW_FAILED
} from '../../types';

const initialState = {
  recent: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchRecentViewReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_RECENTLY_VIEW:
      return {
        ...state,
        isLoading: true,
          recent: action.payload
      }
      case FETCH_RECENTLY_VIEW_SUCCESS:
        return {
          ...state,
          isLoading: false,
            recent: action.payload
        }
        case FETCH_RECENTLY_VIEW_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchRecentViewReducer;
