import axios from 'axios';
import {
  FETCH_REGISTRATION_FAILED,
  FETCH_REGISTRATION_SUCCESS,
  FETCH_LOGIN_FAILED,
  FETCH_LOGIN_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  FETCH_VERIFY_FAILED,
  FETCH_VERIFY_SUCCESS,
  FETCH_ADDRESS_FAILED,
  FETCH_ADDRESS_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constants';

import Auth from '../../config/auth'


export const fetchUserEmailRegistration = (userData) => dispatch => {

  axios.post(`${BASE_URL}/users/register/email`, userData)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_REGISTRATION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_REGISTRATION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_REGISTRATION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUserEmailLogin = (userData) => dispatch => {

  axios.post(`${BASE_URL}/users/login/email`, userData)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_LOGIN_SUCCESS,
          payload: response.data
        })
        const {
          user,
          token
        } = response.data.data;
        Auth.setToken(token, user);
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchUserGoogleLogin = (userData) => dispatch => {

  axios.post(`${BASE_URL}/users/google/login`, userData)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_LOGIN_SUCCESS,
          payload: response.data
        })
        const {
          user,
          token
        } = response.data.data;
        Auth.setToken(token, user);
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchLogout = () => dispatch => {
  dispatch({
    type: FETCH_LOGIN_SUCCESS,
    payload: {}
  })
  Auth.destroyToken();
  // axios.post(`${BASE_URL}/users/login/email`, userData)
  //   .then(response => {
  //     console.log(response)
  //     if (!response.data.hasError) {
  //       dispatch({
  //         type: FETCH_LOGIN_SUCCESS,
  //         payload: response.data
  //       })
  //       const {
  //         user,
  //         token
  //       } = response.data.data;
  //       Auth.setToken(token, user);
  //     } else {
  //       dispatch({
  //         type: FETCH_LOGIN_FAILED,
  //         payload: response.data
  //       })
  //     }
  //   })
  //   .catch(err => {
  //     dispatch({
  //       type: FETCH_LOGIN_FAILED,
  //       payload: {
  //         hasError: true,
  //         errors: {
  //           message: "network error",
  //           error: err.data
  //         }
  //       }
  //     })
  //   })
}

export const fetchUserVerifyCode = (phone, code) => dispatch => {

  axios.get(`${BASE_URL}/users/verify/phone/${phone}/${code}`)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_VERIFY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_VERIFY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_VERIFY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchUserVerifyEmail = (email, token) => dispatch => {

  axios.get(`${BASE_URL}/users/verify/${email}/${token}`)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_VERIFY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_VERIFY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_VERIFY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUserResendEmail = (email) => dispatch => {
  axios.get(`${BASE_URL}/users/verify/resend/token/${email}`)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_VERIFY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_VERIFY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_VERIFY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchUserResendCode = (code) => dispatch => {
  axios.get(`${BASE_URL}/users/verify/resend/code/${code}`)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_VERIFY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_VERIFY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_VERIFY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUserProfile = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/users/profile/view`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        const {
          profile
        } = response.data.data;

        Auth.setToken(token, profile);
        dispatch({
          type: FETCH_LOGIN_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUserEditProfile = (userData) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/users/profile/edit`, userData, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
       
        const {
          profile
        } = response.data.data;
        Auth.setToken(token, profile);
        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_USER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAddressPost = (userData) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/users/address`, userData, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ADDRESS_SUCCESS,
          payload: response.data.data
        })
        dispatch(fetchUserProfile())
      } else {
        dispatch({
          type: FETCH_ADDRESS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ADDRESS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAddressDelete = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/users/address/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchUserProfile())
        dispatch({
          type: FETCH_ADDRESS_SUCCESS,
          payload: response.data.data.address
        })
      } else {
        dispatch({
          type: FETCH_ADDRESS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ADDRESS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAddressEdit = (id, userData) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/users/address/${id}`, userData, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchUserProfile())
        dispatch({
          type: FETCH_ADDRESS_SUCCESS,
          payload: response.data.data.address
        })
      } else {
        dispatch({
          type: FETCH_ADDRESS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ADDRESS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchChangePassword = (userData) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/users/password/change`, userData, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
         dispatch(fetchUserProfile())
        // const {
        //   profile
        // } = response.data.data;
        // Auth.setToken(token, profile);
        // dispatch({
        //   type: FETCH_USER_SUCCESS,
        //   payload: response.data
        // })
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}