import React, { useState } from "react";
import { Container } from "./Container";
import "./styles.scss";
import dayjs from "dayjs";

const CardNotification = (props) => {
  const { title , date, message} = props;
  
  return (
    <Container>
      <div className="">
        <div className="order-item-body row">
          <div className="col-md-12 d-flex justify-content-between">
            <p className="noti-name">{title}</p>
            <p className="noti-date">
              {dayjs(date).format("MMMM D, YYYY h:mm A")}
            </p>
          </div>
          <div className="col-md-12">
            <p className="order-date">
              {message}
            </p>
          </div>
        
        </div>
      </div>
    </Container>
  );
};

export default (CardNotification);
