import React from "react";
import { Container } from "./Container";
import "./styles.scss";
import cart from "../../images/cart.png";
import truncate from "../../utils/truncate";
import numberToComma from "../../utils/numberToComma";

const CardAuction = (props) => {
  const {
    item,
    price,
    time,
    desc,
    photo,
    action,
    like,
    favourite,
    oldPrice,
    discount,
    soldout,
    bids,
  } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  return (
    <Container>
      <div className="card-auction">
        <div className="card-auction-body">
          <div className="auction-time">{time}</div>
          <div className="auction-side">
          <ul className="likes-feat">
            <li> <i className="fa fa-heart-o" aria-hidden="true"></i></li>
            <li> <i className="fa fa-eye" aria-hidden="true"></i></li>
         
         
          {/* {like ? (
            <span style={{ color: "#0279B8" }} onClick={favourite}>
              <i className="fa fa-heart" aria-hidden="true"></i>
            </span>
          ) : (
            <i className="fa fa-heart-o" aria-hidden="true"></i>
          )}
          <span style={{ color: "#0279B8" }}>
            <i className="fa fa-eye ml-2" aria-hidden="true"></i>
          </span> */}
          </ul>
            <div className="item-image-container" onClick={action}>
              <img
                src={photo}
                className="card-item-image-container rounded"
                width="100%"
                alt="deal day"
                onError={addDefaultSrc}
              />
            </div>
          </div>
          <div>
            <p className="card-auction-name">{truncate(item, 25)}</p>
            <p className="card-auction-price">Price: ₦ {numberToComma(price)}</p>
            <p className="card-auction-name">Current Bid:  <span className="auc-price">₦ {numberToComma(bids)}</span></p>
          </div>
          <div className="card-actions">
          <button onClick={action} className="btn btn-sm add-cart mt-2">
          <strong>bid</strong>{" "}
                <i className="fa fa-gavel" aria-hidden="true"></i>
              </button>
            {/* {soldout ? (
              <button onClick={action} className="btn btn-sm sold-out mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>sold out</small>
              </button>
            ) : (
              <button onClick={action} className="btn btn-sm add-cart mt-2">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                <small>add to cart</small>
              </button>
            )} */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardAuction;
