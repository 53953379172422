import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../images/logo.svg";
import "./styles.scss";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchUserVerifyCode,
  fetchUserResendEmail,
  fetchUserResendCode
} from "../../redux/actions/authentication";

import isEmpty from "../../utils/isEmpty";

const ConfirmAccount = props => {
  const { className, email, modal, setModal, phone } = props;

  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [state, setState] = useState("");
  const [form, setForm] = useState({ c1: "", c2: "", c3: "", c4: "" });

  const [errors, setErrors] = useState({
    message: "",
    code: "",
    yesError: false
  });

  function handleVerify() {
    setResend(true);
    const code = form.c1 + form.c2 + form.c3 + form.c4;
    props.fetchUserVerifyCode(phone, code);
  }

  useEffect(() => {
    setResend(false);
    console.log(props.result);
    if (!isEmpty(props.result.user)) {
      const { user } = props.result;

      if (!user.hasError) {
        setState(user.message);
      }
    } else {
      if (props.result.errors.errors) {
        setErrors({ ...errors, ...props.result.errors.errors, yesError: true });
      }
    }
  }, [props.result]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} className="text-center">
          <img src={logo} alt="logo" width="100" height="50" />
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            {errors.yesError && (
              <div className="alert alert-danger">{errors.message}</div>
            )}
            {!isEmpty(props.result.user) && (
              <div className="alert alert-success">
                {props.result.user.message}
              </div>
            )}
            <div className="p-5">
              <h5>Confirm your account</h5>
            </div>
            <div>
              <p>A confirmation email was sent to</p>
              <p className="text-primary">{email}</p>
              <p>Open the link to continue</p>
            </div>
            <p class="form-text text-muted mt-4 mb-4">-OR-</p>
            <div>
              <p>Enter Confirmation code sent to your mobile phone</p>
            </div>

            <div id="form">
              <input
                className="confirm-text"
                type="tel"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                pattern="[0-9]{1}"
                value={form.c1}
                onChange={e => setForm({ ...form, c1: e.target.value })}
              />
              <input
                className="confirm-text"
                type="tel"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                pattern="[0-9]{1}"
                value={form.c2}
                onChange={e => setForm({ ...form, c2: e.target.value })}
              />
              <input
                className="confirm-text"
                type="tel"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                pattern="[0-9]{1}"
                value={form.c3}
                onChange={e => setForm({ ...form, c3: e.target.value })}
              />
              <input
                className="confirm-text"
                type="tel"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                pattern="[0-9]{1}"
                value={form.c4}
                onChange={e => setForm({ ...form, c4: e.target.value })}
              />
            </div>
            <p>23 secs</p>
            <button
              className="btn login-btn text-white"
              disabled={loading}
              onClick={handleVerify}
            >
              {loading ? "Confirming..." : "Confirm"}
            </button>
            <br />
            <br />
            <button
              className="btn text-primary"
              disabled={loading}
              onClick={() => {
                setResend(true);
                props.fetchUserResendEmail(email);
              }}
            >
              {resend ? "Resending..." : "Resend"}
            </button>
            <br />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

ConfirmAccount.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserResendCode: PropTypes.func.isRequired,
  fetchUserResendEmail: PropTypes.func.isRequired,
  fetchUserVerifyCode: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  result: state.verifyUser
});

const pageActions = {
  fetchUserResendCode,
  fetchUserResendEmail,
  fetchUserVerifyCode
};

export default connect(mapStateToProps, pageActions)(ConfirmAccount);
