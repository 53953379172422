import React from "react";
import { Button, Card } from "reactstrap";
import "./styles.scss";
import truncate from "../../utils/truncate";
import cart from "../../images/cart.png";

const CardStoreMobile = (props) => {
  const { logo, name, address, url, likes, follow, like, unfollow } = props;
  const addDefaultSrc = (e) => {
    e.target.src = cart;
  };
  return (
    <div className="store-mobile-item">
      <div className="store-mobile-body">
        <div className="d-flex justify-content-center">
          <img
            className="store-mobile-image-container"
            src={logo}
            alt="store-mobile"
            onError={addDefaultSrc}
          />
          <h5 title={name}>{truncate(name, 10)}</h5>
        </div>
        <div className="ml-4 mt-3">
          <p>
            <i class="fa fa-location-arrow" aria-hidden="true"></i>{" "}
            {truncate(address, 20)}
          </p>
          <p> Followers: {likes}</p>
          <ul className="social" role="navigation">
            <li>
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </li>
            <li>
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </li>
            <li>
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </li>
            <li>
              <i className="fa fa-youtube" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
        <div className="text-center">
          <p
            className="btn-primary btn btn-sm store-mobile-link  font-weight-bold mr-3"
            onClick={() => window.open(`http://${url}.shobbu.com`, "_blank")}
          >
            Visit store
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardStoreMobile;
 