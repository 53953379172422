import React, { useState, useEffect } from "react";
import "./styles.scss";
import Layout from "../../component/Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import CardAddress from "../../component/CardAddress/CardAddress";
import CardSummary from "../../component/CardSummary/CardSummary";
import NewAddress from "../NewAddress/NewAddress";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchAddressDelete,
  fetchUserEditProfile,
} from "../../redux/actions/authentication";
import {
  fetchOrderSummary,
  fetchCancelOrder,
} from "../../redux/actions/products";

import Auth from "../../config/auth";
import isEmpty from "../../utils/isEmpty";

const ProductCheckout = (props) => {
  const history = useHistory();
  const [order, setOrder] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const userAddress = Auth.getUser().addresses;
  const user = Auth.getUser();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDelete = (id) => {
    props.fetchAddressDelete(id);
    const data = {
      selected_address_id: null,
    };
    props.fetchUserEditProfile(data);
  };

  const setActiveAddress = (id) => {
    const data = {
      selected_address_id: id,
    };
    props.fetchUserEditProfile(data);
  };

  const checkout = () => {
    props.fetchOrderSummary();
    toggle(2);
  };

  const cancelOrder = () => {
    props.fetchCancelOrder();
    history.goBack();
  };

  useEffect(() => {
    if (props.order.order.data) {
      setOrder(props.order.order.data.order);
      // Save data to sessionStorage
      sessionStorage.setItem("order_id", props.order.order.data.order.id);
    }
  }, [props.order.order]);

  return (
    <Layout>
      <section className="checkout-section">
        <div className="bread-crumbs pt-3 pl-5 pb-3">
          <Link to="/">Home</Link> <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/product-cart">Cart</Link>
          <i class="fa fa-caret-right pl-2 pr-2"></i>
          <Link to="/product-checkout">Checkout</Link>
        </div>
        <div className="checkout-page-container text-center">
          <div className="row">
            <div className="col-md-6">
              <p className="nav-tabs-checkout-selected">Address Book</p>
            </div>
            <div className="col-md-6">
              <p className="nav-tabs-checkout">Summary</p>
            </div>
          </div>
        </div>

        <div className="checkout-card-container">
          {activeTab === 1 && (
            <div>
              {isEmpty(userAddress) && (
                <h5>You have no current delivery address</h5>
              )}
              {(userAddress || []).map((item, i) => {
                return (
                  <CardAddress
                    name={item.name}
                    address={item.line1}
                    phone={item.phone}
                    zipcode={item.postal}
                    checked={
                      user.selected_address_id === item.id ? true : false
                    }
                    trash={() => {
                      handleDelete(item.id);
                    }}
                    id={item.id}
                    setChecked={() => setActiveAddress(item.id)}
                    key={i}
                  />
                );
              })}
              <NewAddress
                buttonLabel="Add new address +"
                className="login mt-4 mb-3"
              />
            </div>
          )}
          {activeTab === 2 && (
            <CardSummary
              order={order ? order : "no order"}
              cancel={cancelOrder}
            />
          )}
          {/* {activeTab === 3 && } */}
          <hr />
          <div className="row">
            <div className="col-md">
              {activeTab === 2 && (
                <div className="float-left">
                  <button className="login" onClick={() => toggle(1)}>
                    Back
                  </button>
                </div>
              )}

              {activeTab === 1 && (
                <div className="float-right ">
                  <button
                    className="sell text-white"
                    onClick={checkout}
                    disabled={user.selected_address_id === null ? true : false}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-5"></div>
      </section>
    </Layout>
  );
};
ProductCheckout.propTypes = {
  order: PropTypes.object.isRequired,
  fetchAddressDelete: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  order: state.order,
});

const pageActions = {
  fetchAddressDelete,
  fetchUserEditProfile,
  fetchOrderSummary,
  fetchCancelOrder,
};

export default connect(mapStateToProps, pageActions)(ProductCheckout);
