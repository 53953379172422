import {
  FETCH_TRENDING,
  FETCH_TRENDING_SUCCESS,
  FETCH_TRENDING_FAILED
} from '../../types';

const initialState = {
  trends: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchTrendingReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_TRENDING:
      return {
        ...state,
        isLoading: true,
          trends: action.payload
      }
      case FETCH_TRENDING_SUCCESS:
        return {
          ...state,
          isLoading: false,
            trends: action.payload
        }
        case FETCH_TRENDING_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchTrendingReducer;
